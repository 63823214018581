import React, { useEffect, useState } from "react";
import AdminHeader from "./AdminHeader";
import toast from "react-hot-toast";
import { server } from "../../server";
import axios from "axios";
import { Link } from "react-router-dom";
import Modal from "../common/Modal";

const AdminUsers = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [users, setUsers] = useState([]);
  const [loading2, setLoading2] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    const token = localStorage.getItem("userToken");
    if (!token) {
      toast.error("Please Login Again");
      throw new Error("Authentication token not found");
    }
    try {
      setLoading2(true);
      const response = await fetch(`${server}/users`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        setLoading2(false);
        toast.error("Error occurred");
        console.log("error", response);
        throw new Error("Network response was not ok.");
      }
      const data = await response.json();
      // Initialize menuVisible state for each user item
      const initialMenuVisible = {};
      data.data.forEach((user) => {
        initialMenuVisible[user._id] = false;
      });
      const sortedUsers = data.data.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      setUsers(sortedUsers);
    } catch (error) {
      setLoading2(false);
      console.error("Error fetching users:", error);
    }
    setLoading2(false);
  };

  // Filter payments based on search query
  const filteredUsers = users.filter(
    (user) =>
      user.fullName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.phone.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.role.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredUsers?.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate total pages
  const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);

  // Generate page numbers
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [userId, setUserId] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const handleDeleteUser = () => {
    deleteUser(userId);
    closeModal();
  };

  const deleteUser = (userId) => {
    const deletePromise = async () => {
      try {
        const token = localStorage.getItem("userToken");
        if (!token) {
          throw new Error("Authentication token not found");
        }

        const response = await axios.delete(`${server}/users/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200) {
          fetchUsers();
          return "User deleted successfully";
        } else {
          throw new Error("Failed to delete User");
        }
      } catch (error) {
        console.error("Error deleting User:", error);
        throw error;
      }
    };

    // Use toast.promise to handle loading, success, and error messages
    toast.promise(deletePromise(), {
      loading: "Deleting...",
      success: <b>User deleted successfully</b>,
      error: <b>Failed to delete User</b>,
    });
  };

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        onConfirm={handleDeleteUser}
        title={"Do you want to delete this user?"}
        message={
          "NOTE: All Cargo and Payments associated with this user will be deleted. Remember this Process can NOT be reversed."
        }
      />
      <div className="admin">
        <AdminHeader />

        <main>
          <article className="container article">
            <section className="tasks">
              <div className="section-title-wrapper">
                <h2 className="section-title">Users</h2>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    style={{
                      width: "100%",
                      padding: "10px",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      fontSize: "16px",
                      background: "#fffffff",
                    }}
                  />
                </div>
                <div className="pagination">
                  <div>
                    Page {currentPage} of {totalPages}
                  </div>
                </div>
              </div>
              {loading2 ? (
                <ul className="tasks-list loading">
                  {[...Array(5)].map((_, index) => (
                    <li className="tasks-item loading-item" key={index}>
                      <div className="card task-card">
                        <div className="card-input loading-checkbox"></div>
                        <div className="card-badge radius-pill loading-badge"></div>
                        <div className="card-badge radius-pill loading-badge"></div>
                        <div className="card-badge radius-pill loading-badge"></div>
                        <ul className="card-meta-list">
                          <li>
                            <div className="meta-box icon-box loading-meta"></div>
                          </li>
                        </ul>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <>
                  <ul className="tasks-list">
                    {/* Render current page items */}
                    {currentItems.map((user, index) => (
                      <li className="tasks-item" key={index}>
                        <div className="card task-card">
                          <div className="card-input">
                            <input
                              type="checkbox"
                              name={`task-${index}`}
                              id={`task-${index}`}
                            />
                            <label
                              htmlFor={`task-${index}`}
                              className="task-label"
                            >
                              {user.email}
                            </label>
                          </div>
                          <div className="card-badge">{user.fullName}</div>
                          <div
                            className={`card-badge ${
                              user.role === "Admin" ? "cyan" : ""
                            }`}
                          >
                            {user.role}
                          </div>

                          <div className="card-badge">{user.phone}</div>

                          <ul className="card-meta-list">
                            <li>
                              <Link to={`/addcargo/${user._id}`}>
                                <div
                                  className="card-badge orange"
                                  style={{
                                    display: "flex",
                                    textAlign: "center",
                                  }}
                                >
                                  <i
                                    class="bx bx-plus-circle icon"
                                    style={{ fontSize: "25px" }}
                                  ></i>
                                  <p
                                    style={{
                                      fontSize: "13px",
                                      marginLeft: "2px",
                                      marginTop: "2px",
                                    }}
                                  >
                                    Add cargo
                                  </p>
                                </div>
                              </Link>
                            </li>

                            <li>
                              <div
                                className="card-badge orange"
                                onClick={() => {
                                  openModal();
                                  setUserId(user._id);
                                }}
                              >
                                <i
                                  class="bx bx-trash icon"
                                  style={{ fontSize: "25px" }}
                                ></i>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </li>
                    ))}
                  </ul>
                </>
              )}

              {/* Render page numbers */}
              <div
                style={{ display: "flex", marginTop: "6px", flexWrap: "wrap" }}
              >
                {pageNumbers.map((number) => (
                  <button
                    key={number}
                    className={`pag-button ${
                      currentPage === number ? "active" : ""
                    }`}
                    onClick={() => paginate(number)}
                  >
                    {number}
                  </button>
                ))}
              </div>
            </section>
          </article>
        </main>
      </div>
    </>
  );
};

export default AdminUsers;
