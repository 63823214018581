import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import jwtDecode from "jwt-decode";
import logo from "./logo.png";
import { FaXTwitter } from "react-icons/fa6";
import { FaFacebook } from "react-icons/fa";
import { FaSquareInstagram } from "react-icons/fa6";
import { SiTiktok } from "react-icons/si";
import { IoLogoLinkedin } from "react-icons/io5";
import { IoLogoYoutube } from "react-icons/io";

const Footer = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [data, setData] = useState();
  useEffect(() => {
    const token = localStorage.getItem("userToken");
    if (token && token !== "undefined") {
      const decoded = jwtDecode(token);
      setData(decoded);

      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
        setIsLoggedIn(false);
        // setDecodedToken(null);
        localStorage.removeItem("userToken");
      } else {
        setIsLoggedIn(true);
        // setDecodedToken(decoded);
      }
    } else {
      setIsLoggedIn(false);
      // setDecodedToken(null);
    }
  }, []);
  const threeDolts = () => {
    window.open("https://www.threedolts.co.ke", "_blank");
  };

  return (
    <div>
      {" "}
      <footer class="footer section">
        <div class="footer__container container grid">
          <div class="footer__content">
            <Link to="/" class="nav__logo">
              <img src={logo} alt="bani-logo" className="footer__logo" />
            </Link>
            <p class="footer__description">
              Bani Global Logistics delivers tailored solutions for smart
              shipping, global fulfillment, and seamless returns, ensuring
              agility and efficiency in a fast-paced market.
            </p>
          </div>

          <div class="footer__content">
            <h3 class="footer__title">Services</h3>
            <ul class="footer__links">
              <li>
                <Link to="/services" class="footer__link">
                  Air Freight{" "}
                </Link>
              </li>
              <li>
                <Link to="/services" class="footer__link">
                  Ocean Freight
                </Link>
              </li>
              <li>
                <Link to="/services" class="footer__link">
                  Road Freight
                </Link>
              </li>
              <li>
                <Link to="/services" class="footer__link">
                  Customs Brokerage
                </Link>
              </li>
              <li>
                <Link to="/services" class="footer__link">
                  Consulting & Advisory
                </Link>
              </li>
            </ul>
          </div>

          <div class="footer__content">
            <h3 class="footer__title">Company</h3>
            <ul class="footer__links">
              <li>
                <Link to="/" class="footer__link">
                  Services
                </Link>
              </li>
              <li>
                <Link to="/" class="footer__link">
                  Our mision
                </Link>
              </li>
              <li>
                <Link to="/" class="footer__link">
                  Get in touch
                </Link>
              </li>
              {isLoggedIn ? (
                <li>
                  <Link
                    to={data.role === "Admin" ? "/admin" : "/profile"}
                    class="footer__link"
                  >
                    Account
                  </Link>
                </li>
              ) : (
                <li>
                  <Link to="/login" class="footer__link">
                    Login
                  </Link>
                </li>
              )}
              <li>
                <Link to="/tracking" class="footer__link">
                  Tracking
                </Link>
              </li>
            </ul>
          </div>

          <div class="footer__content">
            <h3 class="footer__title">Community</h3>
            <ul class="footer__links">
              <li>
                <Link to="/contacts" class="footer__link">
                  Support
                </Link>
              </li>
              <li>
                <Link to="/contacts" class="footer__link">
                  Questions
                </Link>
              </li>
              <li>
                <Link to="/contacts" class="footer__link">
                  Customer help
                </Link>
              </li>
              <li>
                <Link to="/terms-and-conditions" class="footer__link">
                  Terms and Conditions
                </Link>
              </li>
            </ul>
          </div>
          <div className="footer__content">
            <h3 className="footer__title">Follow Us</h3>
            <div>
              <div className="footer__social" style={{ marginBottom: "5px" }}>
                <Link
                  to="/"
                  className="footer__social-link"
                  style={{ color: "#3b5998" }}
                >
                  {" "}
                  {/* Facebook */}
                  <FaFacebook />
                </Link>
                <Link
                  to="/"
                  className="footer__social-link"
                  style={{ color: "#1DA1F2" }}
                >
                  {" "}
                  {/* Twitter */}
                  <FaXTwitter />
                </Link>

                <Link
                  to="/"
                  className="footer__social-link"
                  style={{ color: "#DE8C9D" }}
                >
                  {" "}
                  {/* TikTok */}
                  <SiTiktok />
                </Link>
              </div>
            </div>
            <div>
              <div className="footer__social">
                <Link
                  to="/"
                  className="footer__social-link"
                  style={{ color: "#C13584" }}
                >
                  {" "}
                  {/* Instagram */}
                  <FaSquareInstagram />
                </Link>
                <Link
                  to="/"
                  className="footer__social-link"
                  style={{ color: "#0077B5" }}
                >
                  {" "}
                  {/* LinkedIn */}
                  <IoLogoLinkedin />
                </Link>
                <Link
                  to="/"
                  className="footer__social-link"
                  style={{ color: "#FF0000" }}
                >
                  {" "}
                  {/* YouTube */}
                  <IoLogoYoutube />
                </Link>
              </div>
            </div>
          </div>
        </div>

        <p
          class="footer__copy"
          onClick={() => threeDolts()}
          style={{ cursor: "pointer" }}
        >
          <p>
            &#169; {new Date().getFullYear()} Bani Logistics. All right
            reserved.
          </p>
          <p>
            <i
              className="bx bxs-cog"
              style={{ marginLeft: "5px", marginRight: "5px" }}
            ></i>
            Build by Threedolts.
          </p>
        </p>
      </footer>
    </div>
  );
};

export default Footer;
