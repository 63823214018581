import React, { useEffect, useState } from "react";
import AdminHeader from "./AdminHeader";
import toast from "react-hot-toast";
import { server } from "../../server";
import axios from "axios";
import Modal from "../common/Modal";
import { useNavigate } from "react-router-dom";

const AdminRequestsPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [cargos, setCargos] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [menuVisible, setMenuVisible] = useState({});
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    fetchCargos();
  }, []);

  const fetchCargos = async () => {
    const token = localStorage.getItem("userToken");
    if (!token) {
      toast.error("Please Login Again");
      throw new Error("Authentication token not found");
    }
    try {
      setLoading2(true);
      const response = await fetch(`${server}/requests`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        setLoading2(false);
        toast.error("Error occurred");
        console.log("error", response);
        throw new Error("Network response was not ok.");
      }
      const data = await response.json();
      const initialMenuVisible = {};
      data.data.forEach((cargo) => {
        initialMenuVisible[cargo._id] = false;
      });
      setMenuVisible(initialMenuVisible);
      const sortedCargos = data.data.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      setCargos(sortedCargos);
    } catch (error) {
      setLoading2(false);
      console.error("Error fetching cargos:", error);
    }
    setLoading2(false);
  };

  // Filter cargos based on search query
  const filteredCargos = cargos.filter(
    (cargo) =>
      cargo.receiptNo.toLowerCase().includes(searchQuery.toLowerCase()) ||
      cargo.shipFrom.toLowerCase().includes(searchQuery.toLowerCase()) ||
      cargo.phone.toLowerCase().includes(searchQuery.toLowerCase()) ||
      cargo.quantitynquality
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      cargo.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
      cargo.status.toLowerCase().includes(searchQuery.toLowerCase()) ||
      cargo.amount.toLowerCase().includes(searchQuery.toLowerCase()) ||
      cargo.shippingMode.toLowerCase().includes(searchQuery.toLowerCase()) ||
      cargo.balance.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredCargos.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate total pages
  const totalPages = Math.ceil(filteredCargos.length / itemsPerPage);

  // Generate page numbers
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const toggleMenu = (cargoId) => {
    setMenuVisible({
      ...menuVisible,
      [cargoId]: !menuVisible[cargoId],
    });
  };

  const handleStatusChange = async (cargoId, userId) => {
    try {
      const token = localStorage.getItem("userToken");
      if (!token) {
        toast.error("Please Login Again");
        throw new Error("Authentication token not found");
      }
      if (selectedStatus === "") {
        toast.error("Please Select the Status");
        throw new Error("Status Not Selected");
      }
      setLoading(true);
      const response = await axios.put(
        `${server}/${cargoId}/request-update-status`,
        { status: selectedStatus },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      await axios.post(`${server}/usernotifications`, {
        userId: userId,
        title: "Status Updates",
        message: `Your status is now: ${selectedStatus}`,
      });

      if (response.status === 200) {
        toast.success("Status updated successfully");
        fetchCargos();
        setLoading(false);
      } else {
        toast.error("Failed to update status");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error updating status:", error);
      toast.error("Error updating status");
      setLoading(false);
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cargoId, setCargoId] = useState("");
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const handleDeleteCargo = () => {
    deleteCargo(cargoId);
    closeModal();
  };

  const deleteCargo = (cargoId) => {
    const deletePromise = async () => {
      try {
        const token = localStorage.getItem("userToken");
        if (!token) {
          throw new Error("Authentication token not found");
        }

        const response = await axios.delete(`${server}/requests/${cargoId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200) {
          fetchCargos();
          return "Requests deleted successfully";
        } else {
          throw new Error("Failed to delete Requests");
        }
      } catch (error) {
        console.error("Error deleting Requests:", error);
        throw error;
      }
    };

    toast.promise(deletePromise(), {
      loading: "Deleting...",
      success: <b>Requests deleted successfully</b>,
      error: <b>Failed to delete cargo</b>,
    });
  };
  const generateReceiptNumber = () => {
    const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let receipt = "";
    for (let i = 0; i < 5; i++) {
      receipt += chars.charAt(Math.floor(Math.random() * chars.length));
    }

    const currentDate = new Date();
    const startOfYear = new Date(currentDate.getFullYear(), 0, 0);
    const diff = currentDate - startOfYear;
    const oneDay = 1000 * 60 * 60 * 24;
    const dayOfYear = Math.floor(diff / oneDay);
    const year = String(currentDate.getFullYear()).slice(-2);
    return `${receipt}${dayOfYear}${year}`;
  };

  const [addLoading, setAddLoading] = useState(false);
  const navigate = useNavigate();

  const makeCargo = async (requestId) => {
    const loadingToast = toast.loading("Processing...");
    setAddLoading(true);

    try {
      const token = localStorage.getItem("userToken");
      if (!token) {
        toast.error("Please Login Again");
        throw new Error("Authentication token not found");
      }

      const response = await axios.get(`${server}/requests/${requestId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const cargoData = {
        userId: response.data.data.userId,
        receiptNo: generateReceiptNumber(),
        trackingNo: response.data.data.receiptNo,
        email: response.data.data.email,
        phone: response.data.data.phone,
        shipFrom: response.data.data.shipFrom,
        quantitynquality: response.data.data.quantitynquality,
        amount: response.data.data.amount,
        shippingMode: response.data.data.shippingMode,
        currency: response.data.data.currency,
      };

      const response2 = await fetch(`${server}/cargos`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(cargoData),
      });

      await axios.post(`${server}/usernotifications`, {
        userId: cargoData.userId,
        title: "New Cargo Added",
        message: `A new cargo was added for you.`,
      });

      if (response2.ok) {
        toast.success("Cargo Added Successfully");
      } else {
        if (response2.status === 400) {
          toast.error("Cargo with this receipt number already exists");
        } else {
          toast.error("Something went Wrong");
        }
        throw new Error("Network response was not ok.");
      }

      const data = await response2.json();
      console.log("Cargo added successfully:", data);

      await deleteCargo(requestId);
      setAddLoading(false);
      toast.dismiss(loadingToast);
      navigate("/cargo");
    } catch (error) {
      console.error("Error fetching cargo details:", error);
      toast.error("Failed to fetch cargo details. Please try again.");
      toast.dismiss(loadingToast);
      setAddLoading(false);
    }
  };

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        onConfirm={handleDeleteCargo}
        title={"Do you want to delete this Request?"}
        message={
          "NOTE: Once deleted, all details associated with this request will be deleted as well and can not be recovered."
        }
      />
      <div className="admin">
        <AdminHeader />
        <main>
          <article className="container article">
            <section className="tasks">
              <div className="section-title-wrapper">
                <h2 className="section-title">Requests</h2>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    style={{
                      width: "100%",
                      padding: "10px",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      fontSize: "16px",
                      background: "#fffffff",
                    }}
                  />
                </div>

                <div className="pagination">
                  <div>
                    Page {currentPage} of {totalPages}
                  </div>
                </div>
              </div>
              {loading2 ? (
                <ul className="tasks-list loading">
                  {[...Array(5)].map((_, index) => (
                    <li className="tasks-item loading-item" key={index}>
                      <div className="card task-card">
                        <div className="card-input loading-checkbox"></div>
                        <div className="card-badge radius-pill loading-badge"></div>
                        <div className="card-badge radius-pill loading-badge"></div>
                        <div className="card-badge radius-pill loading-badge"></div>
                        <ul className="card-meta-list">
                          <li>
                            <div className="meta-box icon-box loading-meta"></div>
                          </li>
                        </ul>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <>
                  <ul className="tasks-list">
                    {currentItems.map((cargo, index) => (
                      <li className="tasks-item" key={index}>
                        <div className="card task-card">
                          <div className="card-input">
                            <input
                              type="checkbox"
                              name={`task-${index}`}
                              id={`task-${index}`}
                            />
                            <label
                              htmlFor={`task-${index}`}
                              className="task-label"
                            >
                              Tracking No: {cargo.receiptNo}
                            </label>
                          </div>
                          <div
                            className={`card-badge ${
                              cargo.status === "Confirmed"
                                ? "cyan"
                                : "radius-pill"
                            }`}
                          >
                            {cargo.status}
                          </div>
                          <div className="card-badge">{cargo.shipFrom}</div>
                          <div className="card-badge">{cargo.shippingMode}</div>
                          <ul className="card-meta-list">
                            <li>
                              <button onClick={() => makeCargo(cargo._id)}>
                                <div
                                  className="card-badge orange"
                                  style={{
                                    display: "flex",
                                    textAlign: "center",
                                  }}
                                >
                                  <p
                                    style={{
                                      fontSize: "13px",
                                      marginLeft: "2px",
                                      marginTop: "2px",
                                    }}
                                  >
                                    {addLoading ? "Adding..." : "Make it Cargo"}
                                  </p>
                                </div>
                              </button>
                            </li>
                            <li>
                              <div
                                className="meta-box icon-box"
                                style={{ cursor: "pointer" }}
                                onClick={() => toggleMenu(cargo._id)}
                              >
                                <i className="bx bx-list-ul icon"></i>
                                <span>{cargo.list}</span>
                              </div>
                            </li>
                            {menuVisible[cargo._id] && (
                              <ul
                                className="ctx-menu"
                                style={{ width: "fit-content", zIndex: "99" }}
                              >
                                <li className="ctx-item">
                                  <button className="ctx-menu-btn icon-box">
                                    <i className="bx bx-money icon"></i>

                                    <span className="ctx-menu-text">
                                      Amount:{" "}
                                      <strong>
                                        {cargo?.currency}{" "}
                                        {Number(cargo.amount).toLocaleString()}
                                      </strong>
                                    </span>
                                  </button>
                                </li>
                                <li className="ctx-item">
                                  <button className="ctx-menu-btn icon-box">
                                    <i className="bx bx-message-square-detail icon"></i>

                                    <span className="ctx-menu-text">
                                      {cargo.email}
                                    </span>
                                  </button>
                                </li>
                                <li className="ctx-item">
                                  <button className="ctx-menu-btn icon-box">
                                    <i className="bx bx-phone icon"></i>
                                    <span className="ctx-menu-text">
                                      {cargo.phone}
                                    </span>
                                  </button>
                                </li>

                                <li className="ctx-item">
                                  <button className="ctx-menu-btn icon-box">
                                    <i className="bx bx-message icon"></i>

                                    <span className="ctx-menu-text">
                                      {cargo.quantitynquality}
                                    </span>
                                  </button>
                                </li>
                                <li className="ctx-item">
                                  <button className="ctx-menu-btn icon-box">
                                    <i className="bx bx-time icon"></i>
                                    <span className="ctx-menu-text">
                                      {new Date(
                                        cargo.createdAt
                                      ).toLocaleString()}
                                    </span>
                                  </button>
                                </li>

                                <li className="divider"></li>

                                <li className="ctx-item">
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <button className="ctx-menu-btn icon-box">
                                      <i className="bx bx-refresh icon"></i>

                                      <select
                                        className="status-select"
                                        style={{
                                          marginLeft: "10px",
                                          padding: "8px",
                                          border: "1px solid #ccc",
                                          borderRadius: "4px",
                                          fontSize: "14px",
                                          cursor: "pointer",
                                        }}
                                        value={selectedStatus}
                                        onChange={(e) =>
                                          setSelectedStatus(e.target.value)
                                        }
                                      >
                                        <option value={`${cargo.status}`}>
                                          {cargo.status}
                                        </option>
                                        <option value="Received">
                                          Received
                                        </option>
                                        <option value="Confirmed">
                                          Confirmed
                                        </option>
                                      </select>
                                    </button>
                                    <button
                                      className="ctx-menu-btn"
                                      onClick={() =>
                                        handleStatusChange(
                                          cargo._id,
                                          cargo.userId
                                        )
                                      }
                                      style={{
                                        border: "1px solid #ccc",
                                        backgroundColor: "#fff",
                                      }}
                                    >
                                      <span className="ctx-menu-text">
                                        {loading
                                          ? "Updating..."
                                          : "Update Status"}
                                      </span>
                                    </button>
                                  </div>
                                </li>
                                <i
                                  className="bx bx-x-circle icon"
                                  style={{
                                    position: "absolute",
                                    top: "2px",
                                    right: "2px",
                                    padding: "5px",
                                    cursor: "pointer",
                                    fontSize: "24px",
                                  }}
                                  onClick={() => toggleMenu(cargo._id)}
                                ></i>
                              </ul>
                            )}

                            <li>
                              <div
                                className="card-badge orange"
                                onClick={() => {
                                  openModal();
                                  setCargoId(cargo._id);
                                }}
                              >
                                <i
                                  className="bx bx-trash icon"
                                  style={{ fontSize: "25px" }}
                                ></i>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </li>
                    ))}
                  </ul>
                </>
              )}
              {!loading2 && currentItems?.length === 0 && (
                <li className="tasks-item loading-item">
                  <div className="card task-card">
                    <div className="card-input loading-checkbox"></div>
                    <div className="card-badge radius-pill loading-badge"></div>
                    <div className="card-badge radius-pill loading-badge">
                      No Requests
                    </div>
                    <div className="card-badge radius-pill loading-badge"></div>
                    <ul className="card-meta-list">
                      <li>
                        <div className="meta-box icon-box loading-meta"></div>
                      </li>
                    </ul>
                  </div>
                </li>
              )}

              <div
                style={{ display: "flex", marginTop: "6px", flexWrap: "wrap" }}
              >
                {pageNumbers.map((number) => (
                  <button
                    key={number}
                    className={`pag-button ${
                      currentPage === number ? "active" : ""
                    }`}
                    onClick={() => paginate(number)}
                  >
                    {number}
                  </button>
                ))}
              </div>
            </section>
          </article>
        </main>
      </div>
    </>
  );
};

export default AdminRequestsPage;
