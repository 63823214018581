import React, { useEffect, useState } from "react";
import "../admin/admin.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import jwtDecode from "jwt-decode";
import logo from "../common/logo.png";
import { server } from "../../server";
import axios from "axios";
import WhatsAppButton from "../common/WhatsAap";

const UserHeader = () => {
  const [headerActive, setHeaderActive] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);

  const toggleHeader = () => {
    setHeaderActive(!headerActive);
  };
  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };
  const navigate = useNavigate();
  const location = useLocation();

  const handelLogout = () => {
    localStorage.removeItem("userToken");
    navigate("/");
    toast.success("See you Soon😊");
  };

  const [decodedToken, setDecodedToken] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("userToken");
    if (token) {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
        setDecodedToken(null);
        localStorage.removeItem("userToken");
      } else {
        setDecodedToken(decoded);
      }
    } else {
      setDecodedToken(null);
    }
  }, []);

  // console.log("decoded token", decodedToken);

  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    // const interval = setInterval(() => {
    fetchNotifications();
    // }, 1000);
    // return () => clearInterval(interval);
  }, []);

  const fetchNotifications = async () => {
    const token = localStorage.getItem("userToken");
    if (!token) {
      toast.error("Please Login Again");
      throw new Error("Authentication token not found");
    }
    if (token) {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
        setDecodedToken(null);
        localStorage.removeItem("userToken");
      } else {
        try {
          const response = await axios.get(
            `${server}/usernotifications/${decoded.id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if (response.status !== 200) {
            toast.error("Error occurred");
            throw new Error("Network response was not ok.");
          }
          const data = response.data;

          const sortedCargos = data.data.sort((a, b) => {
            return new Date(b.createdAt) - new Date(a.createdAt);
          });
          setNotifications(sortedCargos);
        } catch (error) {
          console.error("Error fetching notifications:", error);
        }
      }
    }
  };

  const markAsRead = (notId) => {
    const deletePromise = async () => {
      try {
        const token = localStorage.getItem("userToken");
        if (!token) {
          throw new Error("Authentication token not found");
        }

        const response = await axios.delete(
          `${server}/deleteusernotifications/${notId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          fetchNotifications();
          return "Marked as Read";
        } else {
          throw new Error("Failed to mark as read");
        }
      } catch (error) {
        console.error("Error marking as read:", error);
        throw error;
      }
    };

    // Use toast.promise to handle loading, success, and error messages
    toast.promise(deletePromise(), {
      loading: "marking...",
      success: <b>Marked as read</b>,
      error: <b>Failed to mark as read</b>,
    });
  };

  const clearAllNots = async () => {
    try {
      const token = localStorage.getItem("userToken");
      if (!token) {
        throw new Error("Authentication token not found");
      }
      if (token) {
        const decoded = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
          setDecodedToken(null);
          localStorage.removeItem("userToken");
        } else {
          const response = await axios.delete(
            `${server}/usernotifications/${decoded.id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if (response.status === 200) {
            fetchNotifications();
            return "Cleared all";
          } else {
            throw new Error("Failed to clear");
          }
        }
      }
    } catch (error) {
      console.error("Error in clearing:", error);
      throw error;
    }
  };

  const [cargos, setCargos] = useState([]);
  useEffect(() => {
    fetchCargos();
  }, []);

  const fetchCargos = async () => {
    const token = localStorage.getItem("userToken");
    if (!token) {
      toast.error("Please Login Again");
      throw new Error("Authentication token not found");
    }
    if (token) {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
        localStorage.removeItem("userToken");
      } else {
        try {
          const response = await fetch(`${server}/usercargos/${decoded.id}`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          if (!response.ok) {
            toast.error("Error occurred");
            console.log("error", response);
            throw new Error("Network response was not ok.");
          }
          const data = await response.json();

          const sortedCargos = data.data.sort((a, b) => {
            return new Date(b.createdAt) - new Date(a.createdAt);
          });
          setCargos(sortedCargos);
        } catch (error) {
          console.error("Error fetching cargos:", error);
        }
      }
    }
  };

  return (
    <>
      <div className="admin">
        <header
          className={`header ${headerActive ? "active" : ""}`}
          data-header
          id="header"
        >
          <div className="container">
            <h1>
              <Link to="/" className="logo">
                <img src={logo} alt="bani-logo" style={{ maxWidth: "70%" }} />
              </Link>
            </h1>
            <button
              onClick={() => {
                toggleMenu();
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
              className="notification menu-toggle-btn icon-box"
              style={{
                position: "relative",
              }}
            >
              <i className="bx bx-bell icon"></i>
              {notifications?.length > 0 && (
                <span
                  style={{
                    position: "absolute",
                    top: "-10px",
                    left: "-10px",
                    background: "#f00",
                    color: "#fff",
                    borderRadius: "5px",
                    padding: "4px 6px",
                    fontSize: "12px",
                  }}
                >
                  {notifications?.length < 10
                    ? `0${notifications?.length}`
                    : notifications?.length}
                </span>
              )}
            </button>
            <button
              className="menu-toggle-btn icon-box"
              onClick={toggleHeader}
              aria-label="Toggle Menu"
            >
              <i class="bx bx-menu icon"></i>
            </button>

            <nav className="navbar">
              <div className="container">
                <ul className="navbar-list">
                  <li>
                    <Link
                      to="/"
                      className={`navbar-link ${
                        location.pathname === "/" ? "active" : ""
                      } icon-box`}
                    >
                      <i class="bx bx-home icon"></i>

                      <span className="home-icon">Home</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/profile"
                      className={`navbar-link ${
                        location.pathname === "/profile" ? "active" : ""
                      } icon-box`}
                    >
                      <i class="bx bx-grid-alt icon"></i>

                      <span>Profile</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/user-cargo"
                      className={`navbar-link ${
                        location.pathname === "/user-cargo" ||
                        location.pathname === "/addcargo"
                          ? "active"
                          : ""
                      } icon-box notification`}
                      style={{
                        position: "relative",
                      }}
                    >
                      <i class="bx bx-folder icon"></i>

                      <span>My Packages</span>
                      {cargos?.length > 0 && (
                        <span
                          style={{
                            position: "absolute",
                            top: "-10px",
                            right: "-10px",
                            background: "#06d194",
                            color: "#fff",
                            borderRadius: "5px",
                            padding: "4px 6px",
                            fontSize: "12px",
                          }}
                        >
                          {cargos?.length < 10
                            ? `0${cargos?.length}`
                            : cargos?.length}
                        </span>
                      )}
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/my-payments"
                      className={`navbar-link ${
                        location.pathname === "/my-payments" ? "active" : ""
                      } icon-box`}
                      // onClick={() => toast.error("Coming soon. We promise.")}
                    >
                      <i class="bx bx-user icon"></i>

                      <span>My Payments</span>
                    </Link>
                  </li>
                  <li>
                    <button
                      className="navbar-link icon-box"
                      onClick={() => handelLogout()}
                    >
                      <i class="bx bx-log-out icon"></i>

                      <span>Logout</span>
                    </button>
                  </li>
                </ul>
                <ul className="user-action-list">
                  <li>
                    <button
                      onClick={() => {
                        toggleMenu();
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}
                      className="notification icon-box"
                      style={{
                        position: "relative",
                      }}
                    >
                      <i className="bx bx-bell icon"></i>
                      {notifications?.length > 0 && (
                        <span
                          style={{
                            position: "absolute",
                            top: "-10px",
                            right: "-10px",
                            background: "#f00",
                            color: "#fff",
                            borderRadius: "5px",
                            padding: "4px 6px",
                            fontSize: "12px",
                          }}
                        >
                          {notifications?.length < 10
                            ? `0${notifications?.length}`
                            : notifications?.length}
                        </span>
                      )}
                    </button>
                  </li>

                  <li>
                    <Link to="/profile" className="header-profile">
                      <figure className="profile-avatar">
                        <img
                          src="https://res.cloudinary.com/dxxhtz2dw/image/upload/v1711486062/hero1_ymbowl.png"
                          alt="Elizabeth Foster"
                          width="32"
                          height="32"
                        />
                      </figure>
                      <div>
                        <p className="profile-title">
                          {decodedToken?.name?.split(" ")[0]}
                        </p>
                        <p className="profile-subtitle">{decodedToken?.role}</p>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </header>
        {menuVisible && (
          <ul className="ctx-menu2">
            <li className="ctx-item">
              <button className="ctx-menu-btn icon-box">
                <span
                  className="ctx-menu-text green"
                  style={{ color: "#6c63ff", fontSize: "15px" }}
                >
                  Notifications
                </span>
              </button>
            </li>
            <li className="divider"></li>
            {notifications &&
              notifications.map((nots, index) => (
                <li className="ctx-item" key={index}>
                  <button
                    className="ctx-menu-btn icon-box"
                    style={{ position: "relative" }}
                  >
                    <div className="">
                      <div
                        style={{
                          display: "flex",
                          color: "#17a4ba",
                        }}
                      >
                        <p style={{ justifyContent: "" }}>{nots.title}</p>{" "}
                        <p
                          style={{ position: "absolute", right: "0" }}
                          onClick={() => markAsRead(nots._id)}
                        >
                          mark read
                        </p>
                      </div>
                      <div>{nots.message}</div>
                      <div style={{ fontSize: "11px" }}>
                        {new Date(nots.createdAt).toLocaleString()}
                      </div>
                    </div>
                  </button>
                </li>
              ))}

            {notifications.length === 0 && (
              <li className="ctx-item">
                <button className="ctx-menu-btn icon-box">
                  <div className="">
                    <div>You Have No Notification At The Moment</div>
                  </div>
                </button>
              </li>
            )}

            <li className="divider"></li>
            {notifications.length !== 0 && (
              <li className="ctx-item">
                <button
                  className="ctx-menu-btn red icon-box"
                  onClick={() => {
                    clearAllNots();
                  }}
                >
                  <i class="bx bx-trash icon"></i>

                  <span className="ctx-menu-text">Clear All</span>
                </button>
              </li>
            )}

            <i
              class="bx bx-x-circle icon"
              onClick={() => toggleMenu()}
              style={{
                position: "absolute",
                top: "2px",
                right: "2px",
                padding: "5px",
                cursor: "pointer",
                fontSize: "24px",
              }}
            ></i>
          </ul>
        )}
      </div>
      <WhatsAppButton />
    </>
  );
};

export default UserHeader;
