import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import carousel1 from "./carousel1.svg";
import carousel2 from "./carousel2.svg";
import carousel3 from "./carousel3.svg";
import carousel4 from "./carousel4.svg";

const Hero = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const slides = [
    {
      title: "We're Global",
      subtitle: "Logistics Partners",
      description:
        "Order your goods all over the World at any time and we will deliver them right to where you are.",
      img: carousel1,
    },
    {
      title: "Efficient Shipping",
      subtitle: "Fast & Reliable",
      description:
        "Experience quick and dependable shipping solutions tailored to meet your needs, ensuring your goods arrive on time, every time.",
      img: carousel2,
    },
    {
      title: "Tracking",
      subtitle: "Real-Time Updates",
      description:
        "Stay informed with real-time tracking of your shipments. Our advanced tracking system lets you monitor your delivery status with ease.",
      img: carousel3,
    },
    {
      title: "Customized Solutions",
      subtitle: "Tailored Services",
      description:
        "Benefit from our flexible logistics solutions designed to fit your specific requirements, making sure your shipping needs are met efficiently.",
      img: carousel4,
    },
  ];

  const nextSlide = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
  }, [slides.length]);

  const prevSlide = useCallback(() => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + slides.length) % slides.length
    );
  }, [slides.length]);

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, 10000);
    return () => clearInterval(interval);
  }, [nextSlide]);

  return (
    <section className="home section" id="home">
      <div className="home__container container">
        <div className="carousel">
          <div
            className="carousel__inner"
            style={{ transform: `translateX(-${currentIndex * 100}%)` }}
          >
            {slides.map((slide, index) => (
              <div className="carousel__slide hero__page" key={index}>
                <div className="home__data item1">
                  <h1 className="home__title">
                    {slide.title} <br />
                    {slide.subtitle}
                  </h1>
                  <p className="home__description">{slide.description}</p>
                  <Link
                    to="/quotation"
                    className="button"
                    style={{ display: "flex", width: "fit-content" }}
                  >
                    <i className="bx bxs-quote-alt-left button__icon"></i>
                    <p style={{ marginLeft: "5px" }}>Get Quotation</p>
                  </Link>
                </div>
                <img src={slide.img} alt="carousel" className="carousel-img" />
              </div>
            ))}
          </div>
          <button
            className="carousel__button carousel__button--prev"
            onClick={prevSlide}
          >
            <i className="bx bxs-chevron-left" style={{ marginTop: "3px" }}></i>
          </button>
          <button
            className="carousel__button carousel__button--next"
            onClick={nextSlide}
          >
            <i className="bx bxs-chevron-right"></i>
          </button>
          <div className="carousel__dots">
            {slides.map((_, index) => (
              <div
                key={index}
                className={`carousel__dot ${
                  index === currentIndex ? "active" : ""
                }`}
                onClick={() => goToSlide(index)}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
