import React, { useState } from "react";
import "./contacts.css";
import { server } from "../../server";
import toast from "react-hot-toast";
import axios from "axios";

const Contacts = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    title: "Contacts",
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    for (const key in formData) {
      if (formData[key].trim() === "") {
        toast.error(`${key} is required.`);
        return;
      }
    }
    try {
      setLoading(true);

      const response = await fetch(`${server}/sendcontactsemail`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      await axios.post(`${server}/notifications`, {
        title: "Contacts",
        message: formData.message,
      });

      if (response.ok) {
        await response.json();
        setLoading(false);
        toast.success(
          "We received your email, we will reply as soon as possible",
          {
            duration: 10000,
          }
        );

        setFormData({ name: "", email: "", message: "" });
      } else {
        setLoading(false);
        toast.error("Error Occurred. Try Again");
        console.error("Failed to send message");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error sending message:", error);
      toast.error("Error Occurred. Try Again");
    }
  };

  return (
    <div className="contacts__section">
      <section className="contact" id="contact">
        <div className="container">
          <h2 className="section__title">Contact Us</h2>

          <p className="section-text" style={{ textAlign: "center" }}>
            Send us an email here and we will get back to you as fast as we can.
            We promise.
          </p>

          <div className="contact-wrapper">
            <form onSubmit={handleSubmit} className="contact-form">
              <div className="wrapper-flex">
                <div className="input-wrapper">
                  <label htmlFor="name">Name*</label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Enter Your Name"
                    className="input-field"
                    value={formData.name}
                    onChange={handleChange}
                    style={{ backgroundColor: "#fff" }}
                  />
                </div>

                <div className="input-wrapper">
                  <label htmlFor="email">Email*</label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Enter Your Email"
                    className="input-field"
                    value={formData.email}
                    onChange={handleChange}
                    style={{ backgroundColor: "#fff" }}
                  />
                </div>
              </div>

              <label htmlFor="message">Message*</label>
              <textarea
                name="message"
                id="message"
                placeholder="Type Your Message"
                className="input-field"
                value={formData.message}
                onChange={handleChange}
                style={{ backgroundColor: "#fff" }}
              ></textarea>

              <button
                type="submit"
                className="btn btn-primary"
                style={{
                  border: "none",
                  fontFamily: "Poppins",
                  cursor: "pointer",
                }}
              >
                <span>{loading ? "Sending..." : "Send Message"}</span>
                <i className="bx bxl-telegram" style={{ fontSize: "20px" }}></i>
              </button>
            </form>

            <ul className="contact-list">
              <li>
                <a
                  href="mailto:info@banilogistics.co.ke"
                  className="contact-link"
                >
                  <i
                    className="bx bx-envelope"
                    style={{
                      fontSize: "20px",
                      color: "hsl(210, var(--sat), 60%)",
                      marginTop: "3px",
                    }}
                  ></i>
                  <span> info@banilogistics.co.ke</span>
                </a>
              </li>

              <li>
                <a
                  href="https://www.banilogistics.co.ke"
                  className="contact-link"
                >
                  <i
                    className="bx bx-globe"
                    style={{
                      fontSize: "20px",
                      color: "hsl(210, var(--sat), 60%)",
                      marginTop: "3px",
                    }}
                  ></i>
                  <span> www.banilogistics.co.ke</span>
                </a>
              </li>

              <li>
                <a href="tel:+0011234567890" className="contact-link">
                  <i
                    className="bx bx-phone-call"
                    style={{
                      fontSize: "20px",
                      color: "hsl(210, var(--sat), 60%)",
                      marginTop: "3px",
                    }}
                  ></i>
                  <span> (+254) 795 172 846</span>
                </a>
              </li>

              <li>
                <div className="contact-link">
                  <i
                    className="bx bx-time-five"
                    style={{
                      fontSize: "20px",
                      color: "hsl(210, var(--sat), 60%)",
                      marginTop: "3px",
                    }}
                  ></i>
                  <span> 9:00 AM - 7:00 PM</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contacts;
