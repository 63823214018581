import React, { useState } from "react";
import Header from "./common/Header";
import Footer from "./common/Footer";
import toast from "react-hot-toast";
import axios from "axios";
import { server } from "../server";
import quote from "./quote.svg";
// import Select from "react-select";
// import countryList from "react-select-country-list";

const Quotation = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    ship: "",
    quantitynquality: "",
  });
  // const [shippingData, setShippingData] = useState({
  //   fromCountry: "",
  //   toCountry: "",
  //   weight: "",
  //   unit: "kg",
  //   shippingType: "parcel",
  // });

  const [loading, setLoading] = useState(false);
  // const [loading2, setLoading2] = useState(false);

  // const [shippingRate, setShippingRate] = useState(null);

  // const countryOptions = countryList().getData();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // const handleShippingChange = (e) => {
  //   setShippingData({ ...shippingData, [e.target.name]: e.target.value });
  // };

  // const handleSelectChange = (value, action) => {
  //   setShippingData({ ...shippingData, [action.name]: value.label });
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    for (const key in formData) {
      if (formData[key].trim() === "") {
        toast.error(`${key} is required.`);
        return;
      }
    }
    try {
      setLoading(true);
      const response = await axios.post(
        `${server}/sendquotationemail`,
        formData
      );
      await axios.post(`${server}/notifications`, {
        title: "Quote",
        message: formData.quantitynquality,
      });
      if (response.data.message === "Email sent success") {
        setLoading(false);
        toast.success("Quotation Requested successfully!", {
          duration: 10000,
        });
        setFormData({
          name: "",
          email: "",
          phone: "",
          ship: "",
          quantitynquality: "",
        });
      } else {
        setLoading(false);
        toast.error("Failed to send quotation request");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error submitting quotation request:", error);
      toast.error("Failed to send quotation request");
    }
  };

  // const handleCalculate = async () => {
  //   if (shippingData.fromCountry === "") {
  //     toast.error("Please select the From Country");
  //   } else if (shippingData.toCountry === "") {
  //     toast.error("Please select the To Country");
  //   } else if (shippingData.weight === "") {
  //     toast.error("Please enter the weight of the cargo");
  //   } else {
  //     setLoading2(true);
  //     try {
  //       const response = await axios.post(
  //         `${server}/calculateshipping`,
  //         shippingData
  //       );
  //       setLoading2(false);
  //       setShippingRate(response.data.rate);
  //       toast.success("Shipping rate calculated");
  //     } catch (error) {
  //       setLoading2(false);
  //       console.error("Error calculating shipping rate:", error);
  //       toast.error("Failed to calculate shipping rate");
  //     }
  //     setLoading2(false);
  //   }
  // };

  return (
    <div>
      <Header />{" "}
      <div className="contacts__section">
        <section className="contact" id="contact">
          <div className="container">
            <h2 className="section__title">Get A Quotation</h2>

            <p className="section-text" style={{ textAlign: "center" }}>
              Calculate Shipping Rates by filling the deatails or Send Us The
              Email Here And We Will Get Back To You As Fast As We Can. We
              Promise.
            </p>

            <div className="contact-wrapper">
              {/* <div className="shipping-calculator">
                <h3 style={{ marginBottom: "5px" }}>
                  Calculate Shipping Rates
                </h3>
                <form className="contact-form">
                  <div className="wrapper-flex">
                    <div className="input-wrapper">
                      <label htmlFor="fromCountry">From Country</label>
                      <Select
                        name="fromCountry"
                        options={countryOptions}
                        onChange={handleSelectChange}
                        className="select-field"
                      />
                    </div>
                    <div className="input-wrapper">
                      <label htmlFor="toCountry">To Country</label>
                      <Select
                        name="toCountry"
                        options={countryOptions}
                        onChange={handleSelectChange}
                        className="select-field"
                      />
                    </div>
                  </div>

                  <div className="wrapper-flex">
                    <div className="input-wrapper">
                      <label htmlFor="weight">Weight</label>
                      <input
                        type="number"
                        name="weight"
                        id="weight"
                        placeholder="Enter weight"
                        className="input-field"
                        value={shippingData.weight}
                        onChange={handleShippingChange}
                        style={{ backgroundColor: "#fff" }}
                      />
                    </div>

                    <div className="input-wrapper" style={{ display: "block" }}>
                      <div>
                        <label htmlFor="unit">Unit</label>
                      </div>

                      <div className="custom-select-container">
                        <select
                          name="unit"
                          className="custom-select"
                          value={shippingData.unit}
                          onChange={handleShippingChange}
                        >
                          <option value="kg">Kgs</option>
                          <option value="lb">Lbs</option>
                        </select>
                        <span className="custom-select-arrow">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="gray"
                            className="bi bi-chevron-down"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fillRule="evenodd"
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                            />
                          </svg>
                        </span>{" "}
                      </div>
                    </div>
                  </div>

                  <div className="input-wrapper">
                    <label
                      htmlFor="shippingType"
                      style={{ marginRight: "5px" }}
                    >
                      Shipping Type
                    </label>
                    <select
                      name="shippingType"
                      className="input-field"
                      value={shippingData.shippingType}
                      onChange={handleShippingChange}
                      style={{ backgroundColor: "#fff" }}
                    >
                      <option value="parcel">Parcel</option>
                      <option value="documents">Documents</option>
                    </select>
                  </div>

                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleCalculate}
                    style={{
                      border: "none",
                      fontFamily: "Poppins",
                      cursor: "pointer",
                    }}
                  >
                    {loading2 ? "Calculating..." : "Calculate"}
                  </button>
                </form>

                {shippingRate && (
                  <div className="shipping-rate-result">
                    <h4>Estimated Shipping Rate: sh. {shippingRate}</h4>
                  </div>
                )}
              </div> */}
              <form
                action=""
                className="contact-form"
                onSubmit={(e) => handleSubmit(e)}
              >
                <h3 style={{ marginBottom: "5px" }}>
                  Send Us An Email Instead
                </h3>
                <div className="wrapper-flex">
                  <div className="input-wrapper">
                    <label htmlFor="name">Name*</label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Enter Your Name"
                      className="input-field"
                      value={formData.name}
                      onChange={handleChange}
                      style={{ backgroundColor: "#fff" }}
                    />
                  </div>

                  <div className="input-wrapper">
                    <label htmlFor="email">Email*</label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Enter Your Email"
                      className="input-field"
                      value={formData.email}
                      onChange={handleChange}
                      style={{ backgroundColor: "#fff" }}
                    />
                  </div>
                </div>
                <div className="wrapper-flex">
                  <div className="input-wrapper">
                    <label htmlFor="phone">Phone Number*</label>
                    <input
                      type="phone"
                      name="phone"
                      id="phone"
                      placeholder="Your Phone Number"
                      className="input-field"
                      value={formData.phone}
                      onChange={handleChange}
                      style={{ backgroundColor: "#fff" }}
                    />
                  </div>

                  <div className="input-wrapper">
                    <label htmlFor="ship">Ship From*</label>
                    <input
                      type="ship"
                      name="ship"
                      id="ship"
                      placeholder="Ship Cargo From?"
                      className="input-field"
                      value={formData.ship}
                      onChange={handleChange}
                      style={{ backgroundColor: "#fff" }}
                    />
                  </div>
                </div>
                <label htmlFor="quantitynquality">Description*</label>
                <textarea
                  name="quantitynquality"
                  id="quantitynquality"
                  placeholder="What is shipped and Quantity"
                  className="input-field"
                  value={formData.quantitynquality}
                  onChange={handleChange}
                  style={{ backgroundColor: "#fff" }}
                ></textarea>

                <button
                  type="submit"
                  className="btn btn-primary"
                  style={{
                    border: "none",
                    fontFamily: "Poppins",
                    cursor: "pointer",
                  }}
                >
                  <span>{loading ? "Loading ..." : "Request a Quote"}</span>

                  <i
                    className="bx bxl-telegram"
                    style={{ fontSize: "20px" }}
                  ></i>
                </button>
              </form>
              <img src={quote} alt="quotation img" />
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default Quotation;
