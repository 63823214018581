import Footer from "../common/Footer";
import Header from "../common/Header";
import styles from "./faq.module.css";
import { list } from "./paragraphs";

const TermsAndConditions = () => {
  return (
    <>
      <Header />
      <div
        className={`${styles.mainFaqContainer} container `}
        style={{ marginTop: "5rem" }}
      >
        <div className={`${styles.faqcontainer}  row my-5 w-100 m-s-5`}>
          <div
            className={`${styles.smallcolumn}  mt-5 col-md-6 col-sm-12 col-lg-4  
          `}
          >
            <h1>Our Terms and Conditions</h1>
            <p>
              These Terms and Conditions govern the use of all logistics and
              shipping services provided by Bani Global Logistics Limited ("we,"
              "us," or "our"). By using our services, you ("Customer") agree to
              comply with and be bound by these terms. If you do not agree with
              any part of these terms, please refrain from using our services.
            </p>
          </div>

          <div
            className={`${styles.largecolumn}  col-md-6 col-sm-12 col-lg-8     mt-5  `}
          >
            {list.map((item, index) => (
              <div
                key={item.id}
                className={index > 0 ? "pt-5" : ""}
                id={`faqpara${item.id}`}
              >
                <h2 className={`text-uppercase h6 ${index > 0 ? "pt-3" : ""}`}>
                  {" "}
                  {item.id}- {item.heading}{" "}
                </h2>
                <p className="mb-0"> {item.content} </p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TermsAndConditions;
