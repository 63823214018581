import React, { useEffect, useState } from "react";
import AdminHeader from "./AdminHeader";
import toast from "react-hot-toast";
import { server } from "../../server";
import axios from "axios";
import Modal from "../common/Modal";

const Payments = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [payments, setPayments] = useState([]);
  const [loading2, setLoading2] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    fetchPayments();
  }, []);

  const fetchPayments = async () => {
    const token = localStorage.getItem("userToken");
    if (!token) {
      toast.error("Please Login Again");
      throw new Error("Authentication token not found");
    }
    try {
      setLoading2(true);
      const response = await fetch(`${server}/payments`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        setLoading2(false);
        toast.error("Error occurred");
        console.log("error", response);
        throw new Error("Network response was not ok.");
      }
      const data = await response.json();
      // Initialize menuVisible state for each cargo item
      const initialMenuVisible = {};
      data.data.forEach((cargo) => {
        initialMenuVisible[cargo._id] = false;
      });
      const sortedCargos = data.data.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      setPayments(sortedCargos);
    } catch (error) {
      setLoading2(false);
      console.error("Error fetching cargos:", error);
    }
    setLoading2(false);
  };

  // Filter payments based on search query
  const filteredPayments = payments.filter(
    (payment) =>
      payment.receiptNo.toLowerCase().includes(searchQuery.toLowerCase()) ||
      payment.transactionCode
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      payment.phone.toLowerCase().includes(searchQuery.toLowerCase()) ||
      payment.amount.toLowerCase().includes(searchQuery.toLowerCase()) ||
      payment.method.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredPayments.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate total pages
  const totalPages = Math.ceil(filteredPayments.length / itemsPerPage);

  // Generate page numbers
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [paymentId, setPaymentId] = useState(false);
  const [receiptNo, setReceiptNo] = useState(false);
  const [userId, setUserId] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const handleDeletePayment = () => {
    deletePayment(paymentId, receiptNo, userId);
    closeModal();
  };

  const deletePayment = (paymentId, receiptNo, userId) => {
    const deletePromise = async () => {
      try {
        const token = localStorage.getItem("userToken");
        if (!token) {
          throw new Error("Authentication token not found");
        }

        const response = await axios.delete(`${server}/payment/${paymentId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200) {
          try {
            console.log(response.data.payment.amount);
            const cargoBalanceResponse = await fetch(`${server}/add-balance`, {
              method: "PATCH",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify({
                amount: response.data.payment.amount,
                receiptNo: receiptNo,
              }),
            });

            if (!cargoBalanceResponse.ok) {
              throw new Error("Failed to update cargo balance.");
            }
            await axios.post(`${server}/usernotifications`, {
              userId: userId,
              title: "Payment Cancelled",
              message: `Your payment of sh. ${response.data.payment.amount} was cancelled.`,
            });
          } catch (error) {
            console.log(error);
          }

          fetchPayments();
          return "Payment deleted";
        } else {
          throw new Error("Failed to delete payment");
        }
      } catch (error) {
        console.error("Error deleting payment:", error);
        throw error;
      }
    };

    toast.promise(deletePromise(), {
      loading: "Deleting...",
      success: <b>Payment deleted successfully</b>,
      error: <b>Failed to delete Payment</b>,
    });
  };

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        onConfirm={handleDeletePayment}
        title={"Do you want to delete this payment?"}
        message={"NOTE: This Process can NOT be reversed."}
      />
      <div className="admin">
        <AdminHeader />

        <main>
          <article className="container article">
            <section className="tasks">
              <div className="section-title-wrapper">
                <h2 className="section-title">Payments</h2>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    style={{
                      width: "100%",
                      padding: "10px",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      fontSize: "16px",
                      background: "#fffffff",
                    }}
                  />
                </div>
                <div className="pagination">
                  <div>
                    Page {currentPage} of {totalPages}
                  </div>
                </div>
              </div>
              {loading2 ? (
                <ul className="tasks-list loading">
                  {[...Array(5)].map((_, index) => (
                    <li className="tasks-item loading-item" key={index}>
                      <div className="card task-card">
                        <div className="card-input loading-checkbox"></div>
                        <div className="card-badge radius-pill loading-badge"></div>
                        <div className="card-badge radius-pill loading-badge"></div>
                        <div className="card-badge radius-pill loading-badge"></div>
                        <ul className="card-meta-list">
                          <li>
                            <div className="meta-box icon-box loading-meta"></div>
                          </li>
                        </ul>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <>
                  <ul className="tasks-list">
                    {currentItems.map((payment, index) => (
                      <li className="tasks-item" key={index}>
                        <div className="card task-card">
                          <span className="ctx-menu-text">
                            {new Date(payment.createdAt).toLocaleString()}
                          </span>
                          <div className="card-input">
                            <label
                              htmlFor={`task-${index}`}
                              className="task-label"
                            >
                              Receipt No: {payment.receiptNo}
                            </label>
                          </div>
                          <div className="card-input">
                            <label
                              htmlFor={`task-${index}`}
                              className="task-label"
                            >
                              Transaction Code: {payment.transactionCode}
                            </label>
                          </div>
                          <div className="card-badgec">{payment.phone}</div>

                          <ul className="card-meta-list">
                            <div className="card-badge">
                              sh. {Number(payment.amount).toLocaleString()}
                            </div>

                            <div
                              className={`card-badge ${
                                payment.method === "Mpesa"
                                  ? "cyan"
                                  : payment.method === "Bank"
                                  ? "blue"
                                  : "radius-pill"
                              }`}
                            >
                              {payment.method}
                            </div>
                            <li>
                              <div
                                className="card-badge orange"
                                onClick={() => {
                                  openModal();
                                  setPaymentId(payment._id);
                                  setReceiptNo(payment.receiptNo);
                                  setUserId(payment.userId);
                                }}
                              >
                                <i
                                  class="bx bx-trash icon"
                                  style={{ fontSize: "25px" }}
                                ></i>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </li>
                    ))}
                  </ul>
                </>
              )}
              {!loading2 && currentItems?.length === 0 && (
                <li className="tasks-item loading-item">
                  <div className="card task-card">
                    <div className="card-input loading-checkbox"></div>
                    <div className="card-badge radius-pill loading-badge"></div>
                    <div className="card-badge radius-pill loading-badge">
                      No Payments
                    </div>
                    <div className="card-badge radius-pill loading-badge"></div>
                    <ul className="card-meta-list">
                      <li>
                        <div className="meta-box icon-box loading-meta"></div>
                      </li>
                    </ul>
                  </div>
                </li>
              )}
              {/* Render page numbers */}
              <div
                style={{ display: "flex", marginTop: "6px", flexWrap: "wrap" }}
              >
                {pageNumbers.map((number) => (
                  <button
                    key={number}
                    className={`pag-button ${
                      currentPage === number ? "active" : ""
                    }`}
                    onClick={() => paginate(number)}
                  >
                    {number}
                  </button>
                ))}
              </div>
            </section>
          </article>
        </main>
      </div>
    </>
  );
};

export default Payments;
