import React from "react";

const About = () => {
  return (
    <div>
      <section class="about section container" id="about">
        <div class="about__container grid">
          <div class="about__data">
            <h2 class="section__title-center">
              Find Out A Little More <br />
              About Us
            </h2>
            <div
              class="about__description"
              style={{ textAlign: "start", display: "flex" }}
            >
              <i
                class="bx bxs-check-circle button__icon"
                style={{ color: "#008000a1" }}
              ></i>
              <p style={{ marginLeft: "3px" }}>
                At{" "}
                <strong style={{ color: "#6699ff" }}>
                  Bani Global Logistics Limited
                </strong>
                , we're committed to transforming the logistics landscape by
                tailoring solutions that grow with your business needs.
              </p>
            </div>
            <div
              class="about__description"
              style={{ textAlign: "start", display: "flex" }}
            >
              <i
                class="bx bxs-check-circle button__icon"
                style={{ color: "#008000a1" }}
              ></i>
              <p style={{ marginLeft: "3px" }}>
                From smart shipping and global fulfilment to personalized
                delivery and seamless returns, our company is dedicated to
                providing the
                <strong style={{ color: "#6699ff" }}>
                  {" "}
                  agility, transparency, and efficiency
                </strong>{" "}
                you need to stay ahead in today's fast-paced market.
              </p>
            </div>
          </div>

          <svg
            class="svg__img svg__color about__img"
            viewBox="0 0 312 236"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0)">
              <path
                class="svg__blob"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M146.137 4.22643C177.748 -2.97581 206.274 23.1576 229.992 40.5258C251.017 55.9222 262.978 75.8309 272.499 96.7598C282.192 118.066 300.281 142.538 285.25 161.993C269.962 181.783 230.296 177.103 202.881 186.874C182.644 194.086 167.895 207.639 146.137 211.573C119.351 216.416 86.2133 225.465 65.5354 211.707C44.5361 197.736 61.8497 170.746 54.1954 150.402C47.0089 131.301 13.4763 116.078 22.5437 97.4402C31.6999 78.6197 73.623 86.0355 92.3992 71.8745C117.647 52.8331 111.996 12.005 146.137 4.22643Z"
              />
              <path
                d="M68.8453 179.238L68.0641 222.591L0.104162 222.748L0 178.815L68.8453 179.238Z"
                fill="#EBEBEB"
              />
              <path
                d="M29.3524 201.029C29.5669 189.093 29.6681 179.415 29.5787 179.414C29.4892 179.412 29.2428 189.087 29.0284 201.023C28.814 212.96 28.7128 222.637 28.8022 222.639C28.8917 222.641 29.138 212.966 29.3524 201.029Z"
                fill="#E0E0E0"
              />
              <path
                d="M0.0983887 222.748C0.178558 222.588 0.271444 222.435 0.376152 222.29L1.21523 221.016L4.36903 216.382L14.8605 201.126C18.9575 195.177 22.6437 189.773 25.2767 185.841C26.5903 183.871 27.6377 182.267 28.3552 181.161L29.1712 179.875C29.2594 179.72 29.3601 179.573 29.4721 179.435C29.4017 179.597 29.3185 179.754 29.2233 179.904L28.4594 181.224C27.7708 182.383 26.7581 183.981 25.4676 185.968C22.8867 189.936 19.2295 195.363 15.1324 201.311C11.0354 207.26 7.28556 212.617 4.56578 216.492L1.33097 221.068L0.439807 222.308C0.338969 222.464 0.224734 222.611 0.0983887 222.748Z"
                fill="#E0E0E0"
              />
              <path
                d="M28.8008 222.626C28.685 222.492 28.5805 222.349 28.4883 222.197L27.6434 220.941L24.5764 216.307L14.3744 201.062C10.3757 195.114 6.79367 189.71 4.24749 185.759C2.9744 183.784 1.93277 182.186 1.26151 181.068L0.468721 179.782C0.369518 179.637 0.28242 179.484 0.208313 179.324C0.323934 179.459 0.428443 179.602 0.5208 179.753L1.36567 181.01L4.43266 185.644L14.6348 200.888C18.6334 206.831 22.2154 212.241 24.7616 216.185C26.0347 218.16 27.0763 219.759 27.7476 220.877L28.5404 222.163C28.6393 222.31 28.7264 222.465 28.8008 222.626Z"
                fill="#E0E0E0"
              />
              <path
                d="M35.8173 217.712L46.7294 217.908L46.7696 215.673L35.8574 215.476L35.8173 217.712Z"
                fill="#E0E0E0"
              />
              <path
                d="M41.7007 213.804C44.6505 213.787 47.0414 213.701 47.0409 213.611C47.0404 213.522 44.6487 213.463 41.6988 213.48C38.749 213.497 36.3581 213.583 36.3587 213.673C36.3592 213.762 38.7509 213.821 41.7007 213.804Z"
                fill="#E0E0E0"
              />
              <path
                d="M50.8774 178.989L50.5765 195.728L48.2212 193.857L45.1716 195.629L45.4725 178.896L50.8774 178.989Z"
                fill="#E0E0E0"
              />
              <path
                d="M60.6917 146.408L60.0957 179.492L8.22879 179.614L8.15356 146.084L60.6917 146.408Z"
                fill="#F5F5F5"
              />
              <path
                d="M30.5557 163.033C30.7193 153.924 30.7975 146.539 30.7304 146.538C30.6633 146.537 30.4763 153.92 30.3127 163.029C30.1491 172.138 30.0708 179.523 30.1379 179.524C30.205 179.525 30.3921 172.142 30.5557 163.033Z"
                fill="#E0E0E0"
              />
              <path
                d="M8.22879 179.608C8.28864 179.487 8.35831 179.371 8.43711 179.261L9.07945 178.288L11.4867 174.749L19.4956 163.124C22.6205 158.583 25.4387 154.436 27.4409 151.459C28.442 149.959 29.2406 148.731 29.7903 147.885L30.4153 146.9C30.4835 146.783 30.5609 146.67 30.6468 146.565C30.593 146.689 30.5291 146.809 30.4558 146.924L29.8771 147.931C29.3505 148.794 28.5751 150.034 27.5914 151.552C25.6238 154.581 22.8288 158.722 19.704 163.263C16.5791 167.804 13.7147 171.893 11.6372 174.847L9.16625 178.322L8.4892 179.267C8.41197 179.388 8.32486 179.502 8.22879 179.608Z"
                fill="#E0E0E0"
              />
              <path
                d="M30.1318 179.516C30.044 179.414 29.9647 179.306 29.8945 179.191L29.2522 178.236L26.9375 174.697L19.1022 163.06C16.0525 158.519 13.3154 154.372 11.371 151.383C10.3989 149.878 9.635 148.655 9.09104 147.804L8.51236 146.819C8.4374 146.707 8.37161 146.589 8.31561 146.466C8.40338 146.567 8.48273 146.676 8.55287 146.79L9.1952 147.746L11.5099 151.285L19.3221 162.898C22.3717 167.433 25.1088 171.586 27.0474 174.575C28.0196 176.081 28.7835 177.303 29.3274 178.154L29.9061 179.139C29.9921 179.258 30.0677 179.384 30.1318 179.516Z"
                fill="#E0E0E0"
              />
              <path
                d="M35.8769 175.523L44.204 175.689L44.2382 173.981L35.911 173.814L35.8769 175.523Z"
                fill="#E0E0E0"
              />
              <path
                d="M39.9833 172.778C42.2332 172.765 44.0568 172.7 44.0564 172.633C44.056 172.566 42.2318 172.522 39.9819 172.535C37.7319 172.548 35.9083 172.613 35.9087 172.68C35.9091 172.747 37.7333 172.791 39.9833 172.778Z"
                fill="#EBEBEB"
              />
              <path
                d="M50.2003 146.217L49.9746 158.989L48.1749 157.558L45.8429 158.913L46.0743 146.142L50.2003 146.217Z"
                fill="#E0E0E0"
              />
              <path
                d="M66.3107 219.62C65.7022 217.817 64.5641 216.24 63.0453 215.095C61.5265 213.949 59.6978 213.29 57.7983 213.202L56.207 125.539H63.8687V126.848H65.3096V123.709H53.4062C53.4062 123.709 54.8239 188.383 55.854 213.289C53.6017 213.636 51.5529 214.793 50.0906 216.542C48.6283 218.291 47.8527 220.514 47.9087 222.794L20.7745 222.429L21.55 225.997L48.3022 225.261C48.337 225.383 48.3659 225.51 48.4064 225.632C48.7762 226.841 49.3844 227.963 50.1949 228.932C51.0055 229.902 52.0019 230.699 53.1254 231.276C54.2489 231.854 55.4767 232.2 56.7361 232.294C57.9955 232.389 59.2611 232.23 60.458 231.826C61.6549 231.423 62.7589 230.783 63.7047 229.945C64.6504 229.108 65.4188 228.089 65.9644 226.948C66.5101 225.808 66.8218 224.57 66.8813 223.308C66.9408 222.045 66.7467 220.783 66.3107 219.597V219.62Z"
                fill="#E0E0E0"
              />
              <path
                d="M25.9768 231.227H27.4177L30.1838 225.789L27.8923 225.852L25.9768 231.227Z"
                fill="#E0E0E0"
              />
              <path
                d="M139.826 27.016H81.2869V146.837H139.826V27.016Z"
                fill="#EBEBEB"
              />
              <path
                d="M110.533 27.016C110.533 27.016 98.5315 81.3689 81.2637 85.0874C81.2637 85.0874 88.2426 123.211 88.8734 146.843H81.2869V27.016H110.533Z"
                fill="#E0E0E0"
              />
              <path
                d="M110.753 27.016C110.753 27.016 122.755 81.3689 140.046 85.0816C140.046 85.0816 133.067 123.205 132.436 146.837H140.046V27.016H110.753Z"
                fill="#E0E0E0"
              />
              <path
                d="M111.627 26.7901H109.492V146.843H111.627V26.7901Z"
                fill="#FAFAFA"
              />
              <path
                d="M95.3546 26.7901H94.8279V146.843H95.3546V26.7901Z"
                fill="#FAFAFA"
              />
              <path
                d="M126.956 26.7901H126.43V146.843H126.956V26.7901Z"
                fill="#FAFAFA"
              />
              <path
                d="M139.832 56.5556V57.0363L81.2869 57.2796V56.7989L139.832 56.5556Z"
                fill="#FAFAFA"
              />
              <path
                d="M139.832 116.173V116.643L81.2869 116.88V116.405L139.832 116.173Z"
                fill="#FAFAFA"
              />
              <path
                d="M139.826 85.7245H81.2869V87.7227H139.826V85.7245Z"
                fill="#FAFAFA"
              />
              <path
                d="M140.694 147.706H80.442V26.1472H140.694V147.706ZM82.1549 145.968H138.958V27.8848H82.1781L82.1549 145.968Z"
                fill="#F5F5F5"
              />
              <path
                d="M311.132 27.016H252.593V146.837H311.132V27.016Z"
                fill="#EBEBEB"
              />
              <path
                d="M281.862 27.016C281.862 27.016 269.861 81.3689 252.593 85.0874C252.593 85.0874 259.572 123.211 260.203 146.843H252.593V27.016H281.862Z"
                fill="#E0E0E0"
              />
              <path
                d="M282.059 27.016C282.059 27.016 294.061 81.3689 311.335 85.0816C311.335 85.0816 304.356 123.205 303.725 146.837H311.335V27.016H282.059Z"
                fill="#E0E0E0"
              />
              <path
                d="M282.933 26.7901H280.798V146.843H282.933V26.7901Z"
                fill="#FAFAFA"
              />
              <path
                d="M266.661 26.7901H266.134V146.843H266.661V26.7901Z"
                fill="#FAFAFA"
              />
              <path
                d="M298.262 26.7901H297.736V146.843H298.262V26.7901Z"
                fill="#FAFAFA"
              />
              <path
                d="M311.132 56.5556V57.0363L252.593 57.2796V56.7989L311.132 56.5556Z"
                fill="#FAFAFA"
              />
              <path
                d="M311.132 116.173V116.643L252.593 116.88V116.405L311.132 116.173Z"
                fill="#FAFAFA"
              />
              <path
                d="M311.132 85.7245H252.593V87.7227H311.132V85.7245Z"
                fill="#FAFAFA"
              />
              <path
                d="M312 147.706H251.731V26.1472H312V147.706ZM253.461 145.968H310.264V27.8848H253.467L253.461 145.968Z"
                fill="#F5F5F5"
              />
              <path
                d="M129.103 214.054C127.006 213.07 124.65 212.783 122.379 213.237C121.158 213.44 119.891 213.816 119.057 214.72C118.224 215.623 118.068 217.251 119.022 218.039C119.637 218.456 120.378 218.644 121.117 218.572C122.894 218.572 124.867 218.236 126.285 219.307C127.095 219.915 127.633 220.935 128.6 221.236C129.084 221.344 129.59 221.292 130.044 221.09C130.497 220.888 130.873 220.545 131.117 220.112C131.58 219.219 131.681 218.182 131.4 217.216C131.286 216.548 131.019 215.915 130.621 215.367C130.222 214.818 129.703 214.369 129.103 214.054Z"
                fill="#EBEBEB"
              />
              <path
                d="M133.79 205.001C132.54 206.791 130.11 206.194 128.982 205.105C127.853 204.016 127.379 202.435 126.945 200.923C125.955 197.477 124.954 193.863 125.695 190.353C125.868 189.281 126.335 188.279 127.043 187.457C127.399 187.057 127.853 186.757 128.361 186.587C128.869 186.418 129.412 186.384 129.936 186.489C131.163 186.814 131.921 188.013 132.471 189.159C134.093 192.534 134.943 196.228 134.959 199.973C134.959 201.711 134.763 203.541 133.802 204.978"
                fill="#EBEBEB"
              />
              <path
                d="M137.222 207.567C136.58 206.727 136.152 205.743 135.976 204.701C135.799 203.658 135.879 202.588 136.209 201.583C136.895 199.576 138.12 197.796 139.751 196.44C141.608 194.788 143.771 193.515 146.116 192.693C147.021 192.326 147.994 192.16 148.969 192.206C149.456 192.234 149.929 192.38 150.347 192.632C150.764 192.885 151.114 193.236 151.365 193.654C152.03 194.928 151.365 196.475 150.699 197.709C149.48 199.939 148.087 202.069 146.533 204.08C145.283 205.69 143.854 207.283 141.95 208.007C140.046 208.731 138.073 208.65 137.06 207.329"
                fill="#EBEBEB"
              />
              <path
                d="M133.877 231.969C133.81 231.703 133.771 231.431 133.761 231.158L133.547 228.939C133.437 227.848 133.271 226.764 133.05 225.69C132.767 224.383 132.38 223.101 131.892 221.856C131.408 220.611 130.749 219.441 129.936 218.38C129.274 217.517 128.428 216.813 127.46 216.319C126.804 215.994 126.101 215.775 125.376 215.67C125.103 215.66 124.831 215.621 124.566 215.554C124.839 215.524 125.115 215.524 125.388 215.554C126.142 215.615 126.879 215.812 127.564 216.133C128.582 216.616 129.475 217.327 130.174 218.213C131.022 219.287 131.707 220.482 132.205 221.757C132.702 223.021 133.09 224.325 133.362 225.655C133.573 226.742 133.718 227.841 133.796 228.945C133.866 229.889 133.895 230.648 133.906 231.175C133.924 231.44 133.914 231.706 133.877 231.969Z"
                fill="#E0E0E0"
              />
              <path
                d="M133.721 230.26C133.706 230.135 133.706 230.009 133.721 229.884C133.721 229.6 133.721 229.246 133.721 228.806C133.721 227.868 133.721 226.489 133.675 224.844C133.594 221.502 133.304 216.886 132.749 211.812C132.193 206.738 131.447 202.174 130.77 198.902C130.608 198.079 130.451 197.344 130.295 196.695C130.139 196.046 130.029 195.49 129.907 195.033L129.653 193.984C129.617 193.864 129.596 193.739 129.589 193.614C129.648 193.725 129.692 193.844 129.722 193.967L130.035 194.998C130.174 195.444 130.312 196 130.48 196.649C130.648 197.297 130.828 198.027 131.001 198.85C131.923 203.117 132.617 207.43 133.079 211.772C133.657 216.857 133.889 221.485 133.906 224.833C133.906 226.507 133.906 227.862 133.848 228.8L133.785 229.878C133.779 230.007 133.758 230.136 133.721 230.26Z"
                fill="#E0E0E0"
              />
              <path
                d="M133.68 221.334C133.651 220.96 133.651 220.585 133.68 220.211C133.721 219.487 133.819 218.444 134.028 217.158C134.273 215.66 134.604 214.177 135.017 212.716C135.511 210.921 136.132 209.164 136.875 207.457C138.173 204.315 140.032 201.435 142.361 198.96C142.763 198.535 143.192 198.137 143.645 197.767C143.842 197.599 144.016 197.436 144.195 197.303L144.693 196.961C144.993 196.744 145.311 196.55 145.642 196.382C144.551 197.242 143.515 198.168 142.54 199.157C140.299 201.656 138.501 204.52 137.222 207.625C136.485 209.319 135.861 211.061 135.353 212.837C134.928 214.286 134.577 215.755 134.3 217.239C134.068 218.508 133.935 219.556 133.854 220.257C133.823 220.62 133.765 220.98 133.68 221.334Z"
                fill="#E0E0E0"
              />
              <path d="M262.031 213.492C253.617 207.978 251.482 197.425 251.574 187.891C251.574 186.298 251.372 184.624 252.107 183.211C252.842 181.798 254.601 180.801 256.059 181.439C257.269 181.966 257.795 183.327 258.519 184.45C259.453 185.949 260.791 187.154 262.378 187.926C263.414 188.43 264.693 188.719 265.688 188.14C267.06 187.352 267.176 185.453 267.129 183.871C267.041 180.925 266.95 177.977 266.857 175.027C266.724 173.447 266.849 171.857 267.228 170.318C267.691 168.806 268.755 167.381 270.266 166.924C271.776 166.466 273.686 167.289 274.045 168.829C274.12 169.483 274.151 170.141 274.137 170.799C274.136 171.128 274.233 171.45 274.415 171.725C274.598 171.999 274.857 172.213 275.161 172.339C275.804 172.507 276.434 172.044 276.897 171.569C278.512 169.93 279.582 167.85 280.578 165.777C281.573 163.703 282.54 161.589 283.998 159.811C285.456 158.033 287.47 156.602 289.785 156.423C292.099 156.243 294.553 157.581 295.184 159.788C295.814 161.995 294.559 164.3 293.17 166.159C291.107 168.884 288.663 171.297 285.913 173.324C285.374 173.632 284.948 174.105 284.698 174.673C284.374 175.733 285.416 176.759 286.475 177.083C287.678 177.448 288.969 177.291 290.224 177.407C291.48 177.523 292.84 178.033 293.343 179.191C294.038 180.79 292.713 182.522 291.393 183.663C288.744 185.954 285.722 187.774 282.459 189.044C281.301 189.495 280.063 189.901 279.131 190.741C278.199 191.581 277.661 193.017 278.263 194.117C278.865 195.218 280.346 195.542 281.573 195.276C282.8 195.009 283.888 194.332 285.045 193.868C287.227 192.994 290.068 193.075 291.526 194.923C291.971 195.556 292.262 196.286 292.374 197.052C292.487 197.819 292.419 198.601 292.175 199.336C291.677 200.801 290.889 202.15 289.86 203.304C286.459 207.536 282.001 210.793 276.938 212.745C271.874 214.616 267.158 215.201 262.043 213.492" />
              <path
                d="M292.817 157.685C292.772 157.742 292.721 157.794 292.666 157.842L292.203 158.27C291.793 158.647 291.191 159.203 290.427 159.938C288.278 162.008 286.288 164.237 284.472 166.605C283.315 168.099 282.117 169.791 280.867 171.627C279.617 173.463 278.298 175.438 276.984 177.564C275.598 179.777 274.335 182.064 273.2 184.416C272.621 185.638 272.094 186.918 271.562 188.221L269.959 192.183C267.893 197.197 266.112 202.323 264.624 207.538C263.429 211.801 262.643 216.169 262.274 220.581C262.037 223.557 262.06 226.547 262.344 229.519C262.454 230.567 262.558 231.384 262.645 231.934L262.737 232.559C262.744 232.631 262.744 232.703 262.737 232.774C262.714 232.706 262.697 232.636 262.685 232.565L262.569 231.94C262.459 231.395 262.344 230.579 262.216 229.524C261.888 226.547 261.833 223.546 262.054 220.558C262.4 216.128 263.176 211.742 264.369 207.462C265.845 202.232 267.623 197.092 269.693 192.067L271.296 188.105C271.828 186.808 272.349 185.516 272.945 184.288C274.088 181.928 275.361 179.632 276.759 177.413C278.078 175.287 279.409 173.312 280.67 171.476C281.932 169.64 283.141 167.955 284.31 166.466C286.145 164.102 288.166 161.889 290.352 159.846C291.121 159.122 291.741 158.577 292.163 158.218L292.649 157.807C292.701 157.762 292.758 157.721 292.817 157.685Z"
                fill="#263238"
              />
              <path
                d="M273.032 184.271C272.949 184.054 272.891 183.829 272.858 183.599C272.766 183.165 272.65 182.527 272.517 181.746C272.251 180.176 271.938 177.998 271.649 175.594C271.359 173.191 271.018 171.019 270.729 169.461C270.59 168.679 270.463 168.047 270.376 167.613C270.309 167.398 270.267 167.177 270.248 166.953C270.346 167.164 270.42 167.385 270.468 167.613C270.59 168.041 270.746 168.667 270.914 169.443C271.255 171.001 271.608 173.173 271.921 175.583L272.702 181.746L272.951 183.611C273.001 183.827 273.028 184.049 273.032 184.271Z"
                fill="#263238"
              />
              <path
                d="M293.523 179.979C293.255 180.043 292.982 180.082 292.707 180.095C292.18 180.153 291.416 180.234 290.479 180.367C288.598 180.61 286.012 181.045 283.182 181.641C280.352 182.238 277.812 182.898 275.989 183.42C275.075 183.674 274.34 183.9 273.836 184.057C273.577 184.153 273.31 184.224 273.038 184.271C273.282 184.139 273.537 184.031 273.802 183.947C274.299 183.761 275.023 183.501 275.931 183.217C277.742 182.638 280.283 181.954 283.124 181.346C285.546 180.825 287.993 180.427 290.456 180.153C291.399 180.048 292.192 179.996 292.701 179.973C292.975 179.949 293.25 179.951 293.523 179.979Z"
                fill="#263238"
              />
              <path
                d="M264.618 207.08C264.559 207.006 264.51 206.924 264.473 206.837L264.103 206.113C263.785 205.482 263.351 204.549 262.824 203.396C261.777 201.08 260.422 197.859 258.987 194.274C257.552 190.689 256.227 187.451 255.232 185.128L254.045 182.377L253.727 181.63C253.692 181.623 253.66 181.604 253.638 181.575C253.616 181.546 253.606 181.51 253.609 181.474C253.612 181.438 253.629 181.405 253.656 181.38C253.682 181.356 253.717 181.343 253.753 181.343C253.789 181.343 253.824 181.356 253.851 181.38C253.877 181.405 253.894 181.438 253.897 181.474C253.901 181.51 253.89 181.546 253.868 181.575C253.847 181.604 253.815 181.623 253.779 181.63L254.15 182.354C254.468 182.985 254.902 183.912 255.428 185.07C256.482 187.387 257.83 190.607 259.271 194.193C260.712 197.778 262.025 201.016 263.027 203.339L264.219 206.09L264.537 206.837C264.574 206.914 264.601 206.996 264.618 207.08Z"
                fill="#263238"
              />
              <path
                d="M291.764 197.72C291.681 197.771 291.591 197.81 291.497 197.836L290.71 198.12L287.817 199.128C285.363 199.979 281.99 201.195 278.263 202.539C274.536 203.883 271.145 205.07 268.669 205.875C267.43 206.275 266.429 206.594 265.729 206.796L264.925 207.022C264.831 207.056 264.734 207.078 264.635 207.086C264.718 207.035 264.808 206.996 264.901 206.97L265.688 206.686L268.582 205.678C271.035 204.827 274.409 203.611 278.142 202.267C281.874 200.923 285.259 199.736 287.73 198.936C288.969 198.537 289.97 198.218 290.67 198.015L291.474 197.784C291.567 197.75 291.665 197.728 291.764 197.72Z"
                fill="#263238"
              />
              <path d="M70.1589 230.399L71.1022 224.503L60.5587 222.765L59.0309 231.546L59.6791 231.696C62.5725 232.351 74.4122 234.621 76.4607 234.332C78.7696 234.007 70.1589 230.399 70.1589 230.399Z" />
              <path
                d="M76.8145 233.826C76.8301 233.728 72.8954 233.016 68.026 232.236C63.1565 231.457 59.1964 230.904 59.1807 231.002C59.1651 231.1 63.0999 231.811 67.9693 232.591C72.8387 233.371 76.7988 233.923 76.8145 233.826Z"
                fill="#263238"
              />
              <path
                d="M70.1531 231.633C70.0721 231.575 70.1994 231.274 70.4714 231.001C70.7434 230.729 71.0501 230.607 71.108 230.689C71.1658 230.77 70.9807 231.007 70.726 231.268C70.4714 231.528 70.2341 231.696 70.1531 231.633Z"
                fill="#263238"
              />
              <path
                d="M70.7607 232.131C70.6739 232.084 70.7607 231.743 71.0443 231.407C71.3279 231.071 71.623 230.891 71.6866 230.967C71.7503 231.042 71.5767 231.32 71.3279 231.633C71.079 231.945 70.8533 232.183 70.7607 232.131Z"
                fill="#263238"
              />
              <path
                d="M62.3699 231.476C62.3236 231.476 62.1905 231.32 62.0053 231.025C61.7815 230.642 61.5219 230.281 61.2299 229.947C60.9408 229.63 60.5737 229.395 60.1652 229.264C59.8527 229.171 59.6328 229.189 59.6212 229.131C59.6096 229.073 59.8295 228.974 60.1999 229.009C60.6931 229.08 61.1457 229.321 61.4787 229.692C61.7976 230.047 62.0487 230.457 62.2195 230.903C62.3641 231.245 62.4104 231.459 62.3699 231.476Z"
                fill="#263238"
              />
              <path
                d="M70.3615 230.532C70.402 230.625 70.1879 230.799 69.8696 230.862C69.5513 230.926 69.2909 230.862 69.2909 230.747C69.2909 230.631 69.5224 230.567 69.8002 230.509C70.0779 230.451 70.321 230.434 70.3615 230.532Z"
                fill="#263238"
              />
              <path
                d="M70.2862 229.93C70.2862 230.028 69.9853 230.069 69.6265 230.017C69.2677 229.965 68.9958 229.843 69.0478 229.745C69.0999 229.646 69.3661 229.606 69.7075 229.658C70.049 229.71 70.292 229.831 70.2862 229.93Z"
                fill="#263238"
              />
              <path d="M107.478 229.125L107.258 223.159L96.5698 223.483L96.8128 232.409H97.4783C100.43 232.49 112.524 232.409 114.451 231.731C116.621 230.99 107.478 229.125 107.478 229.125Z" />
              <path
                d="M106.669 231.367C111.6 231.169 115.595 230.929 115.591 230.829C115.587 230.73 111.586 230.81 106.654 231.007C101.723 231.205 97.7287 231.445 97.7327 231.544C97.7366 231.644 101.737 231.564 106.669 231.367Z"
                fill="#263238"
              />
              <path
                d="M107.709 230.335C107.622 230.295 107.709 229.976 107.9 229.658C108.091 229.339 108.392 229.159 108.479 229.229C108.566 229.298 108.415 229.565 108.219 229.86C108.022 230.156 107.802 230.382 107.709 230.335Z"
                fill="#263238"
              />
              <path
                d="M108.404 230.706C108.311 230.677 108.346 230.318 108.543 229.942C108.739 229.565 109.006 229.328 109.087 229.362C109.168 229.397 109.046 229.733 108.867 230.086C108.687 230.44 108.502 230.741 108.404 230.706Z"
                fill="#263238"
              />
              <path
                d="M100.042 231.702C100.001 231.731 99.8335 231.581 99.5963 231.326C99.3014 230.995 98.9759 230.692 98.6241 230.422C98.2845 230.171 97.8859 230.011 97.4667 229.959C97.1427 229.93 96.9286 229.959 96.888 229.959C96.8475 229.959 97.0616 229.762 97.4667 229.727C97.9649 229.7 98.4566 229.85 98.8555 230.15C99.2367 230.436 99.5623 230.789 99.8162 231.193C99.9898 231.465 100.082 231.673 100.042 231.702Z"
                fill="#263238"
              />
              <path
                d="M107.698 229.217C107.756 229.299 107.576 229.513 107.275 229.634C106.974 229.756 106.697 229.727 106.697 229.634C106.697 229.542 106.888 229.409 107.154 229.299C107.42 229.189 107.64 229.131 107.698 229.217Z"
                fill="#263238"
              />
              <path
                d="M107.513 228.644C107.513 228.742 107.246 228.841 106.882 228.858C106.517 228.876 106.234 228.812 106.234 228.708C106.234 228.603 106.517 228.511 106.864 228.493C107.212 228.476 107.495 228.546 107.513 228.644Z"
                fill="#263238"
              />
              <path
                d="M121.968 80.61L121.389 78.6697C121.182 77.5471 120.866 76.4472 120.446 75.3856C120.244 74.8509 119.955 74.3536 119.59 73.9144C119.158 73.3505 118.652 72.8478 118.085 72.42C117.506 72.0841 117.651 71.2616 118.235 71.2037C118.82 71.1458 120.371 72.0493 121.563 73.7812C122.755 75.513 122.981 72.2521 123.079 71.0647C123.177 69.8773 123.658 66.2225 124.057 65.9735C124.636 65.6086 125.133 66.1646 125.006 67.352C124.879 68.5393 124.734 72.1131 125.365 72.1536C125.996 72.1942 126.424 66.1414 126.424 66.1414C126.424 66.1414 126.308 64.8266 127.031 64.8498C128.339 64.8903 127.402 71.279 127.298 71.8814C127.222 72.3042 127.876 72.3679 127.957 71.9161C128.038 71.4643 128.258 65.8634 129.589 65.8924C130.613 65.8924 128.866 71.1574 129.456 72.3563C130.046 73.5553 130.376 68.2382 131.348 68.3019C131.713 68.3019 131.979 68.3945 131.348 71.4122C130.718 74.4299 127.604 82.5562 127.604 82.5562L121.922 80.5232"
                fill="#FFBE9D"
              />
              <path
                d="M82.8725 79.0115L92.4901 79.7239C92.6174 78.6118 92.8026 74.2851 92.8026 74.2851C92.8026 74.2851 96.8186 73.9086 97.1137 69.9353C97.4089 65.9619 97.2816 56.7757 97.2816 56.7757C95.0803 55.6634 92.6222 55.1596 90.1613 55.3165C87.7004 55.4733 85.3259 56.285 83.2833 57.6677L82.6642 58.0847L82.8725 79.0115Z"
                fill="#FFBE9D"
              />
              <path
                d="M96.7668 63.5648C96.9956 63.3415 97.0003 62.9748 96.7772 62.7458C96.5541 62.5167 96.1877 62.512 95.9589 62.7353C95.73 62.9586 95.7254 63.3253 95.9485 63.5544C96.1716 63.7835 96.5379 63.7881 96.7668 63.5648Z"
                fill="#263238"
              />
              <path
                d="M96.9691 62.6952C96.8996 62.7589 96.5293 62.4345 95.9853 62.3998C95.4414 62.365 95.0247 62.6199 94.9669 62.5504C94.909 62.4809 95.0074 62.394 95.1925 62.2781C95.4371 62.1236 95.7246 62.0515 96.013 62.0723C96.3015 62.0932 96.5757 62.2059 96.7955 62.394C96.9459 62.5504 96.998 62.6952 96.9691 62.6952Z"
                fill="#263238"
              />
              <path
                d="M90.7755 63.6083C90.9594 63.3467 90.8965 62.9854 90.6351 62.8013C90.3737 62.6173 90.0127 62.6802 89.8289 62.9419C89.645 63.2035 89.7079 63.5648 89.9693 63.7489C90.2307 63.9329 90.5917 63.87 90.7755 63.6083Z"
                fill="#263238"
              />
              <path
                d="M90.9855 62.8226C90.9219 62.8863 90.5457 62.562 90.0018 62.5272C89.4578 62.4925 89.0412 62.7473 88.9833 62.6778C88.9254 62.6083 89.0238 62.5214 89.209 62.3998C89.4535 62.2452 89.741 62.1731 90.0295 62.1939C90.3179 62.2148 90.5921 62.3275 90.8119 62.5156C90.9682 62.6662 91.0203 62.7937 90.9855 62.8226Z"
                fill="#263238"
              />
              <path
                d="M92.9068 67.4794C93.1967 67.3878 93.4997 67.3447 93.8037 67.352C93.9484 67.352 94.0641 67.3231 94.0815 67.242C94.0963 67.0968 94.0702 66.9503 94.0062 66.8191L93.6417 65.6607C93.2908 64.6936 93.035 63.6946 92.8778 62.6778C93.3204 63.6067 93.6712 64.5766 93.9252 65.5738L94.2666 66.7323C94.3426 66.9158 94.3568 67.1191 94.3071 67.3115C94.283 67.3642 94.2469 67.4105 94.2017 67.4467C94.1565 67.4829 94.1034 67.508 94.0467 67.52C93.9661 67.5308 93.8843 67.5308 93.8037 67.52C93.5045 67.5558 93.2015 67.5421 92.9068 67.4794Z"
                fill="#263238"
              />
              <path
                d="M91.0897 67.9775C90.511 67.6011 92.6116 68.9101 93.7285 68.9274C93.525 69.2037 93.234 69.4028 92.903 69.4923C92.5719 69.5817 92.2203 69.5561 91.9056 69.4198C90.7425 68.9043 91.0897 67.9775 91.0897 67.9775Z"
                fill="white"
              />
              <path
                d="M92.8142 74.2271C90.9762 74.1483 89.2033 73.5232 87.7218 72.4316C87.7218 72.4316 88.7692 75.374 92.7621 75.2581L92.8142 74.2271Z"
                fill="#EB996E"
              />
              <path
                d="M82.7567 62.1159L83.2428 64.9714L85.0136 64.9367L84.8978 58.994C84.8978 58.994 91.923 59.5327 92.7274 56.7235C92.7274 56.7235 94.7933 59.8339 97.3568 59.8223C97.3568 59.8223 99.6079 59.5906 97.4668 56.4629C94.1683 51.6439 82.4211 55.513 82.4211 55.513C82.1245 56.3934 81.9797 57.3178 81.9929 58.2468C81.9466 61.3224 82.7567 62.1159 82.7567 62.1159Z"
                fill="#263238"
              />
              <path d="M81.7903 59.2199L97.4436 56.1096C97.4436 56.1096 96.3267 49.8483 87.8838 51.667C79.6666 53.4626 81.7903 59.2199 81.7903 59.2199Z" />
              <path d="M81.7903 59.2199C82.0102 59.1794 100.921 59.6254 102.27 57.0015C102.912 55.7505 97.3221 55.7157 97.3221 55.7157L97.4494 56.1096L81.7903 59.2199Z" />
              <path
                opacity="0.2"
                d="M81.7903 59.2199C82.0102 59.1794 100.921 59.6254 102.27 57.0015C102.912 55.7505 97.3221 55.7157 97.3221 55.7157L97.4494 56.1096L81.7903 59.2199Z"
                fill="black"
              />
              <path
                d="M83.4338 61.612C83.347 61.5773 80.4825 61.2066 80.7545 64.5949C81.0265 67.9833 84.3539 66.5874 84.3481 66.4889C84.3423 66.3905 83.4338 61.612 83.4338 61.612Z"
                fill="#FFBE9D"
              />
              <path
                d="M83.2197 65.3769C83.2197 65.3769 83.1618 65.4232 83.0692 65.4753C82.9345 65.5485 82.7764 65.5651 82.6294 65.5217C82.3995 65.4004 82.2059 65.2201 82.0686 64.9992C81.9313 64.7783 81.8552 64.5249 81.8482 64.2648C81.8167 63.9753 81.8503 63.6824 81.9466 63.4076C81.9699 63.2998 82.0189 63.1992 82.0894 63.1145C82.16 63.0298 82.2499 62.9634 82.3517 62.921C82.4176 62.8981 82.4895 62.8989 82.5549 62.9232C82.6204 62.9475 82.6753 62.9938 82.7104 63.0543C82.7625 63.1469 82.7451 63.2164 82.7683 63.2164C82.7914 63.2164 82.8377 63.1527 82.7972 63.0137C82.7719 62.9308 82.7187 62.8592 82.6468 62.811C82.547 62.748 82.4263 62.7271 82.3111 62.7531C82.1728 62.7911 82.0467 62.8645 81.9453 62.9661C81.8438 63.0676 81.7705 63.1938 81.7325 63.3323C81.612 63.6375 81.5683 63.9677 81.6052 64.2938C81.6121 64.5971 81.7086 64.8917 81.8827 65.1401C82.0568 65.3886 82.3005 65.5798 82.5831 65.6896C82.6762 65.7086 82.7723 65.7065 82.8644 65.6834C82.9565 65.6604 83.0423 65.617 83.1155 65.5564C83.2197 65.4638 83.2312 65.3827 83.2197 65.3769Z"
                fill="#EB996E"
              />
              <path
                d="M92.6572 54.4152L90.7422 54.452L90.7789 56.3689L92.694 56.332L92.6572 54.4152Z"
                fill="#263238"
              />
              <path
                d="M92.6322 53.3753L91.6718 53.3939L91.6904 54.3552L92.6508 54.3366L92.6322 53.3753Z"
                fill="#263238"
              />
              <path
                d="M121.262 78.1194C121.262 78.1194 118.311 96.9553 116.222 97.0537C113.843 97.1638 106.529 86.7844 106.529 86.7844C101.847 79.5559 95.6728 78.3859 93.4218 78.2063V77.3143L82.3574 76.405C82.3574 76.405 82.3574 77.3607 82.3574 78.1889H77.4618C77.4618 78.1889 68.6833 77.7893 67.3697 89.5877L73.6773 94.5805L76.264 104.213C77.051 107.155 75.8531 110.908 74.5279 117.5L73.7352 117.314L73.3995 123.958C73.3532 124.271 73.3069 124.583 73.2664 124.908H73.3532L70.6797 177.957L57.0114 229.339L72.3984 229.918L86.5876 179.788C86.5876 179.788 87.8144 139.707 89.0643 139.683C91.1649 139.643 92.5364 228.847 93.3118 228.644C94.0872 228.441 109.92 228.603 109.92 228.603C109.92 228.603 104.694 124.67 104.746 124.67L103.392 104.977L102.877 99.2895C105.881 103.518 111.558 110.121 117.622 110.005C127.5 109.814 128.889 79.162 128.889 79.162L121.262 78.1194Z"
                fill="#455A64"
              />
              <path
                d="M88.503 139.284C89.7468 137.427 90.3335 135.206 90.1696 132.976C90.1696 131.175 90.1696 129.542 90.1696 128.25C90.1438 127.594 90.1689 126.938 90.2448 126.286C90.3417 126.934 90.3882 127.589 90.3837 128.244C90.4184 129.455 90.4994 131.14 90.4821 132.982C90.4728 133.857 90.3954 134.731 90.2506 135.594C90.128 136.304 89.9201 136.996 89.6314 137.656C89.4342 138.099 89.1856 138.517 88.8907 138.901C88.6477 139.151 88.5435 139.301 88.503 139.284Z"
                fill="#263238"
              />
              <path
                d="M79.4235 130.868C79.4765 131.045 79.5038 131.228 79.5046 131.412C79.5335 131.766 79.5682 132.275 79.5856 132.907C79.6763 134.575 79.3439 136.238 78.6192 137.743C78.1915 138.572 77.5577 139.276 76.779 139.788C76.1341 140.196 75.3943 140.429 74.6321 140.465C74.1218 140.491 73.612 140.404 73.1391 140.211C72.9619 140.149 72.8 140.051 72.6646 139.921C73.2816 140.176 73.9482 140.289 74.6147 140.251C75.331 140.188 76.0206 139.949 76.6227 139.556C77.3549 139.058 77.9515 138.385 78.3588 137.598C79.0567 136.132 79.4054 134.525 79.3773 132.901C79.3566 132.223 79.3721 131.544 79.4235 130.868Z"
                fill="#263238"
              />
              <path
                d="M105.84 140.095C105.84 140.095 105.748 140.263 105.458 140.454C105.043 140.708 104.562 140.833 104.075 140.813C103.353 140.779 102.659 140.52 102.09 140.072C101.409 139.536 100.822 138.89 100.354 138.16C99.4826 136.852 98.9351 135.355 98.7572 133.793C98.7061 133.324 98.6925 132.851 98.7167 132.38C98.7141 132.205 98.7395 132.031 98.7919 131.864C98.844 131.864 98.7919 132.6 98.9655 133.764C99.1997 135.275 99.7584 136.718 100.603 137.992C101.053 138.695 101.612 139.323 102.258 139.851C102.779 140.279 103.416 140.54 104.087 140.599C104.539 140.633 104.992 140.547 105.4 140.35C105.69 140.205 105.823 140.072 105.84 140.095Z"
                fill="#263238"
              />
              <path
                d="M73.7449 227.382C73.7482 227.298 70.3414 227.094 66.1355 226.926C61.9296 226.758 58.5174 226.689 58.5141 226.772C58.5108 226.855 61.9176 227.059 66.1235 227.227C70.3293 227.396 73.7415 227.465 73.7449 227.382Z"
                fill="#263238"
              />
              <path
                d="M109.376 224.19C109.166 224.245 108.95 224.274 108.734 224.277L106.998 224.404C105.516 224.497 103.468 224.613 101.211 224.717C98.9539 224.821 96.8996 224.896 95.424 224.937L93.688 224.972C93.4746 224.998 93.2589 224.998 93.0456 224.972C93.2555 224.917 93.4711 224.888 93.688 224.885L95.424 224.757C96.9054 224.659 98.9481 224.549 101.211 224.445C103.473 224.34 105.516 224.265 106.998 224.225L108.734 224.19C108.947 224.164 109.163 224.164 109.376 224.19Z"
                fill="#263238"
              />
              <path
                d="M80.986 140.512C80.9929 140.587 80.9929 140.663 80.986 140.738C80.986 140.9 80.986 141.126 80.986 141.404L80.9397 143.964C80.8934 146.188 80.7892 149.403 80.6272 153.37C80.4652 157.338 80.2453 162.058 79.9328 167.312C79.7765 169.936 79.6029 172.687 79.3541 175.536C79.2326 176.961 79.1169 178.433 78.868 179.881C78.6192 181.329 78.2893 182.806 77.9942 184.294C75.3555 196.139 72.3868 206.721 70.0779 214.326C68.9206 218.131 67.8789 221.172 67.1498 223.269L66.2644 225.673L66.0272 226.292C66.0028 226.365 65.9718 226.434 65.9346 226.501C65.9493 226.426 65.9706 226.352 65.9982 226.281L66.2065 225.65L67.0398 223.228C67.7343 221.12 68.7759 218.068 69.8754 214.262C72.138 206.652 75.0835 196.064 77.7049 184.23C78.0232 182.748 78.3356 181.288 78.5787 179.834C78.8217 178.38 78.9375 176.938 79.0648 175.513C79.302 172.669 79.4814 169.918 79.6435 167.294C79.9675 162.053 80.2221 157.326 80.4015 153.359L80.8066 143.958L80.9108 141.404L80.9455 140.738C80.9521 140.661 80.9657 140.586 80.986 140.512Z"
                fill="#263238"
              />
              <path
                d="M102.223 224.578C102.217 224.509 102.217 224.439 102.223 224.369L102.177 223.756L102.015 221.392L101.5 212.704C101.089 205.366 100.574 195.224 100.1 184.016C99.6252 172.808 99.2722 162.655 99.0523 155.305C98.9539 151.644 98.8671 148.673 98.815 146.617C98.815 145.603 98.7803 144.809 98.7688 144.248C98.7688 143.987 98.7688 143.784 98.7688 143.634C98.7623 143.564 98.7623 143.495 98.7688 143.425C98.7757 143.495 98.7757 143.564 98.7688 143.634L98.8035 144.248L98.9076 146.611L99.2433 155.299L100.401 184.004C100.875 195.212 101.355 205.354 101.714 212.698L102.131 221.387L102.235 223.75V224.364C102.238 224.435 102.234 224.507 102.223 224.578Z"
                fill="#263238"
              />
              <path
                d="M89.5157 76.99C89.5598 77.5084 89.5598 78.0296 89.5157 78.5481C89.5026 79.8482 89.3767 81.1447 89.1396 82.423L89.0991 82.6141L88.9255 82.5214C88.5551 82.3303 88.179 82.1218 87.797 81.9017C86.1883 80.9634 84.8226 79.9845 83.8157 79.3126C83.2578 78.9694 82.7189 78.5962 82.2012 78.1947C82.81 78.4482 83.3916 78.7626 83.9372 79.1331C84.9789 79.7644 86.3619 80.7143 87.9533 81.641C88.3294 81.8611 88.7056 82.0639 89.0643 82.2608L88.8502 82.3592C89.0912 81.0967 89.2497 79.8197 89.3248 78.5365C89.3425 78.0163 89.4064 77.4988 89.5157 76.99Z"
                fill="#263238"
              />
              <path
                d="M93.4507 78.2816C93.3793 78.7694 93.2729 79.2514 93.1324 79.7239C93.0283 80.1641 92.9125 80.6969 92.7216 81.2819C92.6218 81.5879 92.5039 81.8877 92.3686 82.1797C92.3252 82.283 92.2529 82.3716 92.1603 82.4345C92.0962 82.4764 92.0196 82.4946 91.9436 82.4862C91.8676 82.4777 91.7969 82.4431 91.7436 82.3882C91.6322 82.2321 91.5444 82.0603 91.4832 81.8785C90.945 80.5927 90.4705 79.4227 90.1464 78.5712C89.9511 78.1222 89.8018 77.6545 89.7009 77.1754C89.9693 77.5913 90.1942 78.0339 90.3721 78.4959C90.7483 79.3242 91.2517 80.4826 91.7899 81.7685C91.8402 81.9114 91.9041 82.0491 91.9809 82.1797C91.9809 82.1797 91.9809 82.1797 92.0272 82.1797C92.0701 82.1462 92.104 82.1023 92.1255 82.0523C92.2601 81.773 92.3798 81.4869 92.4843 81.195C92.6811 80.6158 92.82 80.1119 92.9531 79.6717C93.0567 79.1884 93.2241 78.7209 93.4507 78.2816Z"
                fill="#263238"
              />
              <path
                d="M91.3733 117.859C91.3446 117.724 91.3291 117.586 91.327 117.448L91.2517 116.26L91.0203 111.887L90.3721 97.4476L89.7935 83.0022L89.6604 78.6234V77.4302C89.647 77.2915 89.647 77.1519 89.6604 77.0132C89.6891 77.1484 89.7046 77.2862 89.7067 77.4244L89.7819 78.6118L90.0134 82.9848L90.673 97.4476L91.2517 111.893L91.3848 116.272V117.465C91.3928 117.596 91.389 117.728 91.3733 117.859Z"
                fill="#263238"
              />
              <path
                d="M98.4256 88.9878L93.1086 89.0909L93.1299 90.1912L98.4469 90.0881L98.4256 88.9878Z"
                fill="white"
              />
              <path
                d="M104.268 106.724C104.351 106.718 104.136 102.676 103.788 97.6955C103.439 92.7148 103.09 88.6819 103.006 88.6878C102.923 88.6936 103.138 92.7359 103.487 97.7166C103.835 102.697 104.185 106.73 104.268 106.724Z"
                fill="#263238"
              />
              <path
                d="M128.704 83.0141C128.723 82.933 126.967 82.4618 124.784 81.9615C122.6 81.4613 120.814 81.1215 120.796 81.2026C120.777 81.2837 122.533 81.7549 124.716 82.2551C126.9 82.7554 128.686 83.0952 128.704 83.0141Z"
                fill="#263238"
              />
              <path
                d="M122.44 80.5485C122.74 80.5427 122.979 80.2942 122.973 79.9936C122.967 79.693 122.719 79.454 122.418 79.4598C122.118 79.4656 121.879 79.7141 121.885 80.0147C121.891 80.3153 122.139 80.5543 122.44 80.5485Z"
                fill="#263238"
              />
              <path d="M98.3704 86.5323L96.4553 86.5692L96.492 88.486L98.4071 88.4492L98.3704 86.5323Z" />
              <path d="M98.3508 85.4856L97.3904 85.5042L97.409 86.4655L98.3694 86.4469L98.3508 85.4856Z" />
              <path d="M127.043 96.3645L126.262 127.288L77.7686 127.045L77.9248 95.6984L127.043 96.3645Z" />
              <path
                d="M98.7803 111.69C98.9957 103.174 99.1029 96.2693 99.0199 96.2672C98.9368 96.2651 98.6949 103.167 98.4795 111.683C98.2642 120.199 98.1569 127.104 98.24 127.106C98.323 127.108 98.565 120.206 98.7803 111.69Z"
                fill="#263238"
              />
              <path
                d="M77.7686 127.039C77.8205 126.923 77.8845 126.812 77.9595 126.709L78.5382 125.8L80.8008 122.504L88.3584 111.685C91.3154 107.462 93.9773 103.628 95.8812 100.842C96.8302 99.4459 97.6172 98.3106 98.1091 97.5229L98.6878 96.6135C98.7654 96.4951 98.8567 96.3863 98.9597 96.2892C98.9139 96.407 98.8557 96.5196 98.7861 96.6251L98.2364 97.5634C97.7445 98.3685 97.0154 99.527 96.0837 100.94C94.2261 103.761 91.5932 107.613 88.6361 111.841C85.6791 116.069 82.9766 119.863 81.0091 122.608L78.6944 125.852L78.0463 126.727C77.9662 126.841 77.873 126.946 77.7686 127.039Z"
                fill="#263238"
              />
              <path
                d="M97.7647 127.357C97.835 127.313 93.4851 120.272 88.049 111.632C82.6129 102.992 78.149 96.0232 78.0787 96.0675C78.0084 96.1118 82.3583 103.152 87.7944 111.792C93.2305 120.433 97.6944 127.401 97.7647 127.357Z"
                fill="#263238"
              />
              <path
                d="M103.895 122.795L111.679 123.029L111.727 121.43L103.943 121.196L103.895 122.795Z"
                fill="#263238"
              />
              <path
                d="M111.317 120.773C111.317 120.69 109.613 120.619 107.51 120.616C105.407 120.613 103.702 120.678 103.702 120.761C103.702 120.844 105.406 120.914 107.509 120.917C109.612 120.921 111.317 120.856 111.317 120.773Z"
                fill="#263238"
              />
              <path
                d="M117.24 96.1154L116.933 108.053L115.267 106.703L113.079 107.954L113.38 96.017L117.24 96.1154Z"
                fill="#263238"
              />
              <path
                d="M76.97 92.9588C76.97 92.9588 74.6148 109.374 75.7316 110.335C81.8888 115.519 90.4474 118.235 93.5086 119.093C93.9542 118.988 94.4808 118.844 95.0768 118.652C95.5965 118.486 96.0838 118.231 96.5177 117.899C97.0728 117.511 97.5713 117.047 97.9992 116.521C98.0654 116.412 98.163 116.326 98.279 116.274C98.395 116.222 98.524 116.206 98.6491 116.229C98.7743 116.251 98.8897 116.311 98.9802 116.401C99.0708 116.49 99.1322 116.605 99.1565 116.729C99.1565 117.309 98.2769 118.757 96.5756 119.822C96.5756 119.822 100.499 126.142 101.749 126.709C102.999 127.277 106.575 128.893 105.973 129.2C105.372 129.507 101.008 128.238 101.008 128.238L92.1777 125.875C92.1777 125.875 62.966 121.294 64.9103 107.879C66.1024 99.6602 67.4044 92.2405 67.8732 90.3755L76.97 92.9588Z"
                fill="#FFBE9D"
              />
              <path
                d="M67.3755 89.5936L64.7541 107.503C64.1754 113.017 67.8384 117.396 72.8035 119.863C80.361 123.61 91.5816 126.286 91.489 126.269C91.3964 126.252 92.2471 118.299 92.2471 118.299C92.2471 118.299 76.5186 111.476 76.0441 108.551C75.5695 105.626 78.0463 93.37 78.0463 93.37L67.3755 89.5936Z"
                fill="#455A64"
              />
              <path
                d="M91.489 126.263C91.4822 126.215 91.4822 126.166 91.489 126.118V125.707L91.6105 124.114L92.1197 118.259L92.1776 118.363L84.2671 114.528L81.9524 113.411C81.1827 113.005 80.4131 112.553 79.6377 112.061C78.8606 111.56 78.1147 111.013 77.404 110.422C77.0452 110.121 76.6922 109.802 76.3508 109.466C76.1617 109.305 76.0063 109.108 75.8936 108.887C75.8369 108.773 75.7941 108.652 75.7663 108.528C75.7293 108.409 75.7022 108.287 75.6853 108.163C75.4307 106.205 75.9515 104.358 76.2235 102.597L77.103 97.4882L78.706 88.829C78.9408 87.7354 79.0745 86.6225 79.1053 85.5044C79.1151 84.6623 78.9413 83.8281 78.596 83.0601C78.3743 82.5768 78.0597 82.1418 77.6702 81.7801C77.3982 81.5368 77.2304 81.4383 77.2419 81.4268C77.4106 81.5087 77.5681 81.6118 77.7107 81.7337C78.1257 82.0847 78.4628 82.5188 78.7002 83.008C79.0761 83.7847 79.2738 84.6356 79.2789 85.4986C79.2658 86.6291 79.1456 87.7559 78.9201 88.8638L77.4734 97.5519L76.6054 102.666L76.3739 104.01C76.2813 104.456 76.2061 104.908 76.1424 105.36C75.9939 106.275 75.9705 107.206 76.073 108.128C76.0865 108.242 76.1097 108.354 76.1424 108.464C76.1658 108.573 76.2027 108.678 76.2524 108.777C76.348 108.975 76.484 109.15 76.6517 109.292C76.9873 109.623 77.3287 109.935 77.6817 110.231C78.3816 110.815 79.1159 111.357 79.8807 111.853C80.633 112.339 81.3968 112.785 82.1549 113.196L84.4349 114.32L92.3107 118.212L92.3801 118.247V118.299L91.8015 124.143L91.6221 125.73L91.57 126.142C91.5489 126.186 91.5216 126.227 91.489 126.263Z"
                fill="#263238"
              />
              <path
                d="M90.0307 117.175C90.0509 117.585 90.0198 117.996 89.9382 118.398C89.8398 119.145 89.6835 120.176 89.4752 121.294C89.2669 122.411 89.0296 123.448 88.8329 124.19C88.7553 124.591 88.6249 124.981 88.4451 125.348C88.3641 125.348 88.7634 123.506 89.1801 121.247C89.3802 119.874 89.6643 118.514 90.0307 117.175Z"
                fill="#263238"
              />
              <path
                d="M91.489 126.263C91.3947 126.261 91.3012 126.245 91.2112 126.217L90.4358 126.032L87.5887 125.29C85.193 124.641 81.8656 123.796 78.2951 122.481C76.6166 121.87 74.9827 121.142 73.4053 120.303C72.1168 119.614 70.886 118.822 69.7249 117.934C68.9436 117.336 68.2067 116.682 67.5201 115.976C67.2771 115.727 67.0977 115.525 66.982 115.397C66.9154 115.331 66.8589 115.254 66.8141 115.171C67.0907 115.403 67.3553 115.649 67.6069 115.907C68.32 116.574 69.0716 117.199 69.858 117.778C71.0253 118.641 72.2556 119.415 73.5384 120.095C75.1124 120.914 76.738 121.63 78.4051 122.238C81.9524 123.541 85.2682 124.421 87.6639 125.134L90.4821 125.968L91.246 126.211C91.3288 126.218 91.4105 126.236 91.489 126.263Z"
                fill="#263238"
              />
              <path
                d="M91.196 119.614C91.4772 119.609 91.7007 119.376 91.6952 119.095C91.6897 118.813 91.4573 118.589 91.1761 118.595C90.8949 118.6 90.6714 118.833 90.6769 119.114C90.6824 119.396 90.9149 119.62 91.196 119.614Z"
                fill="#263238"
              />
              <path
                d="M228.051 34.9627H164.188V232.409H228.051V34.9627Z"
                fill="#E0E0E0"
              />
              <path
                d="M208.609 129.891C209.363 129.86 209.925 128.648 209.865 127.185C209.805 125.721 209.146 124.559 208.392 124.59C207.639 124.621 207.076 125.832 207.136 127.296C207.196 128.76 207.855 129.922 208.609 129.891Z"
                fill="white"
              />
              <path
                d="M156.735 27.9775V34.4414V36.4745V232.409H166.758V36.4745H225.615V232.409H236.645V36.4745V34.4414V27.9775H156.735Z"
                fill="#263238"
              />
              <path
                d="M166.758 36.4744H161.746V232.409H166.758V36.4744Z"
                fill="#263238"
              />
              <path
                opacity="0.5"
                d="M166.758 36.4744H161.746V232.409H166.758V36.4744Z"
                fill="black"
              />
              <path
                d="M197.225 67.213C197.321 68.9952 197.985 70.6996 199.121 72.0758C200.256 73.452 201.802 74.4273 203.533 74.8586C205.321 75.2988 207.381 75.0902 208.799 76.295C211.229 78.357 209.805 82.6373 211.449 85.4001C212.456 87.103 214.458 87.9371 215.592 89.5531C217.479 92.2406 216.211 96.295 213.642 98.2933C211.073 100.292 207.647 100.674 204.424 100.61C197.335 100.425 190.078 98.1717 184.951 93.1442C182.544 90.781 174.743 81.8033 178.285 68.8522"
                fill="#263238"
              />
              <path
                d="M171.624 86.2574C171.624 86.2574 159.223 97.0538 158.893 95.739C157.157 88.5916 157.643 79.4748 157.921 75.9822C158.118 75.681 158.338 75.3509 158.604 74.986C158.912 74.5655 159.287 74.1994 159.715 73.9029C160.231 73.5135 160.798 73.1959 161.399 72.9588C161.978 72.7966 162.065 72.032 161.567 71.8525C161.069 71.6729 159.484 72.0784 158.014 73.3121C156.544 74.5458 157.14 71.6208 157.343 70.5493C157.545 69.4777 158.002 66.1473 157.719 65.8345C157.308 65.3769 156.723 65.7418 156.561 66.8134C156.399 67.8849 155.641 71.0705 155.08 70.9489C154.519 70.8273 155.612 65.4001 155.612 65.4001C155.612 65.4001 156.035 64.2706 155.392 64.1143C154.235 63.8304 153.5 69.6747 153.448 70.2365C153.408 70.6246 152.794 70.5203 152.869 70.1033C152.945 69.6862 154.079 64.7108 152.904 64.4097C152.001 64.178 152.256 69.2171 151.446 70.1264C150.636 71.0358 151.643 66.2805 150.769 66.072C150.445 66.0025 150.19 65.9967 150.011 68.8058C149.97 71.0676 150.053 73.3299 150.26 75.5826C149.49 83.2918 147.98 106.889 158.633 106.078C171.202 105.116 174.095 95.2003 174.095 95.2003L171.624 86.2574Z"
                fill="#B78876"
              />
              <path
                d="M181.531 82.7474C181.716 81.7569 181.392 77.5692 181.392 77.5692C181.392 77.5692 177.689 77.1001 177.527 73.3179C177.365 69.5356 177.741 60.8127 177.741 60.8127L184.564 57.5344L191.901 63.4539L190.628 82.6894L181.531 82.7474Z"
                fill="#B78876"
              />
              <path
                d="M179.329 67.2104C179.334 66.9417 179.128 66.7203 178.869 66.7157C178.61 66.7112 178.397 66.9253 178.392 67.194C178.387 67.4627 178.593 67.6841 178.852 67.6887C179.111 67.6932 179.325 67.4791 179.329 67.2104Z"
                fill="#263238"
              />
              <path
                d="M177.926 66.6454C177.984 66.7091 178.337 66.4137 178.846 66.3905C179.355 66.3674 179.732 66.628 179.784 66.5585C179.836 66.489 179.749 66.4137 179.587 66.2921C179.366 66.1402 179.102 66.0648 178.835 66.0774C178.567 66.0899 178.311 66.1898 178.106 66.3616C177.943 66.4948 177.897 66.6164 177.926 66.6454Z"
                fill="#263238"
              />
              <path
                d="M185.431 67.4939C185.437 67.2252 185.231 67.0032 184.972 66.9981C184.713 66.9929 184.499 67.2065 184.494 67.4751C184.489 67.7438 184.694 67.9657 184.953 67.9709C185.212 67.9761 185.426 67.7625 185.431 67.4939Z"
                fill="#263238"
              />
              <path
                d="M183.545 66.7496C183.603 66.8133 183.956 66.5179 184.465 66.4948C184.974 66.4716 185.351 66.7323 185.403 66.6627C185.455 66.5932 185.368 66.5179 185.206 66.3963C184.985 66.2445 184.721 66.1691 184.453 66.1816C184.186 66.1942 183.93 66.294 183.724 66.4658C183.568 66.5932 183.516 66.7149 183.545 66.7496Z"
                fill="#263238"
              />
              <path
                d="M181.641 71.1168C181.372 71.0375 181.094 70.9947 180.814 70.9894C180.686 70.9894 180.559 70.9605 180.536 70.862C180.523 70.7181 180.551 70.5734 180.617 70.445L180.982 69.3734C181.324 68.4608 181.596 67.5237 181.798 66.5701C181.388 67.4525 181.046 68.3644 180.773 69.2981L180.42 70.3755C180.341 70.5475 180.321 70.7409 180.362 70.9257C180.38 70.973 180.409 71.0154 180.446 71.0496C180.483 71.0837 180.528 71.1088 180.576 71.1226C180.649 71.1326 180.723 71.1326 180.796 71.1226C181.077 71.1594 181.361 71.1575 181.641 71.1168Z"
                fill="#263238"
              />
              <path
                d="M183.308 71.6382C183.829 71.2965 181.873 72.478 180.843 72.4606C181.019 72.7265 181.282 72.9235 181.586 73.0184C181.891 73.1134 182.219 73.1005 182.515 72.9819C182.655 72.9461 182.787 72.8829 182.903 72.7959C183.019 72.709 183.117 72.5999 183.191 72.475C183.264 72.3501 183.313 72.2119 183.333 72.0683C183.353 71.9247 183.344 71.7785 183.308 71.6382Z"
                fill="white"
              />
              <path
                d="M181.392 77.5692C183.15 77.5222 184.855 76.9626 186.3 75.959C186.3 75.959 185.142 78.7161 181.456 78.496L181.392 77.5692Z"
                fill="#A36957"
              />
              <path
                d="M197.225 67.2709C197.19 67.0045 197.156 66.599 197.115 66.1762C197.075 65.6614 197.007 65.149 196.913 64.6413C195.038 56.7178 188.319 55.1307 186.982 55.1076C185.553 55.1076 183.059 54.233 179.344 58.0615C178.286 59.0785 177.551 60.3859 177.233 61.8194C176.915 63.2529 177.027 64.7485 177.556 66.1183L178.181 65.5391L183.591 64.2069C183.192 62.7068 183.852 60.8649 184.222 60.0077L184.257 59.9729C185.235 65.6549 191.294 66.3442 191.294 66.3442L190.715 81.4036C196.681 80.2046 197.15 70.5145 197.138 67.2362L197.225 67.2709Z"
                fill="#263238"
              />
              <path
                d="M206.594 226.686C206.594 226.686 192.625 179.556 192.358 178.033C192.115 176.672 198.585 143.605 199.528 135.027L179.888 135.212V135.357L170.398 135.617L175.148 229.797L185.565 230.063L185.408 199.104C190.559 214.222 197.075 231.297 197.075 231.297L206.594 226.686Z"
                fill="#B78876"
              />
              <path
                d="M179.159 175.925C179.159 175.925 179.159 176.289 179.101 176.956C179.054 177.888 179.071 178.823 179.153 179.753C179.274 181.131 179.506 182.496 179.847 183.837C180.302 185.47 180.859 187.074 181.514 188.638C182.81 192.021 183.962 195.091 184.783 197.326L185.761 199.967C185.891 200.287 185.995 200.616 186.074 200.952C185.909 200.651 185.768 200.337 185.651 200.014L184.604 197.407C183.73 195.201 182.544 192.142 181.254 188.76L180.316 186.287C180.034 185.511 179.795 184.72 179.599 183.918C179.267 182.56 179.054 181.176 178.962 179.782C178.899 178.842 178.914 177.899 179.008 176.961C179.043 176.631 179.083 176.382 179.118 176.203C179.117 176.108 179.131 176.015 179.159 175.925Z"
                fill="#A36957"
              />
              <path
                d="M185.455 210.358C185.455 210.075 185.634 199.863 185.634 199.863C185.634 199.863 182.214 190.399 182.127 190.683C182.041 190.967 185.455 210.358 185.455 210.358Z"
                fill="#A36957"
              />
              <path d="M197.428 231.146L206.594 226.686L207.659 228.661L204.412 230.498C202.196 231.708 194.661 235.652 192.879 235.977C190.912 236.336 197.428 231.146 197.428 231.146Z" />
              <path
                d="M192.486 235.71C192.445 235.641 195.801 233.973 199.98 232.003C204.158 230.034 207.583 228.476 207.624 228.528C207.664 228.58 204.314 230.266 200.13 232.235C195.946 234.204 192.526 235.78 192.486 235.71Z"
                fill="#263238"
              />
              <path
                d="M205.286 229.721C205.24 229.721 205.222 229.548 205.246 229.27C205.27 228.9 205.37 228.539 205.541 228.21C205.732 227.85 206.04 227.566 206.414 227.405C206.721 227.283 206.93 227.312 206.935 227.341C206.941 227.37 206.762 227.434 206.53 227.578C206.239 227.767 206.001 228.026 205.836 228.331C205.682 228.642 205.562 228.968 205.477 229.304C205.39 229.565 205.327 229.727 205.286 229.721Z"
                fill="#263238"
              />
              <path
                d="M196.93 231.569C196.93 231.656 197.213 231.766 197.578 231.882C197.943 231.998 198.249 232.085 198.301 232.015C198.353 231.946 198.093 231.725 197.723 231.598C197.352 231.471 196.941 231.482 196.93 231.569Z"
                fill="#263238"
              />
              <path
                d="M196.299 232.061C196.299 232.143 196.542 232.299 196.878 232.415C197.213 232.531 197.555 232.554 197.59 232.478C197.624 232.403 197.364 232.247 197.011 232.125C196.658 232.004 196.322 231.98 196.299 232.061Z"
                fill="#263238"
              />
              <path
                d="M195.778 232.519C195.744 232.594 195.935 232.745 196.195 232.878C196.455 233.011 196.693 233.087 196.774 233.017C196.855 232.948 196.669 232.756 196.386 232.612C196.102 232.467 195.819 232.438 195.778 232.519Z"
                fill="#263238"
              />
              <path
                d="M196.97 231.644C197.02 231.321 196.986 230.99 196.872 230.683C196.778 230.326 196.615 229.992 196.392 229.698C196.251 229.514 196.083 229.352 195.894 229.217C195.786 229.137 195.667 229.073 195.541 229.026C195.379 228.963 195.199 228.963 195.038 229.026C194.969 229.061 194.909 229.111 194.861 229.172C194.814 229.233 194.781 229.304 194.764 229.379C194.748 229.454 194.748 229.533 194.765 229.608C194.783 229.683 194.816 229.753 194.864 229.814C194.991 229.999 195.134 230.171 195.292 230.33C195.555 230.6 195.842 230.846 196.149 231.065C196.418 231.295 196.728 231.472 197.063 231.586C197.092 231.552 196.779 231.314 196.305 230.909C196.033 230.674 195.776 230.423 195.535 230.156C195.398 230.008 195.272 229.849 195.159 229.681C195.055 229.53 195.084 229.345 195.194 229.304C195.304 229.264 195.512 229.304 195.686 229.449C195.852 229.563 196.002 229.7 196.131 229.855C196.347 230.118 196.517 230.416 196.635 230.735C196.717 231.048 196.829 231.353 196.97 231.644Z"
                fill="#263238"
              />
              <path
                d="M196.97 231.465C196.714 231.207 196.404 231.009 196.062 230.886C195.692 230.719 195.304 230.598 194.905 230.526C194.664 230.483 194.42 230.464 194.175 230.469C194.037 230.468 193.899 230.483 193.765 230.515C193.68 230.534 193.601 230.571 193.533 230.625C193.492 230.657 193.46 230.698 193.439 230.746C193.417 230.793 193.408 230.845 193.412 230.897C193.453 231.038 193.528 231.166 193.628 231.273C193.729 231.38 193.853 231.461 193.99 231.511C194.212 231.623 194.451 231.699 194.696 231.737C195.099 231.795 195.506 231.808 195.911 231.778C196.612 231.731 196.988 231.494 196.959 231.459C196.608 231.516 196.255 231.551 195.9 231.563C195.512 231.564 195.125 231.531 194.742 231.465C194.541 231.423 194.346 231.353 194.164 231.256C193.984 231.169 193.793 230.996 193.782 230.88C193.77 230.764 193.99 230.752 194.216 230.764C194.437 230.758 194.657 230.771 194.876 230.804C195.25 230.861 195.618 230.954 195.975 231.083C196.297 231.235 196.629 231.363 196.97 231.465Z"
                fill="#263238"
              />
              <path
                d="M197.607 231.048C197.665 231.135 199.794 230.318 202.277 229.096C204.759 227.874 206.675 226.779 206.611 226.686C206.548 226.594 204.522 227.573 202.051 228.789C199.58 230.005 197.549 230.961 197.607 231.048Z"
                fill="#263238"
              />
              <path d="M176.335 229.692L185.553 229.756L185.403 232.432H184.824C182.15 232.432 171.219 232.235 169.46 231.673C167.481 231.059 176.335 229.692 176.335 229.692Z" />
              <path
                d="M185.394 232.176C185.399 232.096 181.786 231.827 177.325 231.576C172.864 231.325 169.245 231.186 169.24 231.266C169.236 231.346 172.848 231.614 177.309 231.865C181.77 232.117 185.39 232.256 185.394 232.176Z"
                fill="#263238"
              />
              <path
                d="M182.475 231.975C182.44 231.975 182.527 231.795 182.712 231.569C182.963 231.26 183.269 231.001 183.615 230.805C183.997 230.59 184.434 230.496 184.87 230.532C185.212 230.573 185.38 230.694 185.362 230.723C185.345 230.752 185.154 230.723 184.865 230.723C184.491 230.748 184.127 230.855 183.8 231.036C183.479 231.226 183.177 231.445 182.897 231.691C182.666 231.888 182.509 231.998 182.475 231.975Z"
                fill="#263238"
              />
              <path
                d="M175.64 229.82C175.577 229.884 175.773 230.121 176.022 230.399C176.271 230.677 176.485 230.886 176.601 230.851C176.717 230.816 176.601 230.509 176.323 230.214C176.045 229.918 175.704 229.756 175.64 229.82Z"
                fill="#263238"
              />
              <path
                d="M174.767 229.947C174.697 230.005 174.836 230.266 175.079 230.527C175.322 230.787 175.582 230.961 175.658 230.915C175.733 230.868 175.6 230.608 175.345 230.335C175.091 230.063 174.859 229.889 174.767 229.947Z"
                fill="#263238"
              />
              <path
                d="M174.061 230.086C173.98 230.133 174.061 230.353 174.211 230.584C174.361 230.816 174.523 230.996 174.616 230.961C174.709 230.926 174.68 230.689 174.523 230.44C174.367 230.19 174.136 230.04 174.061 230.086Z"
                fill="#263238"
              />
              <path
                d="M175.635 229.907C175.868 229.664 176.039 229.368 176.132 229.044C176.266 228.707 176.327 228.346 176.312 227.984C176.3 227.765 176.251 227.549 176.167 227.347C176.122 227.227 176.057 227.115 175.976 227.017C175.863 226.886 175.703 226.805 175.53 226.791C175.45 226.783 175.369 226.793 175.293 226.819C175.217 226.845 175.146 226.886 175.087 226.941C175.028 226.995 174.98 227.062 174.948 227.135C174.916 227.209 174.899 227.289 174.9 227.37C174.899 227.584 174.92 227.797 174.963 228.007C175.029 228.356 175.13 228.698 175.264 229.026C175.363 229.348 175.531 229.645 175.756 229.895C175.714 229.575 175.63 229.261 175.507 228.963C175.41 228.636 175.336 228.303 175.287 227.966C175.257 227.775 175.244 227.581 175.247 227.387C175.247 227.213 175.386 227.069 175.501 227.08C175.617 227.092 175.768 227.248 175.843 227.434C175.923 227.617 175.972 227.813 175.988 228.013C176.016 228.344 175.985 228.677 175.895 228.997C175.777 229.291 175.69 229.596 175.635 229.907Z"
                fill="#263238"
              />
              <path
                d="M175.744 229.756C175.785 229.756 175.658 229.38 175.287 228.858C175.064 228.544 174.8 228.261 174.5 228.019C174.321 227.87 174.127 227.74 173.922 227.63C173.799 227.564 173.669 227.514 173.534 227.48C173.444 227.462 173.352 227.462 173.262 227.48C173.207 227.488 173.154 227.507 173.107 227.537C173.06 227.567 173.02 227.607 172.99 227.654C172.94 227.782 172.925 227.921 172.945 228.057C172.966 228.193 173.021 228.322 173.106 228.43C173.234 228.63 173.397 228.807 173.586 228.951C173.91 229.183 174.259 229.378 174.628 229.53C174.963 229.726 175.354 229.803 175.739 229.75C175.4 229.637 175.07 229.501 174.749 229.345C174.419 229.169 174.105 228.964 173.812 228.731C173.655 228.603 173.52 228.451 173.412 228.279C173.359 228.209 173.322 228.128 173.303 228.042C173.284 227.956 173.284 227.867 173.302 227.781C173.302 227.729 173.563 227.781 173.754 227.885C173.957 227.98 174.151 228.095 174.333 228.227C174.625 228.447 174.892 228.697 175.131 228.974C175.484 229.449 175.692 229.774 175.744 229.756Z"
                fill="#263238"
              />
              <path
                d="M176.549 229.692C178.013 229.924 179.494 230.022 180.976 229.988C182.455 230.054 183.937 230.002 185.408 229.831C183.937 229.699 182.459 229.65 180.982 229.687C178.54 229.658 176.555 229.611 176.549 229.692Z"
                fill="#263238"
              />
              <path d="M204.528 102.516C204.528 102.516 204.91 101.102 203.839 92.4433C203.371 88.6726 200.807 86.3384 198.053 84.9136V84.8615L197.815 84.7919C195.551 83.6978 193.12 82.9892 190.622 82.6952L180.906 82.5388C180.906 82.5388 173.794 81.6526 169.263 86.5933C164.732 91.5339 161.642 94.4415 161.642 94.4415L168.881 103.13L170.82 101.207C170.82 101.207 170.617 105.215 171.943 107.543L175.067 113.057L172.996 122.776C171.711 126.478 167.475 140.014 169.136 153.422C171.19 170.028 171.52 174.853 171.52 174.853L194.98 178.068C194.98 178.068 204.354 142.91 201.143 128.945C198.938 119.347 197.254 119.799 196.71 117.72L197.318 102.776L204.528 102.516Z" />
              <path
                d="M171.826 99.6877C172.364 98.7799 172.742 98.0095 172.671 97.967C172.599 97.9246 172.105 98.6262 171.567 99.5341C171.029 100.442 170.65 101.212 170.722 101.255C170.793 101.297 171.288 100.596 171.826 99.6877Z"
                fill="#263238"
              />
              <path
                d="M193.701 116.139C193.458 116.214 193.208 116.265 192.954 116.289C192.468 116.37 191.768 116.457 190.894 116.55C186.341 117.007 181.749 116.925 177.214 116.307C176.346 116.185 175.646 116.069 175.166 115.977C174.914 115.944 174.665 115.886 174.425 115.803C174.679 115.796 174.933 115.815 175.183 115.861L177.243 116.098C178.979 116.289 181.387 116.486 184.054 116.533C186.722 116.579 189.135 116.469 190.877 116.341L192.943 116.179C193.193 116.14 193.447 116.126 193.701 116.139Z"
                fill="#263238"
              />
              <path
                d="M201.606 142.452C201.588 142.609 201.557 142.764 201.513 142.915L201.195 144.23L200.958 145.18C200.859 145.522 200.732 145.893 200.605 146.286C200.338 147.074 200.078 147.995 199.65 148.957L199.025 150.486C198.805 151.013 198.521 151.54 198.255 152.102C197.74 153.231 197.057 154.361 196.369 155.577C193.237 160.753 189.106 165.252 184.216 168.812C183.059 169.6 182 170.376 180.918 170.978C180.386 171.291 179.882 171.615 179.373 171.882L177.903 172.629C176.977 173.133 176.086 173.463 175.322 173.787C174.934 173.949 174.581 174.106 174.246 174.233L173.32 174.546L172.035 174.975C171.889 175.03 171.738 175.073 171.584 175.102C171.723 175.028 171.868 174.966 172.018 174.917L173.279 174.436L174.194 174.1C174.524 173.967 174.871 173.799 175.253 173.631C176.005 173.283 176.89 172.936 177.805 172.426L179.257 171.662C179.761 171.395 180.258 171.082 180.785 170.752C181.855 170.144 182.914 169.362 184.049 168.574C188.895 165.014 193.005 160.544 196.149 155.415C196.843 154.216 197.526 153.098 198.047 151.98C198.313 151.401 198.625 150.909 198.828 150.381L199.465 148.87C199.899 147.914 200.171 147.005 200.454 146.223C200.587 145.829 200.726 145.47 200.83 145.128L201.091 144.19L201.461 142.892C201.497 142.742 201.545 142.595 201.606 142.452Z"
                fill="#263238"
              />
              <path
                d="M204.158 97.4476C204.043 97.5305 203.921 97.6023 203.793 97.6619C203.55 97.7893 203.185 97.9805 202.711 98.2064C201.376 98.8854 199.946 99.358 198.469 99.608C197.436 99.7567 196.383 99.6976 195.373 99.4343C194.806 99.2849 194.265 99.0485 193.77 98.7334C193.252 98.3917 192.789 97.9716 192.399 97.4882C191.627 96.4316 190.933 95.3205 190.321 94.1635C189.644 93.0051 188.765 91.8467 187.428 91.4065C186.091 90.9663 184.685 91.2964 183.377 91.6555C182.138 92.0927 180.849 92.371 179.541 92.4838C178.76 92.5111 177.982 92.3747 177.257 92.0834C176.532 91.792 175.876 91.3521 175.331 90.7919C174.787 90.2317 174.365 89.5637 174.093 88.8306C173.821 88.0975 173.706 87.3157 173.754 86.5353C173.794 85.8882 173.94 85.2521 174.188 84.6529C174.385 84.1384 174.682 83.668 175.062 83.2686C174.758 83.7178 174.502 84.1973 174.298 84.6993C174.075 85.2911 173.948 85.9149 173.922 86.5469C173.877 87.47 174.072 88.3889 174.489 89.2135C174.906 90.038 175.53 90.7399 176.3 91.2501C177.253 91.9119 178.393 92.249 179.552 92.2116C180.838 92.0913 182.103 91.8111 183.319 91.3775C183.985 91.1727 184.666 91.0236 185.356 90.9315C186.083 90.8344 186.823 90.9017 187.521 91.1285C188.214 91.375 188.838 91.7865 189.338 92.3274C189.809 92.8442 190.223 93.4114 190.57 94.0187C191.171 95.1657 191.852 96.2689 192.607 97.3202C193.322 98.2342 194.312 98.8924 195.431 99.1968C196.409 99.4611 197.43 99.53 198.434 99.3995C199.895 99.169 201.315 98.7322 202.653 98.1021L203.758 97.6098C203.886 97.5431 204.02 97.4888 204.158 97.4476Z"
                fill="white"
              />
              <path
                d="M190.391 117.969C190.59 117.673 190.73 117.34 190.802 116.99C190.86 116.511 190.809 116.025 190.652 115.569C190.496 115.112 190.238 114.697 189.899 114.355C189.433 113.879 188.859 113.521 188.227 113.312C187.495 113.066 186.738 112.901 185.97 112.82C185.154 112.71 184.286 112.646 183.389 112.455C182.449 112.285 181.56 111.903 180.791 111.337C180.406 111.032 180.08 110.659 179.83 110.237C179.597 109.829 179.403 109.401 179.251 108.956C179.012 108.133 178.696 107.334 178.308 106.57C177.943 105.956 177.429 105.443 176.814 105.079C176.2 104.714 175.504 104.51 174.79 104.485C173.814 104.47 172.862 104.779 172.081 105.365C171.802 105.591 171.535 105.833 171.283 106.089C171.322 106.003 171.375 105.923 171.439 105.852C171.611 105.639 171.805 105.445 172.018 105.273C172.803 104.625 173.789 104.271 174.807 104.271C175.564 104.282 176.306 104.485 176.963 104.862C177.62 105.239 178.17 105.777 178.563 106.425C178.968 107.201 179.298 108.013 179.546 108.852C179.797 109.725 180.298 110.504 180.987 111.094C181.717 111.629 182.557 111.993 183.447 112.159C184.315 112.351 185.183 112.426 185.999 112.542C187.631 112.744 189.181 113.214 190.049 114.21C190.4 114.578 190.66 115.024 190.809 115.51C190.957 115.997 190.991 116.512 190.906 117.013C190.848 117.282 190.736 117.537 190.576 117.76C190.449 117.905 190.391 117.975 190.391 117.969Z"
                fill="white"
              />
              <path
                d="M199.47 159.104C198.698 159.027 197.919 159.061 197.156 159.203C196.262 159.387 195.38 159.619 194.511 159.898C193.354 160.283 192.148 160.499 190.929 160.541C189.442 160.571 187.967 160.283 186.601 159.695C185.016 159.03 183.654 157.925 182.677 156.51C181.634 154.905 181.189 152.983 181.421 151.082C181.532 150.082 181.834 149.112 182.313 148.227C182.811 147.311 183.509 146.519 184.355 145.91C185.177 145.302 186.091 144.752 186.502 143.906C186.708 143.486 186.775 143.011 186.693 142.551C186.602 142.085 186.446 141.635 186.23 141.213C185.745 140.376 185.417 139.457 185.264 138.502C185.172 137.567 185.311 136.625 185.669 135.757C186.348 134.151 187.627 132.875 189.234 132.2C190.603 131.618 192.087 131.352 193.574 131.424C194.776 131.486 195.982 131.451 197.179 131.32C198.076 131.19 198.932 130.855 199.679 130.341C200.157 130.018 200.553 129.587 200.836 129.084C201.021 128.737 201.067 128.505 201.085 128.534C201.049 128.736 200.982 128.931 200.888 129.113C200.627 129.638 200.241 130.091 199.765 130.434C199.012 130.977 198.142 131.337 197.225 131.482C196.02 131.633 194.804 131.683 193.591 131.633C192.137 131.577 190.69 131.848 189.355 132.426C187.821 133.085 186.603 134.314 185.958 135.855C185.621 136.682 185.492 137.579 185.582 138.467C185.736 139.391 186.057 140.278 186.531 141.085C186.76 141.533 186.926 142.011 187.023 142.504C187.116 143.028 187.039 143.568 186.803 144.045C186.305 145.006 185.368 145.557 184.564 146.159C183.77 146.737 183.109 147.477 182.622 148.329C182.135 149.182 181.835 150.128 181.74 151.106C181.518 152.929 181.939 154.773 182.932 156.318C183.873 157.697 185.188 158.778 186.722 159.434C188.053 160.018 189.493 160.312 190.946 160.298C192.148 160.273 193.341 160.078 194.488 159.718C195.364 159.451 196.255 159.234 197.156 159.07C197.728 158.969 198.312 158.941 198.892 158.989C199.088 159.01 199.281 159.049 199.47 159.104Z"
                fill="white"
              />
              <path
                d="M191.282 177.575C191.282 177.575 191.282 177.17 191.328 176.446C191.38 175.43 191.2 174.416 190.802 173.48C190.517 172.853 190.019 172.348 189.396 172.056C188.658 171.773 187.868 171.654 187.081 171.708C186.163 171.781 185.239 171.667 184.367 171.372C183.915 171.164 183.519 170.852 183.209 170.463C182.899 170.069 182.631 169.644 182.411 169.194C182.03 168.318 181.532 167.497 180.929 166.756C180.346 166.091 179.648 165.535 178.869 165.117C177.541 164.398 176.018 164.126 174.523 164.34C173.515 164.481 172.544 164.824 171.671 165.348C171.051 165.725 170.756 165.997 170.733 165.974C170.71 165.951 170.797 165.893 170.936 165.765C171.145 165.578 171.368 165.406 171.601 165.25C172.474 164.673 173.461 164.292 174.495 164.132C176.038 163.88 177.621 164.14 179.002 164.873C179.817 165.302 180.546 165.875 181.155 166.565C181.473 166.923 181.756 167.311 182 167.723C182.243 168.152 182.457 168.598 182.683 169.032C183.035 169.905 183.679 170.628 184.506 171.077C185.341 171.358 186.225 171.47 187.104 171.407C187.938 171.357 188.772 171.493 189.546 171.806C190.214 172.133 190.743 172.688 191.039 173.37C191.434 174.34 191.589 175.391 191.49 176.434C191.49 176.799 191.427 177.083 191.392 177.268C191.371 177.376 191.334 177.479 191.282 177.575Z"
                fill="white"
              />
              <path
                d="M177.4 129.681C177.258 130.019 177.1 130.349 176.925 130.671C176.458 131.545 176.166 132.501 176.063 133.486C176.025 134.159 176.171 134.83 176.485 135.426C176.877 136.108 177.318 136.761 177.805 137.378C178.358 138.123 178.759 138.97 178.982 139.871C179.206 140.772 179.248 141.708 179.107 142.626C178.956 143.549 178.59 144.424 178.036 145.178C177.483 145.932 176.759 146.544 175.924 146.964C175.196 147.335 174.392 147.534 173.574 147.543C172.71 147.535 171.861 147.305 171.11 146.876C170.358 146.447 169.729 145.834 169.281 145.093C169.142 144.855 169.03 144.603 168.945 144.34C168.911 144.251 168.889 144.158 168.881 144.062C168.91 144.062 169.009 144.439 169.385 145.03C169.927 145.856 170.703 146.503 171.613 146.889C172.233 147.16 172.904 147.296 173.58 147.289C174.355 147.267 175.115 147.069 175.802 146.709C176.592 146.304 177.276 145.718 177.799 145C178.321 144.282 178.668 143.451 178.812 142.574C178.947 141.692 178.907 140.792 178.695 139.925C178.482 139.058 178.101 138.242 177.573 137.523C177.085 136.891 176.646 136.223 176.26 135.525C175.935 134.887 175.793 134.171 175.849 133.457C175.971 132.448 176.302 131.475 176.821 130.602C176.994 130.289 177.133 130.051 177.232 129.895C177.278 129.816 177.334 129.744 177.4 129.681Z"
                fill="white"
              />
              <path
                d="M194.835 110.242C194.786 110.016 194.763 109.785 194.766 109.553L194.673 107.671L194.372 101.456C194.256 99.0289 194.181 96.8221 194.158 95.2351C194.158 94.4358 194.158 93.7929 194.158 93.3469C194.147 93.1142 194.163 92.881 194.204 92.6519C194.253 92.8783 194.276 93.1095 194.274 93.3411L194.366 95.2235C194.447 96.8801 194.552 99.0463 194.667 101.438C194.783 103.831 194.858 106.072 194.881 107.659C194.881 108.458 194.881 109.101 194.881 109.547C194.893 109.78 194.877 110.013 194.835 110.242Z"
                fill="#263238"
              />
              <path
                d="M194.633 102.904C194.633 102.904 195.605 114.592 194.488 115.374C188.302 119.701 180.073 121.444 177.128 121.954C176.717 121.809 176.242 121.624 175.692 121.375C175.219 121.164 174.784 120.874 174.408 120.518C173.919 120.089 173.491 119.596 173.135 119.052C173.091 118.933 173.01 118.832 172.904 118.764C172.798 118.696 172.672 118.664 172.546 118.674C172.42 118.684 172.301 118.735 172.207 118.818C172.112 118.902 172.048 119.015 172.024 119.139C171.948 119.678 172.683 121.16 174.188 122.342C175.692 123.524 172.73 123.593 171.647 123.634C170.565 123.674 167.232 123.97 166.989 124.323C166.63 124.838 167.111 125.331 168.187 125.261C169.263 125.192 172.515 125.221 172.515 125.806C172.515 126.391 167.018 126.524 167.018 126.524C167.018 126.524 165.861 126.362 165.814 127.034C165.785 128.25 171.601 127.659 172.18 127.613C172.568 127.566 172.591 128.192 172.18 128.227C171.769 128.262 166.682 128.227 166.642 129.478C166.607 130.428 171.445 129.038 172.504 129.64C173.563 130.242 168.725 130.312 168.731 131.221C168.731 131.557 168.777 131.8 171.549 131.36C173.528 130.947 175.484 130.425 177.405 129.796C177.405 129.796 206.171 127.329 205.448 114.343C204.962 105.557 205.118 107.178 204.58 102.562"
                fill="#B78876"
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <rect width="312" height="236" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
      </section>
    </div>
  );
};

export default About;
