import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import AdminHeader from "./AdminHeader";
import { server } from "../../server";
import { useParams, useNavigate } from "react-router-dom";

const AdminUpdateCargo = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [cargoDetails, setCargoDetails] = useState({
    receiptNo: "",
    email: "",
    phone: "",
    shipFrom: "",
    quantitynquality: "",
    amount: "",
    shippingMode: "",
    currency: "",
    balance: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchCargoDetails = async () => {
      try {
        const response = await axios.get(`${server}/cargo/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        });
        setCargoDetails(response.data.data);
      } catch (error) {
        console.error("Error fetching cargo details:", error);
        toast.error("Failed to fetch cargo details. Please try again.");
      }
    };
    fetchCargoDetails();
  }, [id]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const {
      receiptNo,
      email,
      phone,
      shipFrom,
      quantitynquality,
      amount,
      balance,
      shippingMode,
      currency,
    } = cargoDetails;

    if (
      !receiptNo ||
      !email ||
      !phone ||
      !shipFrom ||
      !quantitynquality ||
      !amount ||
      !balance ||
      !shippingMode ||
      !currency
    ) {
      toast.error("Please fill out all required fields.");
      return;
    }

    try {
      setLoading(true);
      await axios.put(
        `${server}/cargo/${id}`,
        {
          receiptNo,
          email,
          phone,
          shipFrom,
          quantitynquality,
          amount,
          balance,
          shippingMode,
          currency,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      );

      toast.success("Cargo updated successfully");
      setLoading(false);
      navigate("/cargo");
    } catch (error) {
      setLoading(false);
      console.error("Error updating cargo:", error);
      toast.error("Failed to update cargo. Please try again.");
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "amount") {
      toast("Please remember to adjust Balance as well");
    }
    setCargoDetails({
      ...cargoDetails,
      [name]: value,
    });
  };

  return (
    <div>
      <AdminHeader />
      <div className="contacts__section">
        <section className="contact" id="contact">
          <div className="container">
            <h2 className="section__title" style={{ marginTop: "10px" }}>
              Update A Cargo
            </h2>
            <p className="section-text" style={{ textAlign: "center" }}>
              Change details on where you want to update/edit
            </p>

            <div className="contact-wrapper">
              <form className="contact-form" onSubmit={handleSubmit}>
                <div className="wrapper-flex">
                  <div className="input-wrapper">
                    <label htmlFor="receiptNo">Receipt Number*</label>
                    <input
                      type="text"
                      name="receiptNo"
                      id="receiptNo"
                      placeholder="Enter Receipt Number"
                      className="input-field"
                      value={cargoDetails.receiptNo}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="input-wrapper">
                    <label htmlFor="email">Customer Email*</label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Enter Customer Email"
                      className="input-field"
                      value={cargoDetails.email}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="wrapper-flex">
                  <div className="input-wrapper">
                    <label htmlFor="phone">Customer Number*</label>
                    <input
                      type="text"
                      name="phone"
                      id="phone"
                      placeholder="Enter Customer Phone Number"
                      className="input-field"
                      value={cargoDetails.phone}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="input-wrapper">
                    <label htmlFor="shipFrom">Ship From*</label>
                    <input
                      type="text"
                      name="shipFrom"
                      id="shipFrom"
                      placeholder="Cargo is Shipped from?"
                      className="input-field"
                      value={cargoDetails.shipFrom}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="wrapper-flex">
                  <div className="input-wrapper">
                    <label htmlFor="currency">Currency*</label>
                    <select
                      name="currency"
                      id="currency"
                      value={cargoDetails.currency}
                      onChange={handleChange}
                      className="input-field"
                      style={{ cursor: "pointer" }}
                    >
                      <option value="">Money Currency</option>
                      <option value="Shs">Shs</option>
                      <option value="Usd">Usd</option>
                      <option value="Gbp">Gbp</option>
                      <option value="Chf">Chf</option>
                    </select>
                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="amount">Cargo Value*</label>
                    <input
                      type="text"
                      name="amount"
                      id="amount"
                      placeholder="Enter Cargo Amount"
                      className="input-field"
                      value={cargoDetails.amount}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="wrapper-flex">
                  <div className="input-wrapper">
                    <label htmlFor="shippingMode">Shipping Mode*</label>
                    <select
                      name="shippingMode"
                      id="shippingMode"
                      value={cargoDetails.shippingMode}
                      onChange={handleChange}
                      className="input-field"
                      style={{ cursor: "pointer" }}
                    >
                      <option value="">Select Shipping Mode</option>
                      <option value="Air">Air</option>
                      <option value="Sea">Sea</option>
                      <option value="Land">Land</option>
                    </select>
                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="balance">Balance*</label>
                    <input
                      type="text"
                      name="balance"
                      id="balance"
                      placeholder="Enter Cargo balance"
                      className="input-field"
                      value={cargoDetails.balance}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="input-wrapper">
                  <label htmlFor="quantitynquality">Description*</label>
                  <textarea
                    name="quantitynquality"
                    id="quantitynquality"
                    placeholder="Enter Description and Quality"
                    className="input-field"
                    value={cargoDetails.quantitynquality}
                    onChange={handleChange}
                  ></textarea>
                </div>

                <button
                  type="submit"
                  className="btn btn-primary"
                  style={{
                    border: "none",
                    fontFamily: "Poppins",
                    cursor: "pointer",
                  }}
                >
                  <span>{loading ? "Updating ..." : "Update Cargo"}</span>
                  <i
                    className="bx bxl-telegram"
                    style={{ fontSize: "20px" }}
                  ></i>
                </button>
              </form>
              <figure class="service-banner">
                <figure class="service-banner">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    data-name="Layer 1"
                    width="454"
                    height="225"
                    style={{ width: "100%" }}
                    viewBox="0 0 860.15102 646"
                  >
                    <path
                      d="M215.70379,772.43837l-.95117-1.75879c12.75342-6.89746,28.62573-15.481,36.916-29.56738,8.17237-13.88623,8.35572-32.74268.47852-49.21094-6.70361-14.01562-19.01782-26.57226-35.61108-36.313-3.273-1.92138-6.70874-3.74218-10.0315-5.50341-8.03149-4.25635-16.33642-8.65821-23.18432-14.90284-9.43165-8.60107-16.56788-23.03466-11.249-35.89111a23.85566,23.85566,0,0,1,20.67749-14.46728l.1128,1.99707a21.8606,21.8606,0,0,0-18.94776,13.248c-4.94263,11.94727,1.83862,25.50489,10.75415,33.63574,6.66065,6.07374,14.85181,10.415,22.77319,14.61329,3.34156,1.771,6.79712,3.60254,10.10767,5.54589,16.93848,9.94336,29.52637,22.79786,36.40259,37.17432,8.1604,17.06055,7.94629,36.63672-.55908,51.08887C244.81951,756.69277,228.67571,765.42372,215.70379,772.43837Z"
                      transform="translate(-169.92449 -127)"
                      fill="#f1f1f1"
                    />
                    <ellipse
                      cx="35.5"
                      cy="459.86478"
                      rx="17.5"
                      ry="8.5"
                      fill="#f1f1f1"
                    />
                    <ellipse
                      cx="49.5"
                      cy="515.86478"
                      rx="17.5"
                      ry="8.5"
                      fill="#f1f1f1"
                    />
                    <ellipse
                      cx="17.5"
                      cy="524.86478"
                      rx="17.5"
                      ry="8.5"
                      fill="#f1f1f1"
                    />
                    <ellipse
                      cx="67.5"
                      cy="571.86478"
                      rx="17.5"
                      ry="8.5"
                      fill="#f1f1f1"
                    />
                    <ellipse
                      cx="102.5"
                      cy="571.86478"
                      rx="17.5"
                      ry="8.5"
                      fill="#f1f1f1"
                    />
                    <path
                      d="M390.39542,564.89893h41.30365a144.4636,144.4636,0,0,0,0-49.24668H390.39542A88.61136,88.61136,0,0,1,390.39542,564.89893Z"
                      transform="translate(-169.92449 -127)"
                      fill="#6c63ff"
                    />
                    <polygon
                      points="93.008 633.403 104.35 633.403 109.746 589.655 93.006 589.656 93.008 633.403"
                      fill="#ffb7b7"
                    />
                    <path
                      d="M260.03957,756.7l22.33642-.00091h.0009a14.23527,14.23527,0,0,1,14.23451,14.23429v.46257l-36.57115.00135Z"
                      transform="translate(-169.92449 -127)"
                      fill="#2f2e41"
                    />
                    <polygon
                      points="229.273 633.03 240.286 630.32 235.073 586.55 218.818 590.551 229.273 633.03"
                      fill="#ffb7b7"
                    />
                    <path
                      d="M395.50345,757.12586l21.6892-5.338.00088-.00022a14.23529,14.23529,0,0,1,17.22335,10.42078l.11053.44916-35.51152,8.73966Z"
                      transform="translate(-169.92449 -127)"
                      fill="#2f2e41"
                    />
                    <path
                      d="M255.95768,749.97175l2.35609-12.05466s4.337-46.54492,35.46228-92.99741l10.13116-50.46137s-3.0088-38.196,26.03151-58.87161l8.95312-6.1675,31.39,1.79924,7.24979,39.69123,14.60772,93.91422s3.76973.35337,5.18338,11.21364a32.66006,32.66006,0,0,1,4.94778,15.13841s7.47574,20.62345,9.47841,35.80785l-.23561,14.85807-15.78576,8.41023-32.90147-93.04772-23.53251-52.01923s-11.32633,36.65079-28.67473,68.049c0,0-23.76156,51.4307-28.50861,61.87956l-5.18339,17.1008Z"
                      transform="translate(-169.92449 -127)"
                      fill="#2f2e41"
                    />
                    <circle
                      cx="190.98689"
                      cy="300.11532"
                      r="22.43647"
                      fill="#ffb7b7"
                    />
                    <circle
                      cx="190.98689"
                      cy="300.11532"
                      r="22.43647"
                      fill="#ffb8b8"
                    />
                    <path
                      d="M330.00441,535.82617l7.4066-18.50076a83.21046,83.21046,0,0,1,.91546-51.88311l0,0,1.417-1.16693c12.345-10.16646,23.18007-8.31559,33.23482,4.12055l0,0a27.79875,27.79875,0,0,0,5.62613,5.30481c4.43448,3.11126,11.4698,20.01463,2.18066,29.92306l-7.43132,43.34936C374.13633,570.907,356.76877,561.89479,330.00441,535.82617Z"
                      transform="translate(-169.92449 -127)"
                      fill="#3f3d56"
                    />
                    <path
                      d="M395.77222,561.38142a8.21447,8.21447,0,0,0-10.59312-6.81484l-12.02453-14.41449-10.54188,5.17081,17.26687,20.11756a8.259,8.259,0,0,0,15.89266-4.059Z"
                      transform="translate(-169.92449 -127)"
                      fill="#ffb7b7"
                    />
                    <path
                      d="M370.30486,561.19232,340.19692,516.065l8.706-32.72127a12.38549,12.38549,0,0,1,22.28654,10.81255l-10.424,21.53195,24.451,35.06533Z"
                      transform="translate(-169.92449 -127)"
                      fill="#ccc"
                    />
                    <circle
                      cx="150.99378"
                      cy="273.6762"
                      r="21.52503"
                      fill="#2f2e41"
                    />
                    <path
                      d="M302.3518,418.50031a21.52619,21.52619,0,0,0,33.24036-11.30244,21.52622,21.52622,0,1,1-41.98955-8.82725A21.51828,21.51828,0,0,0,302.3518,418.50031Z"
                      transform="translate(-169.92449 -127)"
                      fill="#2f2e41"
                    />
                    <path
                      d="M387.29908,418.52423c-3.75771-6.72739-5.19556-9.53761-10.04069-13.62845-4.28456-3.61793-9.55471-4.68042-13.80552-1.25235a25.45685,25.45685,0,1,0,15.36,23.367,25.66776,25.66776,0,0,0-.17344-2.87844C382.33438,423.6131,383.60412,419.04315,387.29908,418.52423Z"
                      transform="translate(-169.92449 -127)"
                      fill="#2f2e41"
                    />
                    <path
                      d="M495.25575,773h-305a1,1,0,0,1,0-2h305a1,1,0,1,1,0,2Z"
                      transform="translate(-169.92449 -127)"
                      fill="#cbcbcb"
                    />
                    <path
                      d="M960.36259,207.64414h-.932V182.11121a14.77776,14.77776,0,0,0-14.77775-14.77778h-54.095A14.77776,14.77776,0,0,0,875.78,182.11116V322.18682a14.77777,14.77777,0,0,0,14.77775,14.77778h54.095a14.77777,14.77777,0,0,0,14.77781-14.77772v-96.368h.93205Z"
                      transform="translate(-169.92449 -127)"
                      fill="#e5e5e5"
                    />
                    <path
                      d="M944.63253,171.1781h-7.06118a5.24313,5.24313,0,0,1-4.85441,7.2233H901.72662a5.2431,5.2431,0,0,1-4.85441-7.22331h-6.59516a11.03587,11.03587,0,0,0-11.03588,11.03585V322.08407A11.03586,11.03586,0,0,0,890.277,333.11994h54.3555a11.03587,11.03587,0,0,0,11.03588-11.03586h0V182.214A11.03586,11.03586,0,0,0,944.63253,171.1781Z"
                      transform="translate(-169.92449 -127)"
                      fill="#fff"
                    />
                    <rect
                      x="742.68978"
                      y="95.4603"
                      width="33.70117"
                      height="2.67015"
                      fill="#e5e5e5"
                    />
                    <rect
                      x="742.68978"
                      y="103.91577"
                      width="33.70117"
                      height="2.67015"
                      fill="#e5e5e5"
                    />
                    <rect
                      x="742.68978"
                      y="112.37124"
                      width="33.70117"
                      height="2.67015"
                      fill="#e5e5e5"
                    />
                    <rect
                      x="719.90269"
                      y="97.1105"
                      width="16.28069"
                      height="16.28069"
                      fill="#e5e5e5"
                    />
                    <circle
                      cx="731.03514"
                      cy="147.75284"
                      r="7.08489"
                      fill="#6c63ff"
                    />
                    <circle
                      cx="749.92816"
                      cy="147.75284"
                      r="5.78936"
                      fill="#6c63ff"
                    />
                    <circle
                      cx="768.82119"
                      cy="147.75284"
                      r="3.5222"
                      fill="#6c63ff"
                    />
                    <path
                      d="M713.45308,329.29253H589.27282a11.11416,11.11416,0,0,1-11.10161-11.10161V138.10161A11.11416,11.11416,0,0,1,589.27282,127H713.45308a11.11416,11.11416,0,0,1,11.10161,11.10161V318.19092A11.11416,11.11416,0,0,1,713.45308,329.29253Z"
                      transform="translate(-169.92449 -127)"
                      fill="#e5e5e5"
                    />
                    <path
                      d="M713.67166,322.20946H589.05424a3.42611,3.42611,0,0,1-3.4222-3.4222v-181.282a3.42611,3.42611,0,0,1,3.4222-3.4222H713.67166a3.42611,3.42611,0,0,1,3.4222,3.4222v181.282A3.42611,3.42611,0,0,1,713.67166,322.20946Z"
                      transform="translate(-169.92449 -127)"
                      fill="#fff"
                    />
                    <circle
                      cx="480.96626"
                      cy="3.39987"
                      r="0.94441"
                      fill="#fff"
                    />
                    <rect
                      x="463.77608"
                      y="62.67961"
                      width="69.31077"
                      height="3.98242"
                      fill="#e5e5e5"
                    />
                    <rect
                      x="463.77608"
                      y="75.29059"
                      width="69.31077"
                      height="3.98242"
                      fill="#e5e5e5"
                    />
                    <rect
                      x="463.77608"
                      y="87.90158"
                      width="69.31077"
                      height="3.98242"
                      fill="#e5e5e5"
                    />
                    <rect
                      x="429.79007"
                      y="65.14081"
                      width="24.28198"
                      height="24.28198"
                      fill="#e5e5e5"
                    />
                    <circle
                      cx="458.13574"
                      cy="129.96471"
                      r="9.64822"
                      fill="#6c63ff"
                    />
                    <circle
                      cx="483.8643"
                      cy="129.96471"
                      r="7.88397"
                      fill="#6c63ff"
                    />
                    <circle
                      cx="509.59285"
                      cy="129.96471"
                      r="4.79654"
                      fill="#6c63ff"
                    />
                    <path
                      d="M949.57714,345.95293H583.18848a10.08456,10.08456,0,0,0-10.08457,10.08455v204.145a10.08459,10.08459,0,0,0,10.08457,10.0846H949.57714a10.08459,10.08459,0,0,0,10.08457-10.0846v-204.145a10.08457,10.08457,0,0,0-10.08457-10.08455Z"
                      transform="translate(-169.92449 -127)"
                      fill="#3f3d56"
                    />
                    <rect
                      x="416.69973"
                      y="236.77515"
                      width="359.51714"
                      height="202.80455"
                      fill="#fff"
                    />
                    <circle
                      cx="596.15102"
                      cy="227.55676"
                      r="3.68735"
                      fill="#fff"
                    />
                    <path
                      d="M969.75613,562.89234H934.66956v-2.528a.50121.50121,0,0,0-.5012-.50123H922.13862a.50122.50122,0,0,0-.50127.5012v2.528h-7.51843v-2.528a.50122.50122,0,0,0-.50123-.50123H901.588a.50122.50122,0,0,0-.50123.50123h0v2.528h-7.51854v-2.528a.50122.50122,0,0,0-.50123-.50123H881.03731a.50122.50122,0,0,0-.50123.50123h0v2.528h-7.51853v-2.528a.50122.50122,0,0,0-.50123-.50123H860.48664a.50121.50121,0,0,0-.50123.5012v2.528h-7.51853v-2.528a.50122.50122,0,0,0-.50123-.50123H839.936a.50122.50122,0,0,0-.50123.50123h0v2.528h-7.51856v-2.528a.50123.50123,0,0,0-.50124-.50123H819.38527a.50122.50122,0,0,0-.50123.50123h0v2.528h-7.51853v-2.528a.50123.50123,0,0,0-.50124-.50123H716.63185a.50122.50122,0,0,0-.50123.50123h0v2.528h-7.51857v-2.528a.50122.50122,0,0,0-.50123-.50123H696.08118a.50122.50122,0,0,0-.50123.50123h0v2.528h-7.51857v-2.528a.50121.50121,0,0,0-.5012-.50123H675.53047a.50122.50122,0,0,0-.50123.50123h0v2.528h-7.51856v-2.528a.50123.50123,0,0,0-.50124-.50123H654.97977a.50122.50122,0,0,0-.50123.50123h0v2.528H646.96v-2.528a.50123.50123,0,0,0-.50124-.50123H634.4291a.50122.50122,0,0,0-.50123.50123h0v2.528H626.4093v-2.528a.50122.50122,0,0,0-.50123-.50123H613.87839a.50123.50123,0,0,0-.50123.5012v2.528h-7.51855v-2.528a.50122.50122,0,0,0-.50121-.50123H593.32772a.50123.50123,0,0,0-.50123.50123h0v2.528H569.7696A12.02967,12.02967,0,0,0,557.73994,574.922v5.43953a12.02967,12.02967,0,0,0,12.02966,12.02964H969.75613a12.02968,12.02968,0,0,0,12.02968-12.02964V574.922A12.02967,12.02967,0,0,0,969.75613,562.89234Z"
                      transform="translate(-169.92449 -127)"
                      fill="#3f3d56"
                    />
                    <rect
                      x="560.33798"
                      y="256.08507"
                      width="116.25931"
                      height="4.31998"
                      fill="#cbcbcb"
                    />
                    <rect
                      x="560.33798"
                      y="269.765"
                      width="116.25931"
                      height="4.31998"
                      fill="#cbcbcb"
                    />
                    <rect
                      x="560.33798"
                      y="283.44494"
                      width="116.25931"
                      height="4.31998"
                      fill="#cbcbcb"
                    />
                    <rect
                      x="523.4712"
                      y="258.75489"
                      width="26.3402"
                      height="26.3402"
                      fill="#cbcbcb"
                    />
                    <circle
                      cx="596.17784"
                      cy="355.70291"
                      r="46.09535"
                      fill="#6c63ff"
                    />
                    <polygon
                      points="588.694 362.58 579.029 362.58 596.133 327.821 613.327 362.548 603.654 362.548 603.654 376.999 588.694 376.999 588.694 362.58"
                      fill="#fff"
                    />
                    <path
                      d="M522.77659,316.01652a43.78818,43.78818,0,0,1,25.50529-14.65517l-4.41511-3.7089,4.7219-.4091,6.04278,5.07922-5.07922,6.04282-4.7219.40911,3.40338-4.04663a40.62894,40.62894,0,0,0,9.379,80.45317l.1184,3.2904a43.92572,43.92572,0,0,1-34.95448-72.45492Z"
                      transform="translate(-169.92449 -127)"
                      fill="#e5e5e5"
                    />
                    <path
                      d="M1019.89613,346.15293A43.92573,43.92573,0,0,1,974.42,388.47144l.1184-3.2904a40.62894,40.62894,0,0,0,9.379-80.45317l3.40337,4.04663-4.7219-.40911-5.07922-6.04282,6.04278-5.07922,4.7219.4091-4.41511,3.7089a43.92126,43.92126,0,0,1,36.027,44.79158Z"
                      transform="translate(-169.92449 -127)"
                      fill="#e5e5e5"
                    />
                    <path
                      d="M1029.07551,593h-526a1,1,0,0,1,0-2h526a1,1,0,0,1,0,2Z"
                      transform="translate(-169.92449 -127)"
                      fill="#cbcbcb"
                    />
                  </svg>
                </figure>
              </figure>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default AdminUpdateCargo;
