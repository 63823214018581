export const list = [
  {
    id: 1,
    heading: "Introduction",
    content: `These Terms and Conditions govern the use of all logistics and shipping services provided by
Bani Global Logistics Limited ("we," "us," or "our"). By using our services, you ("Customer")
agree to comply with and be bound by these terms. If you do not agree with any part of these
terms, please refrain from using our services.`,
  },
  {
    id: 2,
    heading: `Definitions`,
    content: `*Services*: Refers to logistics, shipping, warehousing, freight forwarding, and other related
services provided by Bani Global Logistics Limited.
- *Shipment*: Any goods or cargo handed over to us for transportation and or clearance.
- *Customer*: Any person, company, or entity using our services.
- *Consignee*: The recipient of the shipment as indicated by the Customer. `,
  },
  {
    id: 3,
    heading: ` Shipping and Delivery Terms 
    `,
    content: `3.1 Acceptance of Shipments
We reserve the right to refuse, reject, or cancel any shipment that violates applicable laws, our
policies, or for any other reason we deem reasonable. Shipments containing hazardous materials,
illegal goods, or perishable items may be refused unless specific arrangements have been made.
3.2 Shipping Schedule
All delivery timelines provided are estimates and not guaranteed. Delays caused by customs,
weather, third-party carriers, or other unforeseen circumstances are not within our control.
3.3 Packaging
Customers are responsible for ensuring that shipments are properly packaged, labeled, and meet
all legal and regulatory requirements for transportation.`,
  },
  {
    id: 4,
    heading: `Liability and Insurance 
    `,
    content: `4.1 Liability Limit
Our liability for loss or damage to any shipment shall be limited to the lower of the shipment’s
actual value or $ 100.00, unless the Customer has opted for additional insurance coverage.
4.2 Insurance
We offer optional insurance coverage for shipments at an additional cost. Customers must
declare the full value of the shipment if insurance is desired. Failure to do so will limit our
liability.
4.3 Exclusions from Liability
We are not liable for damages or loss caused by:
- Acts of God (e.g., natural disasters, floods, earthquakes)
- War, civil unrest, or terrorist activities
- Customs hold-ups or governmental regulations
- Improper packaging by the Customer
- Delays beyond our control`,
  },
  {
    id: 5,
    heading: `Duties and Taxes 
    `,
    content: `Customers are responsible for any customs duties, taxes, or other fees that may apply to
international shipments. We are not responsible for delays caused by customs clearance or
payment of duties.`,
  },
  {
    id: 6,
    heading: `Payment Terms 
    `,
    content: `6.1 Rates and Charges
All rates are subject to change without notice. Charges will be based on the weight, dimensions,
and destination of the shipment. Any additional services requested (e.g., expedited delivery,
warehousing) will incur additional fees.
6.2 Payment Due Date
Payment is due upon receipt of the invoice unless otherwise agreed in writing. Failure to pay
within the specified time frame may result in a suspension of services and additional late
payment fees.
6.3 Methods of Payment
We accept payments via [list payment methods]. In case of any discrepancies, the Customer must
notify us within [number] days of the invoice date.
`,
  },
  {
    id: 7,
    heading: `Customs Clearance 
    `,
    content: `For international shipments, we will facilitate customs clearance on behalf of the Customer.
However, the Customer is responsible for providing accurate and complete documentation. We
are not liable for any delays or penalties resulting from inaccurate information.`,
  },
  {
    id: 8,
    heading: `Storage and Warehousing 
    `,
    content: `8.1 Storage Terms
We provide warehousing services subject to space availability. Any goods stored in our facilities
are at the Customer’s risk unless separate insurance is purchased.
8.2 Storage Charges
Storage fees will be charged based on the weight and volume of the goods, and any additional
services (e.g., repacking) will be billed separately.`,
  },
  {
    id: 9,
    heading: `Indemnification
    `,
    content: `The Customer agrees to indemnify and hold us harmless from any claims, damages, or expenses
(including legal fees) arising out of:
- The Customer’s breach of these terms
- Any third-party claims related to the shipment
- Improper or illegal handling of goods by the Customer

`,
  },
  {
    id: 10,
    heading: `Dispute Resolution 
  `,
    content: `In the event of any disputes or claims arising from our services, both parties agree to first attempt
an amicable settlement. If this fails, the dispute shall be resolved through arbitration in
accordance with the laws of Kenya.
`,
  },
  {
    id: 11,
    heading: `Governing Law 
  `,
    content: `These Terms and Conditions shall be governed by and construed in accordance with the laws of
Kenya. Any disputes shall be subject to the exclusive jurisdiction of the courts.

`,
  },
  {
    id: 12,
    heading: `Amendments
  `,
    content: `We reserve the right to amend these Terms and Conditions at any time without prior notice.
Continued use of our services after such changes constitutes acceptance of the revised terms.

`,
  },
  {
    id: 13,
    heading: `Force Majeure 
  `,
    content: `We are not liable for failure to perform any of our obligations due to circumstances beyond our
control, including but not limited to natural disasters, strikes, lockouts, or government
regulations.

`,
  },
  {
    id: 14,
    heading: `Termination
  `,
    content: `We may terminate or suspend our services at any time if the Customer violates these terms,
engages in illegal activities, or fails to make timely payments.

`,
  },
];
