import React from "react";
import Header from "./common/Header";
import Footer from "./common/Footer";
import Services from "./common/Services";

const ServicesPage = () => {
  return (
    <div>
      <Header />
      <div style={{ marginTop: "30px" }}>
        <Services />
      </div>
      <Footer />
    </div>
  );
};

export default ServicesPage;
