import React, { useEffect, useState } from "react";
import Footer from "../common/Footer";
import toast from "react-hot-toast";
import jwtDecode from "jwt-decode";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { server } from "../../server";
import UserHeader from "./UserHeader";
import Modal from "../common/Modal";

const ProfilesPage = () => {
  const [menuVisible, setMenuVisible] = useState(false);
  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };
  const token = localStorage.getItem("userToken");
  const user = jwtDecode(token);
  const navigate = useNavigate();

  const handelLogout = () => {
    localStorage.removeItem("userToken");
    navigate("/");
    toast.success("See you when We see you!");
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const handleDeactivateHandler = () => {
    deactivateHandler();
    closeModal();
  };

  const deactivateHandler = async () => {
    try {
      await axios.post(`${server}/notifications`, {
        title: "Deactivate",
        message: user.email,
      });
      toast.success(
        "Request Received. Account will be deactivated within 24 hrs",
        { duration: 10000 }
      );
      localStorage.removeItem("userToken");
      navigate("/");
      toggleMenu();
    } catch (error) {
      toast.error("Error ocurred, try again later");
    }
  };
  const [loading, setLoading] = useState(false);
  const [receipt, setReceipt] = useState("");

  const [data, setData] = useState({});
  const [shippedOn, setShippedOn] = useState([]);

  const handleStatusChange = async (e) => {
    e.preventDefault();

    if (receipt === "") {
      toast.error("Please Enter Receipt Number");
    } else {
      try {
        setLoading(true);
        const response = await axios.post(
          `${server}/${receipt}/get-status`,
          {}
        );
        setData(response.data.data);
        setShippedOn(response.data.data.sippedOn);
        await axios.post(`${server}/notifications`, {
          title: "Tracking",
          message: receipt,
        });
        setLoading(false);
        const response2 = await axios.post(`${server}/send-email-status`, {
          receipt,
        });

        if (response2.status === 200) {
          toast.success("Package status also sent to your email.", {
            duration: 10000,
          });
        } else {
          toast.error("Failed to request status");
          setLoading(false);
        }
      } catch (error) {
        console.error("Error updating status:", error);
        toast.error("Error: Re-check your Receipt Number", {
          duration: 10000,
        });
        await axios.post(`${server}/notifications`, {
          title: "Error",
          message: receipt,
        });
        setLoading(false);
      }
    }
  };
  const updateProfile = () => {
    navigate(`/update-profile/${user?.id}`);
    toggleMenu();
  };

  const changePassword = () => {
    navigate("/change-password");
  };

  const [userDetails, setUserDetails] = useState({
    fullName: "",
    email: "",
    phone: "",
  });
  const [loading2, setLoading2] = useState(false);
  useEffect(() => {
    const fetchUserDetails = async () => {
      setLoading2(true);
      try {
        const response = await axios.get(`${server}/users/${user.id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        });
        setUserDetails(response.data);
        setLoading2(false);
      } catch (error) {
        console.error("Error fetching cargo details:", error);
        toast.error("Error: Please Login Again.");
        navigate("/login");
        setLoading2(false);
      }
    };
    fetchUserDetails();
  }, [user.id, navigate]);

  return (
    <div>
      <UserHeader />
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        onConfirm={handleDeactivateHandler}
        title={"Do you want to deactivate your account?"}
        message={
          "We are soo Sad to see you leave. NOTE: This Process can NOT be reversed."
        }
      />
      <div style={{ marginTop: "30px" }}>
        <section class="services section container" id="services">
          <h2 className="section__title">My Profile</h2>
          <div className="admin">
            <div className="card profile-card">
              <button
                className="card-menu-btn icon-box"
                aria-label="More"
                onClick={toggleMenu}
              >
                <i class="bx bx-dots-horizontal-rounded icon"></i>
              </button>

              {menuVisible && (
                <ul className="ctx-menu">
                  <li className="ctx-item">
                    <button
                      className="ctx-menu-btn icon-box"
                      onClick={() => {
                        updateProfile();
                      }}
                    >
                      <i class="bx bx-edit icon"></i>

                      <span className="ctx-menu-text">Edit</span>
                    </button>
                  </li>
                  <li className="ctx-item">
                    <button
                      className="ctx-menu-btn icon-box"
                      onClick={() => {
                        changePassword();
                      }}
                    >
                      <i class="bx bx-lock icon"></i>

                      <span className="ctx-menu-text">Change Password</span>
                    </button>
                  </li>

                  <li className="ctx-item">
                    <button
                      className="ctx-menu-btn icon-box"
                      onClick={() => {
                        handelLogout();
                      }}
                    >
                      <i class="bx bx-log-out icon"></i>

                      <span className="ctx-menu-text">Log Out</span>
                    </button>
                  </li>

                  <li className="divider"></li>

                  <li className="ctx-item">
                    <button
                      className="ctx-menu-btn red icon-box"
                      // onClick={() => {
                      //   deactivateHandler();
                      // }}
                      onClick={openModal}
                    >
                      <i class="bx bx-trash icon"></i>

                      <span className="ctx-menu-text">Deactivate</span>
                    </button>
                  </li>
                </ul>
              )}

              <div className="profile-card-wrapper">
                <figure className="card-avatar">
                  <img
                    src="https://res.cloudinary.com/dxxhtz2dw/image/upload/v1711486062/hero1_ymbowl.png"
                    alt="Elizabeth Foster"
                    width="48"
                    height="48"
                  />
                </figure>

                <div>
                  <p className="card-title">
                    {loading2 ? (
                      <div
                        className="card-badge radius-pill loading-badge"
                        style={{ width: "100px" }}
                      ></div>
                    ) : (
                      userDetails?.fullName
                    )}
                  </p>
                  <p className="card-subtitle">
                    Bani Logistics -{" "}
                    {loading2 ? (
                      <div
                        className="card-badge radius-pill loading-badge"
                        style={{ width: "50px" }}
                      ></div>
                    ) : userDetails.role === "User" ? (
                      "Client"
                    ) : (
                      "Admin"
                    )}
                  </p>
                </div>
              </div>

              <ul className="contact-list-2">
                <li>
                  <a
                    href="mailto:xyz@mail.com"
                    className="contact-link-2 icon-box"
                  >
                    <i class="bx bx-envelope icon"></i>
                    <p className="text">
                      {" "}
                      {loading2 ? (
                        <div
                          className="card-badge radius-pill loading-badge"
                          style={{ width: "100px" }}
                        ></div>
                      ) : (
                        userDetails.email
                      )}
                    </p>
                  </a>
                </li>
                <li>
                  <a href="tel:00123456789" className="contact-link-2 icon-box">
                    {userDetails?.phone ? (
                      <>
                        <i class="bx bx-phone icon"></i>
                        <p className="text">{userDetails?.phone}</p>
                      </>
                    ) : (
                      <>
                        <Link
                          to={`/update-profile/${user?.id}`}
                          style={{
                            display: "flex",
                            color: "#8b91a2",
                            fontFamily: "Poppins",
                          }}
                        >
                          <i
                            class="bx bx-plus-circle bx-fade-up"
                            style={{ fontSize: "20px" }}
                          ></i>
                          <p className="text" style={{ marginLeft: "2px" }}>
                            Add Phone Number
                          </p>
                        </Link>
                      </>
                    )}
                  </a>
                </li>
              </ul>

              <div className="divider card-divider"></div>

              <ul className="progress-list">
                <li className="progress-item">
                  <div className="progress-label">
                    <p className="progress-title">Trustworthiness</p>
                    <data className="progress-data" value="99">
                      99%
                    </data>
                  </div>
                  <div className="progress-bar">
                    <div
                      className="progress"
                      style={{
                        "--width": "99%",
                        "--bg": "var(--blue-ryb)",
                      }}
                    ></div>
                  </div>
                </li>
                <li className="progress-item">
                  <div className="progress-label">
                    <p className="progress-title">Overall Rating</p>
                    <data className="progress-data" value="9.9">
                      9.9
                    </data>
                  </div>
                  <div className="progress-bar">
                    <div
                      className="progress"
                      style={{
                        "--width": "99%",
                        "--bg": "var(--coral)",
                      }}
                    ></div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
      <section className="section container" id="contact">
        <h2 class="section__title">Track Your Cargo</h2>
        <div className="tracking" style={{ maxWidth: "100%" }}>
          <div class="input-box">
            <form onSubmit={(e) => handleStatusChange(e)}>
              <i className="bx bx-search"></i>
              <input
                type="text"
                placeholder="Receipt Number?..."
                onChange={(e) => setReceipt(e.target.value)}
              />
              <button
                class="button"
                type="submit"
                style={{
                  fontFamily: "Poppins",
                  backgroundColor: "hsl(210, var(--sat), 60%)",
                }}
              >
                {loading ? "Submiting..." : "Submit"}
              </button>
            </form>
          </div>
        </div>
      </section>
      {data.status && (
        <section
          class="service"
          id="service"
          style={{ padding: "10px", marginBottom: "0" }}
        >
          <div class="container" style={{ display: "block" }}>
            <div>
              <div
                style={{
                  border: "1px solid #fff",
                  padding: "15px",
                  borderRadius: "5px",
                }}
              >
                {" "}
                <div style={{ flexGrow: 1 }}>
                  <div
                    style={{
                      padding: "1.5rem",
                      backgroundColor: "#ffffff",
                      border: "1px solid #e5e7eb",
                      borderRadius: "0.5rem",
                      boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
                    }}
                  >
                    <h3
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "600",
                        color: "#111827",
                      }}
                    >
                      Package Status
                    </h3>
                    <p>
                      <strong>Ordered On:</strong>{" "}
                      {new Date(data?.createdAt).toLocaleString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                        hour12: true,
                      })}
                    </p>
                    <p style={{ marginBottom: "10px" }}>
                      <strong>Status:</strong> {data?.status}
                    </p>

                    <ol className="tracking__ol">
                      <li className="tracking__li">
                        <span>
                          <svg
                            style={{
                              height: "1rem",
                              width: "1rem",
                              color: "#1d4ed8",
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M5 11.917 9.724 16.5 19 7.5"
                            />
                          </svg>
                        </span>
                        <h4 style={{ color: "#1d4ed8" }}>Package Requested</h4>
                        <p style={{ color: "#1d4ed8" }}>
                          Package is requested for importation.
                          <p style={{ color: "#3599fd" }}>
                            {new Date(data?.createdAt).toLocaleString("en-US", {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                              second: "2-digit",
                              hour12: true,
                            })}
                          </p>
                        </p>
                      </li>
                      {data.status !== "Canceled" && (
                        <>
                          <li className="tracking__li">
                            <span>
                              {data.status === "Received" ||
                              data.status === "Pending" ||
                              data.status === "Shipped/In Transit" ||
                              data.status ===
                                "Arrived at destination (customs pending)" ||
                              data.status === "Out for delivery" ||
                              data.status === "Delivered" ? (
                                <svg
                                  style={{
                                    height: "1rem",
                                    width: "1rem",
                                    color: "#1d4ed8",
                                  }}
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M5 11.917 9.724 16.5 19 7.5"
                                  />
                                </svg>
                              ) : (
                                <svg
                                  style={{
                                    height: "1rem",
                                    width: "1rem",
                                    color: "#6b7280",
                                  }}
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M3 21h18m-9-2v-8m0 0L4 11m8-2.5L20 11m-8 2.5V21m8-5.5v-4.5a2 2 0 0 0-1.175-1.813L13 7V4.5a1.5 1.5 0 1 0-3 0V7l-4.825 2.187A2 2 0 0 0 4 11.5v4.5"
                                  />
                                </svg>
                              )}
                            </span>
                            <h4
                              style={{
                                color: `${
                                  data.status === "Received" ||
                                  data.status === "Pending" ||
                                  data.status === "Shipped/In Transit" ||
                                  data.status ===
                                    "Arrived at destination (customs pending)" ||
                                  data.status === "Out for delivery" ||
                                  data.status === "Delivered"
                                    ? "#1d4ed8"
                                    : ""
                                }`,
                              }}
                            >
                              Received
                            </h4>
                            <div>
                              {shippedOn
                                .filter((order) => order.status === "Received")
                                .map((order) => (
                                  <>
                                    <p style={{ color: "#1d4ed8" }}>
                                      {order?.description}
                                    </p>
                                    <p style={{ color: "#3599fd" }}>
                                      {` ${new Date(order?.date).toLocaleString(
                                        "en-US",
                                        {
                                          year: "numeric",
                                          month: "long",
                                          day: "numeric",
                                          hour: "2-digit",
                                          minute: "2-digit",
                                          second: "2-digit",
                                          hour12: true,
                                        }
                                      )}`}
                                    </p>
                                  </>
                                ))}
                            </div>
                          </li>
                          <li className="tracking__li">
                            <span>
                              {data.status === "Pending" ||
                              data.status === "Shipped/In Transit" ||
                              data.status ===
                                "Arrived at destination (customs pending)" ||
                              data.status === "Out for delivery" ||
                              data.status === "Delivered" ? (
                                <svg
                                  style={{
                                    height: "1rem",
                                    width: "1rem",
                                    color: "#1d4ed8",
                                  }}
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M5 11.917 9.724 16.5 19 7.5"
                                  />
                                </svg>
                              ) : (
                                <svg
                                  style={{
                                    height: "1rem",
                                    width: "1rem",
                                    color: "#6b7280",
                                  }}
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M13 7h6l2 4m-8-4v8m0-8V6a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v9h2m8 0H9m4 0h2m4 0h2v-4m0 0h-5m3.5 5.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Zm-10 0a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z"
                                  />
                                </svg>
                              )}
                            </span>
                            <h4
                              style={{
                                color: `${
                                  data.status === "Pending" ||
                                  data.status === "Shipped/In Transit" ||
                                  data.status ===
                                    "Arrived at destination (customs pending)" ||
                                  data.status === "Out for delivery" ||
                                  data.status === "Delivered"
                                    ? "#1d4ed8"
                                    : ""
                                }`,
                              }}
                            >
                              Pending
                            </h4>
                            <div>
                              {shippedOn
                                .filter((order) => order.status === "Pending")
                                .map((order) => (
                                  <>
                                    <p style={{ color: "#1d4ed8" }}>
                                      {order?.description}
                                    </p>
                                    <p style={{ color: "#3599fd" }}>
                                      {` ${new Date(order?.date).toLocaleString(
                                        "en-US",
                                        {
                                          year: "numeric",
                                          month: "long",
                                          day: "numeric",
                                          hour: "2-digit",
                                          minute: "2-digit",
                                          second: "2-digit",
                                          hour12: true,
                                        }
                                      )}`}
                                    </p>
                                  </>
                                ))}
                            </div>
                          </li>
                          <li className="tracking__li">
                            <span>
                              {data.status === "Shipped/In Transit" ||
                              data.status ===
                                "Arrived at destination (customs pending)" ||
                              data.status === "Out for delivery" ||
                              data.status === "Delivered" ? (
                                <svg
                                  style={{
                                    height: "1rem",
                                    width: "1rem",
                                    color: "#1d4ed8",
                                  }}
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M5 11.917 9.724 16.5 19 7.5"
                                  />
                                </svg>
                              ) : (
                                <svg
                                  style={{
                                    height: "1rem",
                                    width: "1rem",
                                    color: "#6b7280",
                                  }}
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M12 8c-1.1 0-2 .9-2 2s.9 2 2 2 2 .9 2 2-.9 2-2 2m0-8V6m0 12v-2m-6-6H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2h-1m-10 0H4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-2"
                                  />
                                </svg>
                              )}
                            </span>
                            <h4
                              style={{
                                color: `${
                                  data.status === "Shipped/In Transit" ||
                                  data.status ===
                                    "Arrived at destination (customs pending)" ||
                                  data.status === "Out for delivery" ||
                                  data.status === "Delivered"
                                    ? "#1d4ed8"
                                    : ""
                                }`,
                              }}
                            >
                              Shipped/In Transit
                            </h4>
                            <div>
                              {shippedOn
                                .filter(
                                  (order) =>
                                    order.status === "Shipped/In Transit"
                                )
                                .map((order) => (
                                  <>
                                    <p style={{ color: "#1d4ed8" }}>
                                      {order?.description}
                                    </p>
                                    <p style={{ color: "#3599fd" }}>
                                      {` ${new Date(order?.date).toLocaleString(
                                        "en-US",
                                        {
                                          year: "numeric",
                                          month: "long",
                                          day: "numeric",
                                          hour: "2-digit",
                                          minute: "2-digit",
                                          second: "2-digit",
                                          hour12: true,
                                        }
                                      )}`}
                                    </p>
                                  </>
                                ))}
                            </div>
                          </li>
                          <li className="tracking__li">
                            <span>
                              {data.status === "Shipped/In Transit" ||
                              data.status ===
                                "Arrived at destination (customs pending)" ||
                              data.status === "Out for delivery" ||
                              data.status === "Delivered" ? (
                                <svg
                                  style={{
                                    height: "1rem",
                                    width: "1rem",
                                    color: "#1d4ed8",
                                  }}
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M5 11.917 9.724 16.5 19 7.5"
                                  />
                                </svg>
                              ) : (
                                <svg
                                  style={{
                                    height: "1rem",
                                    width: "1rem",
                                  }}
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M3 8l9 5 9-5M3 8v10a2 2 0 002 2h14a2 2 0 002-2V8M3 8l9 5 9-5"
                                  />
                                  <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M15 12l2 2 4-4"
                                  />
                                </svg>
                              )}
                            </span>
                            <h4
                              style={{
                                color: `${
                                  data.status ===
                                    "Arrived at destination (customs pending)" ||
                                  data.status === "Out for delivery" ||
                                  data.status === "Delivered"
                                    ? "#1d4ed8"
                                    : ""
                                }`,
                              }}
                            >
                              Arrived at destination (customs pending)
                            </h4>
                            <div>
                              {shippedOn
                                .filter(
                                  (order) =>
                                    order.status ===
                                    "Arrived at destination (customs pending)"
                                )
                                .map((order) => (
                                  <>
                                    <p style={{ color: "#1d4ed8" }}>
                                      {order?.description}
                                    </p>
                                    <p style={{ color: "#3599fd" }}>
                                      {` ${new Date(order?.date).toLocaleString(
                                        "en-US",
                                        {
                                          year: "numeric",
                                          month: "long",
                                          day: "numeric",
                                          hour: "2-digit",
                                          minute: "2-digit",
                                          second: "2-digit",
                                          hour12: true,
                                        }
                                      )}`}
                                    </p>
                                  </>
                                ))}
                            </div>
                          </li>
                          <li className="tracking__li">
                            <span>
                              {data.status === "Out for delivery" ||
                              data.status === "Delivered" ? (
                                <svg
                                  style={{
                                    height: "1rem",
                                    width: "1rem",
                                    color: "#1d4ed8",
                                  }}
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M5 11.917 9.724 16.5 19 7.5"
                                  />
                                </svg>
                              ) : (
                                <svg
                                  style={{
                                    height: "1rem",
                                    width: "1rem",
                                  }}
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <circle
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                  />
                                  <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M9 12l2 2 4-4"
                                  />
                                </svg>
                              )}
                            </span>
                            <h4
                              style={{
                                color: `${
                                  data.status === "Out for delivery" ||
                                  data.status === "Delivered"
                                    ? "#1d4ed8"
                                    : ""
                                }`,
                              }}
                            >
                              Out for delivery
                            </h4>
                            <div>
                              {shippedOn
                                .filter(
                                  (order) => order.status === "Out for delivery"
                                )
                                .map((order) => (
                                  <>
                                    <p style={{ color: "#1d4ed8" }}>
                                      {order?.description}
                                    </p>
                                    <p style={{ color: "#3599fd" }}>
                                      {` ${new Date(order?.date).toLocaleString(
                                        "en-US",
                                        {
                                          year: "numeric",
                                          month: "long",
                                          day: "numeric",
                                          hour: "2-digit",
                                          minute: "2-digit",
                                          second: "2-digit",
                                          hour12: true,
                                        }
                                      )}`}
                                    </p>
                                  </>
                                ))}
                            </div>
                          </li>
                          <li className="tracking__li">
                            <span>
                              {data.status === "Delivered" ? (
                                <svg
                                  style={{
                                    height: "1rem",
                                    width: "1rem",
                                    color: "#1d4ed8",
                                  }}
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M5 11.917 9.724 16.5 19 7.5"
                                  />
                                </svg>
                              ) : (
                                <svg
                                  style={{
                                    height: "1rem",
                                    width: "1rem",
                                  }}
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M4 8v12a2 2 0 002 2h12a2 2 0 002-2V8l-8-4-8 4z"
                                  />
                                  <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M10 12l2 2 4-4"
                                  />
                                </svg>
                              )}
                            </span>
                            <h4
                              style={{
                                color: `${
                                  data.status === "Delivered" ? "#1d4ed8" : ""
                                }`,
                              }}
                            >
                              Delivered
                            </h4>
                            <div>
                              {shippedOn
                                .filter((order) => order.status === "Delivered")
                                .map((order) => (
                                  <>
                                    <p style={{ color: "#1d4ed8" }}>
                                      {order?.description}
                                    </p>
                                    <p style={{ color: "#3599fd" }}>
                                      {` ${new Date(order?.date).toLocaleString(
                                        "en-US",
                                        {
                                          year: "numeric",
                                          month: "long",
                                          day: "numeric",
                                          hour: "2-digit",
                                          minute: "2-digit",
                                          second: "2-digit",
                                          hour12: true,
                                        }
                                      )}`}
                                    </p>
                                  </>
                                ))}
                            </div>
                          </li>
                        </>
                      )}

                      {data.status === "Canceled" && (
                        <li className="tracking__li" style={{ color: "red" }}>
                          <span>
                            <svg
                              style={{
                                height: "1rem",
                                width: "1rem",
                                color: "#f44336", // red color for cancelled
                              }}
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <circle
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="2"
                              />
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M15 9l-6 6M9 9l6 6"
                              />
                            </svg>
                          </span>
                          <h4 style={{ color: "red" }}>Cancelled</h4>
                          <p style={{ fontSize: "0.875rem", color: "red" }}>
                            Order has been cancelled - call us
                          </p>
                        </li>
                      )}
                    </ol>

                    <div
                      style={{
                        gap: "1rem",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <button
                        type="button"
                        style={{
                          width: "100%",
                          padding: "0.625rem 1.25rem",
                          backgroundColor: "#ffffff",
                          border: "1px solid #e5e7eb",
                          borderRadius: "0.5rem",
                          fontSize: "0.875rem",
                          fontWeight: "500",
                          color: "#111827",
                          cursor: "pointer",
                        }}
                      >
                        Call Us - 0795172846
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      <Footer />
    </div>
  );
};

export default ProfilesPage;
