import React, { useEffect, useState } from "react";
import AdminHeader from "./AdminHeader";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { server } from "../../server";
import axios from "axios";
import Modal from "../common/Modal";
import StatusModal from "../common/StatusModal";

const AdminCargosPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [cargos, setCargos] = useState([]);
  // const [selectedStatus, setSelectedStatus] = useState("");
  // const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [menuVisible, setMenuVisible] = useState({});
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    fetchCargos();
  }, []);

  const fetchCargos = async () => {
    const token = localStorage.getItem("userToken");
    if (!token) {
      toast.error("Please Login Again");
      throw new Error("Authentication token not found");
    }
    try {
      setLoading2(true);
      const response = await fetch(`${server}/cargos`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        setLoading2(false);
        toast.error("Error occurred");
        console.log("error", response);
        throw new Error("Network response was not ok.");
      }
      const data = await response.json();
      const initialMenuVisible = {};
      data.data.forEach((cargo) => {
        initialMenuVisible[cargo._id] = false;
      });
      setMenuVisible(initialMenuVisible);
      const sortedCargos = data.data.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      setCargos(sortedCargos);
    } catch (error) {
      setLoading2(false);
      console.error("Error fetching cargos:", error);
    }
    setLoading2(false);
  };

  // Filter cargos based on search query
  const filteredCargos = cargos.filter(
    (cargo) =>
      cargo.receiptNo.toLowerCase().includes(searchQuery.toLowerCase()) ||
      cargo.shipFrom.toLowerCase().includes(searchQuery.toLowerCase()) ||
      cargo.phone.toLowerCase().includes(searchQuery.toLowerCase()) ||
      cargo.quantitynquality
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      cargo.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
      cargo.status.toLowerCase().includes(searchQuery.toLowerCase()) ||
      cargo.amount.toLowerCase().includes(searchQuery.toLowerCase()) ||
      cargo.shippingMode.toLowerCase().includes(searchQuery.toLowerCase()) ||
      cargo.balance.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredCargos.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate total pages
  const totalPages = Math.ceil(filteredCargos.length / itemsPerPage);

  // Generate page numbers
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const toggleMenu = (cargoId) => {
    setMenuVisible({
      ...menuVisible,
      [cargoId]: !menuVisible[cargoId],
    });
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cargoId, setCargoId] = useState("");
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const handleDeleteCargo = () => {
    deleteCargo(cargoId);
    closeModal();
  };

  const deleteCargo = (cargoId) => {
    const deletePromise = async () => {
      try {
        const token = localStorage.getItem("userToken");
        if (!token) {
          throw new Error("Authentication token not found");
        }

        const response = await axios.delete(`${server}/cargo/${cargoId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200) {
          fetchCargos();
          return "Cargo deleted successfully";
        } else {
          throw new Error("Failed to delete cargo");
        }
      } catch (error) {
        console.error("Error deleting cargo:", error);
        throw error;
      }
    };

    toast.promise(deletePromise(), {
      loading: "Deleting...",
      success: <b>Cargo deleted successfully</b>,
      error: <b>Failed to delete cargo</b>,
    });
  };

  const navigate = useNavigate();

  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);

  const handleOpenStatusModal = () => {
    setIsStatusModalOpen(true);
  };

  const handleCloseStatusModal = () => {
    setIsStatusModalOpen(false);
  };

  const [statuscargoId, setStatuscargoId] = useState();
  const [userId, setUserId] = useState();

  const handleStatusConfirm = async (details) => {
    const token = localStorage.getItem("userToken");
    if (!token) {
      toast.error("Please Login Again");
      return;
    }
    if (details.selectedOption === "") {
      toast.error("Please Select the Status");
      return;
    }
    if (details.textAreaValue === "") {
      toast.error("Please Enter the Status description");
      return;
    }
    setIsStatusModalOpen(false);

    toast.promise(
      (async () => {
        const response = await axios.put(
          `${server}/${statuscargoId}/update-status`,
          {
            status: details.selectedOption,
            description: details.textAreaValue,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        await axios.post(`${server}/usernotifications`, {
          userId: userId,
          title: "Status Updates",
          message: `Your status is now: ${details.selectedOption}`,
        });

        if (response.status === 200) {
          fetchCargos();
          return "Status updated successfully";
        } else {
          throw new Error("Failed to update status");
        }
      })(),
      {
        loading: "Updating status...",
        success: (data) => data,
        error: (error) => error.message,
      }
    );
  };

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        onConfirm={handleDeleteCargo}
        title={"Do you want to delete this cargo?"}
        message={
          "NOTE: Once deleted, all Payments associated with this cargo will be deleted as well and can not be recovered."
        }
      />
      <StatusModal
        isOpen={isStatusModalOpen}
        onClose={handleCloseStatusModal}
        onConfirm={handleStatusConfirm}
        title="Change Cargo Status."
        message=""
        options={[
          "Received",
          "Pending",
          "Shipped/In Transit",
          "Arrived at destination (customs pending)",
          "Out for delivery",
          "Delivered",
          "Canceled",
        ]}
      />
      <div className="admin">
        <AdminHeader />
        <main>
          <article className="container article">
            <section className="tasks">
              <div className="section-title-wrapper">
                <h2 className="section-title">Cargos</h2>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    style={{
                      width: "100%",
                      padding: "10px",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      fontSize: "16px",
                      background: "#fffffff",
                    }}
                  />
                </div>

                <div className="pagination">
                  <div>
                    Page {currentPage} of {totalPages}
                  </div>
                </div>
              </div>
              {loading2 ? (
                <ul className="tasks-list loading">
                  {[...Array(5)].map((_, index) => (
                    <li className="tasks-item loading-item" key={index}>
                      <div className="card task-card">
                        <div className="card-input loading-checkbox"></div>
                        <div className="card-badge radius-pill loading-badge"></div>
                        <div className="card-badge radius-pill loading-badge"></div>
                        <div className="card-badge radius-pill loading-badge"></div>
                        <ul className="card-meta-list">
                          <li>
                            <div className="meta-box icon-box loading-meta"></div>
                          </li>
                        </ul>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <>
                  <ul className="tasks-list">
                    {currentItems.map((cargo, index) => (
                      <li className="tasks-item" key={index}>
                        <div className="card task-card">
                          <div className="card-input">
                            <input
                              type="checkbox"
                              name={`task-${index}`}
                              id={`task-${index}`}
                            />
                            <label
                              htmlFor={`task-${index}`}
                              className="task-label"
                            >
                              Receipt No: {cargo.receiptNo}
                            </label>
                          </div>
                          <div
                            className={`card-badge ${
                              cargo.status === "delivered"
                                ? "cyan"
                                : "radius-pill"
                            }`}
                          >
                            {cargo.status}
                          </div>
                          <div className="card-badge">{cargo.shipFrom}</div>
                          <div className="card-badge">{cargo.shippingMode}</div>
                          <ul className="card-meta-list">
                            <li>
                              <Link to={`/add-payments/${cargo._id}`}>
                                <div
                                  className="card-badge orange"
                                  style={{
                                    display: "flex",
                                    textAlign: "center",
                                  }}
                                >
                                  <i
                                    className="bx bx-plus-circle icon"
                                    style={{ fontSize: "25px" }}
                                  ></i>
                                  <p
                                    style={{
                                      fontSize: "13px",
                                      marginLeft: "2px",
                                      marginTop: "2px",
                                    }}
                                  >
                                    Add Payment
                                  </p>
                                </div>
                              </Link>
                            </li>
                            <li>
                              <div
                                className="meta-box icon-box"
                                style={{ cursor: "pointer" }}
                                onClick={() => toggleMenu(cargo._id)}
                              >
                                <i className="bx bx-list-ul icon"></i>
                                <span>{cargo.list}</span>
                              </div>
                            </li>
                            {menuVisible[cargo._id] && (
                              <ul
                                className="ctx-menu"
                                style={{ width: "fit-content", zIndex: "99" }}
                              >
                                <li className="ctx-item">
                                  <button className="ctx-menu-btn icon-box">
                                    <i className="bx bx-money icon"></i>

                                    <span className="ctx-menu-text">
                                      Amount:{" "}
                                      <strong>
                                        {cargo?.currency}{" "}
                                        {Number(cargo.amount).toLocaleString()}
                                      </strong>
                                    </span>
                                  </button>
                                </li>
                                <li className="ctx-item">
                                  <button className="ctx-menu-btn icon-box">
                                    <i className="bx bx-message-square-detail icon"></i>

                                    <span className="ctx-menu-text">
                                      {cargo.email}
                                    </span>
                                  </button>
                                </li>
                                <li className="ctx-item">
                                  <button className="ctx-menu-btn icon-box">
                                    <i className="bx bx-phone icon"></i>
                                    <span className="ctx-menu-text">
                                      {cargo.phone}
                                    </span>
                                  </button>
                                </li>

                                <li className="ctx-item">
                                  <button className="ctx-menu-btn icon-box">
                                    <i className="bx bx-message icon"></i>

                                    <span className="ctx-menu-text">
                                      {cargo.quantitynquality}
                                    </span>
                                  </button>
                                </li>
                                <li className="ctx-item">
                                  <button className="ctx-menu-btn icon-box">
                                    <i className="bx bx-wallet icon"></i>
                                    <span className="ctx-menu-text">
                                      Balance:{" "}
                                      <strong>
                                        {cargo?.currency}{" "}
                                        {Number(cargo.balance).toLocaleString()}
                                      </strong>
                                    </span>
                                  </button>
                                </li>
                                <li className="ctx-item">
                                  <button className="ctx-menu-btn icon-box">
                                    <i className="bx bx-time icon"></i>
                                    <span className="ctx-menu-text">
                                      {new Date(
                                        cargo.createdAt
                                      ).toLocaleString()}
                                    </span>
                                  </button>
                                </li>

                                <li className="divider"></li>

                                <li className="ctx-item">
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <button
                                      onClick={() => {
                                        handleOpenStatusModal();
                                        setStatuscargoId(cargo._id);
                                        setUserId(cargo.userId);
                                      }}
                                      className="ctx-menu-btn"
                                      style={{
                                        border: "1px solid #ccc",
                                        backgroundColor: "#fff",
                                        justifyContent: "center",
                                      }}
                                    >
                                      Update Status from {cargo.status}
                                    </button>
                                  </div>
                                </li>
                                <i
                                  className="bx bx-x-circle icon"
                                  style={{
                                    position: "absolute",
                                    top: "2px",
                                    right: "2px",
                                    padding: "5px",
                                    cursor: "pointer",
                                    fontSize: "24px",
                                  }}
                                  onClick={() => toggleMenu(cargo._id)}
                                ></i>
                              </ul>
                            )}
                            <li>
                              <div
                                className="meta-box icon-box"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  navigate(`/updatecargo/${cargo._id}`)
                                }
                              >
                                <i className="bx bx-edit-alt icon"></i>

                                <data value={cargo.comments}>
                                  {cargo.comments}
                                </data>
                              </div>
                            </li>
                            <li>
                              <div
                                className="card-badge orange"
                                onClick={() => {
                                  openModal();
                                  setCargoId(cargo._id);
                                }}
                              >
                                <i
                                  className="bx bx-trash icon"
                                  style={{ fontSize: "25px" }}
                                ></i>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </li>
                    ))}
                  </ul>
                </>
              )}
              {!loading2 && currentItems?.length === 0 && (
                <li className="tasks-item loading-item">
                  <div className="card task-card">
                    <div className="card-input loading-checkbox"></div>
                    <div className="card-badge radius-pill loading-badge"></div>
                    <div className="card-badge radius-pill loading-badge">
                      No Cargos
                    </div>
                    <div className="card-badge radius-pill loading-badge"></div>
                    <ul className="card-meta-list">
                      <li>
                        <div className="meta-box icon-box loading-meta"></div>
                      </li>
                    </ul>
                  </div>
                </li>
              )}

              <div
                style={{ display: "flex", marginTop: "6px", flexWrap: "wrap" }}
              >
                {pageNumbers.map((number) => (
                  <button
                    key={number}
                    className={`pag-button ${
                      currentPage === number ? "active" : ""
                    }`}
                    onClick={() => paginate(number)}
                  >
                    {number}
                  </button>
                ))}
              </div>
            </section>
          </article>
        </main>
      </div>
    </>
  );
};

export default AdminCargosPage;
