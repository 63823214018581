import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "./logo.png";
import jwtDecode from "jwt-decode";
import WhatsApp from "./WhatsAap";

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);

  const [activeLink, setActiveLink] = useState("");

  const location = useLocation();

  // Toggle menu function
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  // Close menu function when a link is clicked
  const closeMenu = () => {
    setShowMenu(false);
  };

  // Scroll active link function
  const scrollActive = () => {
    const sections = document.querySelectorAll("section[id]");
    sections.forEach((current) => {
      const sectionId = current.getAttribute("id");
      // const navLink = document.querySelector(
      //   `.nav__menu a[href*=${sectionId}]`
      // );

      const scrollY = window.pageYOffset;
      const sectionTop = current.offsetTop - 50;
      const sectionHeight = current.offsetHeight;

      if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
        setActiveLink(sectionId);
      }
    });
  };

  // Scroll header function
  const scrollHeader = () => {
    const nav = document.getElementById("header");
    if (window.scrollY >= 80) nav.classList.add("scroll-header");
    else nav.classList.remove("scroll-header");
  };

  // Scroll up function
  const scrollUp = () => {
    const scrollUp = document.getElementById("scroll-up");
    if (window.scrollY >= 560) scrollUp.classList.add("show-scroll");
    else scrollUp.classList.remove("show-scroll");
  };

  useEffect(() => {
    const handleScroll = () => {
      scrollActive();
      scrollHeader();
      scrollUp();
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    // Update active link based on URL change
    const path = location.pathname;
    const navLinks = document.querySelectorAll(".nav__menu a");

    navLinks.forEach((link) => {
      link.classList.remove("active-link");
      if (link.getAttribute("href") === path) {
        link.classList.add("active-link");
      }
    });
  }, [location.pathname]);

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [data, setData] = useState();

  useEffect(() => {
    const token = localStorage.getItem("userToken");
    if (token && token !== "undefined") {
      const decoded = jwtDecode(token);
      setData(decoded);

      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
        setIsLoggedIn(false);
        // setDecodedToken(null);
        localStorage.removeItem("userToken");
      } else {
        setIsLoggedIn(true);
        // setDecodedToken(decoded);
      }
    } else {
      setIsLoggedIn(false);
      // setDecodedToken(null);
    }
  }, []);
  return (
    <div>
      <header className={`header`} id="header">
        <nav className="nav container">
          <Link to="/" className="nav__logo">
            <img src={logo} alt="bani-logo" style={{ maxWidth: "70%" }} />
          </Link>

          <div
            className={`nav__menu ${showMenu ? "show-menu" : ""}`}
            id="nav-menu"
          >
            <ul className="nav__list">
              <li className="nav__item">
                <Link
                  to="/"
                  className={`nav__link ${
                    activeLink === "" ? "active-link" : ""
                  }`}
                  onClick={closeMenu}
                >
                  Home
                </Link>
              </li>
              <li className="nav__item">
                <Link
                  to="/about"
                  className={`nav__link ${
                    activeLink === "about" ? "active-link" : ""
                  }`}
                  onClick={closeMenu}
                >
                  About us
                </Link>
              </li>
              <li className="nav__item">
                <Link
                  to="/services"
                  className={`nav__link ${
                    activeLink === "services" ? "active-link" : ""
                  }`}
                  onClick={closeMenu}
                >
                  Services
                </Link>
              </li>
              <li className="nav__item">
                <Link
                  to="/tracking"
                  className={`nav__link ${
                    activeLink === "tracking" ? "active-link" : ""
                  }`}
                  onClick={closeMenu}
                >
                  Tracking
                </Link>
              </li>
              <li className="nav__item">
                <Link
                  to="/contacts"
                  className={`nav__link ${
                    activeLink === "contacts" ? "active-link" : ""
                  }`}
                  onClick={closeMenu}
                >
                  Contact us
                </Link>
              </li>
              {isLoggedIn ? (
                <li className="nav__item button__login">
                  <Link
                    to={data.role === "admin" ? "/admin" : "/profile"}
                    className={`nav__link ${
                      activeLink === "login" ? "active-link" : ""
                    }`}
                    onClick={closeMenu}
                  >
                    Account
                  </Link>
                </li>
              ) : (
                <li className="nav__item button__login">
                  <Link
                    to="/login"
                    className={`nav__link ${
                      activeLink === "login" ? "active-link" : ""
                    }`}
                    onClick={closeMenu}
                  >
                    Login
                  </Link>
                </li>
              )}
            </ul>
          </div>

          <div className="nav__toggle" id="nav-toggle" onClick={toggleMenu}>
            {showMenu ? (
              <i className="bx bx-x" style={{ fontSize: "25px" }}></i>
            ) : (
              <i className="bx bx-grid-alt"></i>
            )}{" "}
          </div>
          {isLoggedIn ? (
            <Link
              to={data.role === "admin" ? "/admin" : "/profile"}
              className="button button__header"
            >
              Account
            </Link>
          ) : (
            <Link to="/login" className="button button__header">
              Login
            </Link>
          )}
        </nav>
      </header>
      <WhatsApp />
    </div>
  );
};

export default Header;
