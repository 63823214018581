import React, { useEffect, useState } from "react";
import "./admin.css";
import { server } from "../../server";
import axios from "axios";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

const AdminTasks = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);

  const [cargos, setCargos] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);

  // Initialize menu visibility state for each cargo item
  const [menuVisible, setMenuVisible] = useState({});

  useEffect(() => {
    fetchCargos();
  }, []);

  const fetchCargos = async () => {
    const token = localStorage.getItem("userToken");
    if (!token) {
      toast.error("Please Login Again");
      throw new Error("Authentication token not found");
    }
    try {
      setLoading2(true);
      const response = await fetch(`${server}/cargos`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        setLoading2(false);
        toast.error("Error occurred");
        throw new Error("Network response was not ok.");
      }
      const data = await response.json();
      // Initialize menuVisible state for each cargo item
      const initialMenuVisible = {};
      data.data.forEach((cargo) => {
        initialMenuVisible[cargo._id] = false;
      });
      setMenuVisible(initialMenuVisible);
      const sortedCargos = data.data.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      setCargos(sortedCargos);
    } catch (error) {
      setLoading2(false);
      console.error("Error fetching cargos:", error);
    }
    setLoading2(false);
  };

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = cargos?.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate total pages
  const totalPages = Math.ceil(cargos.length / itemsPerPage);

  // Generate page numbers
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  // Toggle menu for specific cargo item
  const toggleMenu = (cargoId) => {
    setMenuVisible({
      ...menuVisible,
      [cargoId]: !menuVisible[cargoId],
    });
  };

  const handleStatusChange = async (cargoId, userId) => {
    try {
      const token = localStorage.getItem("userToken");
      if (!token) {
        toast.error("Please Login Again");
        throw new Error("Authentication token not found");
      }
      if (selectedStatus === "") {
        toast.error("Please Select the Status");
        throw new Error("Status Not Selected");
      }
      setLoading(true);
      const response = await axios.put(
        `${server}/${cargoId}/update-status`,
        { status: selectedStatus },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      await axios.post(`${server}/usernotifications`, {
        userId: userId,
        title: "Status Updates",
        message: `Your status is now: ${selectedStatus}`,
      });
      if (response.status === 200) {
        toast.success("Status updated successfully");
        fetchCargos();
        setLoading(false);
      } else {
        toast.error("Failed to update status");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error updating status:", error);
      toast.error("Error updating status");
      setLoading(false);
    }
  };
  return (
    <div className="admin">
      <main>
        <article className="container article" style={{ marginTop: "0" }}>
          <section className="tasks">
            <div className="section-title-wrapper">
              <h2 className="section-title">Latest Cargos</h2>
              <button className="btn btn-link icon-box">
                <Link to="/cargo">
                  <span>View All</span>
                </Link>
                <i class="bx bx-chevron-right icon"></i>
              </button>
            </div>

            {loading2 ? (
              <>
                <ul className="tasks-list loading">
                  {[...Array(5)].map((_, index) => (
                    <li className="tasks-item loading-item" key={index}>
                      <div className="card task-card">
                        <div className="card-input loading-checkbox"></div>
                        <div className="card-badge radius-pill loading-badge"></div>
                        <div className="card-badge radius-pill loading-badge"></div>
                        <div className="card-badge radius-pill loading-badge"></div>
                        <ul className="card-meta-list">
                          <li>
                            <div className="meta-box icon-box loading-meta"></div>
                          </li>
                        </ul>
                      </div>
                    </li>
                  ))}
                </ul>
              </>
            ) : (
              <>
                <ul className="tasks-list">
                  {/* Render current page items */}
                  {currentItems.map((cargo, index) => (
                    <li className="tasks-item" key={index}>
                      <div className="card task-card">
                        <div className="card-input">
                          <input
                            type="checkbox"
                            name={`task-${index}`}
                            id={`task-${index}`}
                          />
                          <label
                            htmlFor={`task-${index}`}
                            className="task-label"
                          >
                            Receipt No: {cargo.receiptNo}
                          </label>
                        </div>
                        <div
                          className={`card-badge ${
                            cargo.status === "delivered"
                              ? "cyan"
                              : "radius-pill"
                          }`}
                        >
                          {cargo.status}
                        </div>
                        <div className="card-badge radius-pill">
                          {cargo.shipFrom}
                        </div>
                        <div className="card-badge radius-pill">
                          {cargo.phone}
                        </div>

                        <ul className="card-meta-list">
                          <li>
                            <div
                              className="meta-box icon-box"
                              style={{ cursor: "pointer" }}
                              onClick={() => toggleMenu(cargo._id)}
                            >
                              <i class="bx bx-list-ul icon"></i>

                              <span>{cargo.list}</span>
                            </div>
                          </li>
                          {menuVisible[cargo._id] && (
                            <ul
                              className="ctx-menu"
                              style={{ width: "fit-content", zIndex: "99" }}
                            >
                              <li className="ctx-item">
                                <button className="ctx-menu-btn icon-box">
                                  <i class="bx bx-money icon"></i>

                                  <span className="ctx-menu-text">
                                    {Number(cargo.amount).toLocaleString()}
                                  </span>
                                </button>
                              </li>
                              <li className="ctx-item">
                                <button className="ctx-menu-btn icon-box">
                                  <i class="bx bx-message-square-detail icon"></i>

                                  <span className="ctx-menu-text">
                                    {cargo.email}
                                  </span>
                                </button>
                              </li>

                              <li className="ctx-item">
                                <button className="ctx-menu-btn icon-box">
                                  <i class="bx bx-message icon"></i>

                                  <span className="ctx-menu-text">
                                    {cargo.quantitynquality}
                                  </span>
                                </button>
                              </li>
                              <li className="ctx-item">
                                <button className="ctx-menu-btn icon-box">
                                  <i class="bx bx-time icon"></i>
                                  <span className="ctx-menu-text">
                                    {new Date(cargo.createdAt).toLocaleString()}
                                  </span>
                                </button>
                              </li>

                              <li className="divider"></li>

                              <li className="ctx-item">
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <button className="ctx-menu-btn icon-box">
                                    <i class="bx bx-refresh icon"></i>
                                    <select
                                      className="status-select"
                                      style={{
                                        marginLeft: "10px",
                                        padding: "8px",
                                        border: "1px solid #ccc",
                                        borderRadius: "4px",
                                        fontSize: "14px",
                                        cursor: "pointer",
                                      }}
                                      value={selectedStatus}
                                      onChange={(e) =>
                                        setSelectedStatus(e.target.value)
                                      }
                                    >
                                      <option value={`${cargo.status}`}>
                                        {cargo.status}
                                      </option>
                                      <option value="pending">Pending</option>
                                      <option value="shipped">Shipped</option>
                                      <option value="delivered">
                                        Delivered
                                      </option>
                                      <option value="paid">Paid</option>
                                    </select>
                                  </button>
                                  <button
                                    className="ctx-menu-btn"
                                    onClick={() =>
                                      handleStatusChange(
                                        cargo._id,
                                        cargo.userId
                                      )
                                    }
                                    style={{
                                      border: "1px solid #ccc",
                                      backgroundColor: "#fff",
                                    }}
                                  >
                                    <span className="ctx-menu-text">
                                      {loading
                                        ? "Updating..."
                                        : "Update Status"}
                                    </span>
                                  </button>
                                </div>
                              </li>
                              <i
                                class="bx bx-x-circle icon"
                                onClick={() => toggleMenu(cargo._id)}
                                style={{
                                  position: "absolute",
                                  top: "2px",
                                  right: "2px",
                                  padding: "5px",
                                  cursor: "pointer",
                                  fontSize: "24px",
                                }}
                              ></i>
                            </ul>
                          )}
                        </ul>
                      </div>
                    </li>
                  ))}
                </ul>
              </>
            )}
            {/* Render page numbers */}
            <div
              style={{ display: "flex", marginTop: "6px", flexWrap: "wrap" }}
            >
              {pageNumbers.map((number) => (
                <button
                  key={number}
                  className={`pag-button ${
                    currentPage === number ? "active" : ""
                  }`}
                  onClick={() => paginate(number)}
                >
                  {number}
                </button>
              ))}
            </div>
          </section>
        </article>
      </main>
    </div>
  );
};

export default AdminTasks;
