import React, { useState } from "react";
import img from "./img-pass.svg";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import axios from "axios";
import Header from "./common/Header";
import { server } from "../server";
import Footer from "./common/Footer";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const resetSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      toast.error("Email is required.");
      return;
    }
    try {
      setLoading(true);
      const response = await axios.post(`${server}/reset-password`, { email });
      localStorage.setItem("userToken", response.data.token);
      toast.success("If we found account, Email was sent to your account.", {
        duration: 30000,
      });
      console.log("data", response.data);
      setLoading(false);
    } catch (error) {
      setTimeout(() => {
        toast.success(
          "If we found the account, an email was sent to your account.",
          {
            duration: 30000,
          }
        );
        setLoading(false);
      }, 3000);
    }
  };

  const login = () => {
    navigate("/login");
  };

  return (
    <div>
      <Header />
      <div className="login">
        <div className="login__content">
          <div className="login__img">
            <img
              src={img}
              style={{ maxWidth: "100%", height: "auto", display: "block" }}
              alt=""
            />
          </div>

          <div className="login__forms">
            <form
              action=""
              className="login__registre"
              id="login-in"
              style={{ bottom: "0" }}
              onSubmit={(e) => {
                resetSubmit(e);
              }}
            >
              <h1 className="login__title">Forgot Password</h1>

              <div className="login__box">
                <i className="bx bx-user login__icon"></i>
                <input
                  type="email"
                  placeholder="Email"
                  id="input-email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="login__input"
                />
              </div>
              <button
                type="submit"
                className="login__button"
                disabled={loading}
                style={{ border: "none", fontFamily: "Poppins" }}
              >
                {loading ? "Reseting ..." : "Reset"}
              </button>

              <div>
                <span className="login__account">Have an Account?</span>
                <span className="login__signin" id="sign-up" onClick={login}>
                  {" "}
                  Log In
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ForgotPassword;
