import React, { useState } from "react";
import "./Modal.css";

const StatusModal = ({
  isOpen = true,
  onClose,
  onConfirm,
  title,
  message,
  options,
}) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [textAreaValue, setTextAreaValue] = useState("");

  const handleConfirm = () => {
    onConfirm({ selectedOption, textAreaValue });
    setSelectedOption("");
    setTextAreaValue("");
  };

  if (!isOpen) return null;

  return (
    <div className={`modal ${isOpen ? "show" : ""}`} style={{ zIndex: "100" }}>
      <div className="modal-content">
        <div className="modal-header">
          <h3 className="modal-title">{title}</h3>
          <button className="close-btn" onClick={onClose}>
            &times;
          </button>
        </div>
        <div className="modal-body">
          <p>{message}</p>
          <div>
            <label htmlFor="options">Select Status</label>
            <select
              id="options"
              value={selectedOption}
              onChange={(e) => setSelectedOption(e.target.value)}
              style={{
                width: "100%",
                padding: "8px",
                margin: "8px 0",
                border: "1px solid #ccc",
                borderRadius: "4px",
                fontSize: "16px",
              }}
            >
              <option value="" disabled>
                Select Status
              </option>
              {options.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label htmlFor="details">Additional Details</label>
            <textarea
              id="details"
              value={textAreaValue}
              onChange={(e) => setTextAreaValue(e.target.value)}
              rows="4"
              cols="30"
              style={{
                width: "100%",
                padding: "8px",
                margin: "8px 0",
                border: "1px solid #ccc",
                borderRadius: "4px",
                fontSize: "16px",
                resize: "vertical",
              }}
            />
          </div>
        </div>
        <div className="modal-footer">
          <button
            className="btn btn-secondary"
            onClick={onClose}
            style={{ fontFamily: "Poppins" }}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary"
            onClick={handleConfirm}
            style={{ fontFamily: "Poppins" }}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default StatusModal;
