import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import axios from "axios";
import Header from "./common/Header";
import Footer from "./common/Footer";
import { server } from "../server";
import img from "./img-change.svg";
import jwtDecode from "jwt-decode";

const ChangePasswordPage = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const navigate = useNavigate();
  const checkPasswordStrength = (password) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/;
    const hasLowerCase = /[a-z]/;
    const hasNumber = /\d/;
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;

    let missingCriteria = [];

    if (password.length < minLength) {
      missingCriteria.push("at least 8 characters long");
    }
    if (!hasUpperCase.test(password)) {
      missingCriteria.push("at least one uppercase letter");
    }
    if (!hasLowerCase.test(password)) {
      missingCriteria.push("at least one lowercase letter");
    }
    if (!hasNumber.test(password)) {
      missingCriteria.push("at least one number");
    }
    if (!hasSpecialChar.test(password)) {
      missingCriteria.push("at least one special character");
    }

    return missingCriteria;
  };
  const handleChangePassword = async (e) => {
    e.preventDefault();

    if (!currentPassword || !newPassword || !confirmPassword) {
      toast.error("All fields are required.");
      return;
    }
    const missingCriteria = checkPasswordStrength(newPassword);
    if (missingCriteria.length > 0) {
      toast.error(`New password must include: ${missingCriteria.join(", ")}.`, {
        duration: 10000,
      });
      return;
    }

    if (newPassword !== confirmPassword) {
      toast.error("New passwords do not match.");
      return;
    }

    try {
      setLoading(true);
      const token = localStorage.getItem("userToken");
      const user = jwtDecode(token);
      await axios.post(
        `${server}/change-password`,
        {
          email: user.email,
          currentPassword,
          newPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast.success("Password changed successfully.");
      setLoading(false);
      navigate("/profile");
    } catch (error) {
      console.error("Change password error:", error);
      if (error.response.data.error === "Current password is incorrect") {
        toast.error("Current password is incorrect");
      } else if (
        error.response.data.error ===
        "New password must be different from the current password"
      ) {
        toast.error("New password must be different from the current password");
      } else {
        toast.error("Failed to change password. Please try again.");
      }
      setLoading(false);
    }
  };

  const toggleVisibility = (setter) => {
    setter((prev) => !prev);
  };

  return (
    <div>
      <Header />
      <div className="login">
        <div className="login__content">
          <div className="login__img">
            <img
              src={img}
              style={{ maxWidth: "100%", height: "auto", display: "block" }}
              alt=""
            />
          </div>
          <div className="login__forms">
            <form className="login__registre" onSubmit={handleChangePassword}>
              <h1 className="login__title">Change Password</h1>

              <div className="login__box" style={{ position: "relative" }}>
                <i className="bx bx-lock-open login__icon"></i>
                <input
                  type={showCurrentPassword ? "text" : "password"}
                  placeholder="Current Password"
                  className="login__input"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                />
                <span
                  className="password-toggle-icon"
                  onClick={() => toggleVisibility(setShowCurrentPassword)}
                >
                  {showCurrentPassword ? (
                    <i
                      className="bx bxs-hide"
                      style={{
                        cursor: "pointer",
                        position: "absolute",
                        top: "50%",
                        transform: "translateY(-50%)",
                        right: "20px",
                        fontSize: "25px",
                      }}
                    ></i>
                  ) : (
                    <i
                      className="bx bxs-show"
                      style={{
                        cursor: "pointer",
                        position: "absolute",
                        top: "50%",
                        transform: "translateY(-50%)",
                        right: "20px",
                        fontSize: "25px",
                      }}
                    ></i>
                  )}
                </span>
              </div>

              <div className="login__box" style={{ position: "relative" }}>
                <i className="bx bx-lock login__icon"></i>
                <input
                  type={showNewPassword ? "text" : "password"}
                  placeholder="New Password"
                  className="login__input"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                <span
                  className="password-toggle-icon"
                  onClick={() => toggleVisibility(setShowNewPassword)}
                >
                  {showNewPassword ? (
                    <i
                      className="bx bxs-hide"
                      style={{
                        cursor: "pointer",
                        position: "absolute",
                        top: "50%",
                        transform: "translateY(-50%)",
                        right: "20px",
                        fontSize: "25px",
                      }}
                    ></i>
                  ) : (
                    <i
                      className="bx bxs-show"
                      style={{
                        cursor: "pointer",
                        position: "absolute",
                        top: "50%",
                        transform: "translateY(-50%)",
                        right: "20px",
                        fontSize: "25px",
                      }}
                    ></i>
                  )}
                </span>
              </div>

              <div className="login__box" style={{ position: "relative" }}>
                <i className="bx bx-lock login__icon"></i>
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Confirm New Password"
                  className="login__input"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <span
                  className="password-toggle-icon"
                  onClick={() => toggleVisibility(setShowConfirmPassword)}
                >
                  {showConfirmPassword ? (
                    <i
                      className="bx bxs-hide"
                      style={{
                        cursor: "pointer",
                        position: "absolute",
                        top: "50%",
                        transform: "translateY(-50%)",
                        right: "20px",
                        fontSize: "25px",
                      }}
                    ></i>
                  ) : (
                    <i
                      className="bx bxs-show"
                      style={{
                        cursor: "pointer",
                        position: "absolute",
                        top: "50%",
                        transform: "translateY(-50%)",
                        right: "20px",
                        fontSize: "25px",
                      }}
                    ></i>
                  )}
                </span>
              </div>

              <button
                type="submit"
                className="login__button"
                disabled={loading}
                style={{ border: "none", fontFamily: "Poppins" }}
              >
                {loading ? "Changing Password ..." : "Change Password"}
              </button>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ChangePasswordPage;
