import React, { useEffect, useState } from "react";
import AdminHeader from "./AdminHeader";
import AdminHome from "./AdminHome";
import AdminTasks from "./AdminTasks";
import Footer from "../common/Footer";
import jwtDecode from "jwt-decode";
import LoginPage from "../LoginPage";

const AdminPage = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [decodedToken, setDecodedToken] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("userToken");
    if (token) {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
        setIsLoggedIn(false);
        setDecodedToken(null);
        localStorage.removeItem("userToken");
      } else {
        setIsLoggedIn(true);
        setDecodedToken(decoded);
      }
    } else {
      setIsLoggedIn(false);
      setDecodedToken(null);
    }
  }, []);

  return (
    <>
      {isLoggedIn && decodedToken?.role === "admin" ? (
        <div>
          <AdminHeader active={"active"} />
          <AdminHome />
          <AdminTasks />
          <Footer />
        </div>
      ) : (
        <LoginPage />
      )}
    </>
  );
};

export default AdminPage;
