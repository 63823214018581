import React, { useEffect, useState } from "react";
import Header from "./common/Header";
import Footer from "./common/Footer";
import axios from "axios";
import { server } from "../server";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";

const TrackingReceiptPage = () => {
  const [data, setData] = useState({});
  const [shippedOn, setShippedOn] = useState([]);
  const [loading, setLoading] = useState(false);

  const { id } = useParams();
  useEffect(() => {
    const handleStatusChange = async () => {
      setLoading(true);
      try {
        const response = await axios.post(`${server}/${id}/get-status`, {});
        setData(response.data.data);
        setShippedOn(response.data.data.sippedOn);
        await axios.post(`${server}/notifications`, {
          title: "Tracking",
          message: id,
        });
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error updating status:", error);
        toast.error("Error: Re-check your Receipt Number", {
          duration: 10000,
        });
        await axios.post(`${server}/notifications`, {
          title: "Error",
          message: id,
        });
        setLoading(false);
      }
    };

    handleStatusChange();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="tracking-page">
      <Header />
      {loading ? (
        <div style={{ marginTop: "70px" }} className="container">
          <ul className="tasks-list loading">
            {[...Array(5)].map((_, index) => (
              <li className="tasks-item loading-item" key={index}>
                <div className="card task-card">
                  <div className="card-input loading-checkbox"></div>
                  <div className="card-badge radius-pill loading-badge"></div>
                  <div className="card-badge radius-pill loading-badge"></div>
                  <div className="card-badge radius-pill loading-badge"></div>
                  <ul className="card-meta-list">
                    <li>
                      <div className="meta-box icon-box loading-meta"></div>
                    </li>
                  </ul>
                </div>
              </li>
            ))}
          </ul>{" "}
        </div>
      ) : (
        <div style={{ marginTop: "70px" }}>
          {data.status && (
            <section
              class="service"
              id="service"
              style={{ padding: "10px", marginBottom: "0" }}
            >
              <div class="container" style={{ display: "block" }}>
                <div>
                  <div
                    style={{
                      border: "1px solid #fff",
                      padding: "15px",
                      borderRadius: "5px",
                    }}
                  >
                    {" "}
                    <div style={{ flexGrow: 1 }}>
                      <div
                        style={{
                          padding: "1.5rem",
                          backgroundColor: "#ffffff",
                          border: "1px solid #e5e7eb",
                          borderRadius: "0.5rem",
                          boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
                        }}
                      >
                        <h3
                          style={{
                            fontSize: "1.25rem",
                            fontWeight: "600",
                            color: "#111827",
                          }}
                        >
                          Package Status
                        </h3>
                        <p>
                          <strong>Ordered On:</strong>{" "}
                          {new Date(data?.createdAt).toLocaleString("en-US", {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                            hour12: true,
                          })}
                        </p>
                        <p style={{ marginBottom: "10px" }}>
                          <strong>Status:</strong> {data?.status}
                        </p>

                        <ol className="tracking__ol">
                          <li className="tracking__li">
                            <span>
                              <svg
                                style={{
                                  height: "1rem",
                                  width: "1rem",
                                  color: "#1d4ed8",
                                }}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M5 11.917 9.724 16.5 19 7.5"
                                />
                              </svg>
                            </span>
                            <h4 style={{ color: "#1d4ed8" }}>
                              Package Requested
                            </h4>
                            <p style={{ color: "#1d4ed8" }}>
                              Package is requested for importation.
                              <p style={{ color: "#3599fd" }}>
                                {new Date(data?.createdAt).toLocaleString(
                                  "en-US",
                                  {
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric",
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    second: "2-digit",
                                    hour12: true,
                                  }
                                )}
                              </p>
                            </p>
                          </li>
                          {data.status !== "Canceled" && (
                            <>
                              <li className="tracking__li">
                                <span>
                                  {data.status === "Received" ||
                                  data.status === "Pending" ||
                                  data.status === "Shipped/In Transit" ||
                                  data.status ===
                                    "Arrived at destination (customs pending)" ||
                                  data.status === "Out for delivery" ||
                                  data.status === "Delivered" ? (
                                    <svg
                                      style={{
                                        height: "1rem",
                                        width: "1rem",
                                        color: "#1d4ed8",
                                      }}
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M5 11.917 9.724 16.5 19 7.5"
                                      />
                                    </svg>
                                  ) : (
                                    <svg
                                      style={{
                                        height: "1rem",
                                        width: "1rem",
                                        color: "#6b7280",
                                      }}
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M3 21h18m-9-2v-8m0 0L4 11m8-2.5L20 11m-8 2.5V21m8-5.5v-4.5a2 2 0 0 0-1.175-1.813L13 7V4.5a1.5 1.5 0 1 0-3 0V7l-4.825 2.187A2 2 0 0 0 4 11.5v4.5"
                                      />
                                    </svg>
                                  )}
                                </span>
                                <h4
                                  style={{
                                    color: `${
                                      data.status === "Received" ||
                                      data.status === "Pending" ||
                                      data.status === "Shipped/In Transit" ||
                                      data.status ===
                                        "Arrived at destination (customs pending)" ||
                                      data.status === "Out for delivery" ||
                                      data.status === "Delivered"
                                        ? "#1d4ed8"
                                        : ""
                                    }`,
                                  }}
                                >
                                  Received
                                </h4>
                                <div>
                                  {shippedOn
                                    .filter(
                                      (order) => order.status === "Received"
                                    )
                                    .map((order) => (
                                      <>
                                        <p style={{ color: "#1d4ed8" }}>
                                          {order?.description}
                                        </p>
                                        <p style={{ color: "#3599fd" }}>
                                          {` ${new Date(
                                            order?.date
                                          ).toLocaleString("en-US", {
                                            year: "numeric",
                                            month: "long",
                                            day: "numeric",
                                            hour: "2-digit",
                                            minute: "2-digit",
                                            second: "2-digit",
                                            hour12: true,
                                          })}`}
                                        </p>
                                      </>
                                    ))}
                                </div>
                              </li>
                              <li className="tracking__li">
                                <span>
                                  {data.status === "Pending" ||
                                  data.status === "Shipped/In Transit" ||
                                  data.status ===
                                    "Arrived at destination (customs pending)" ||
                                  data.status === "Out for delivery" ||
                                  data.status === "Delivered" ? (
                                    <svg
                                      style={{
                                        height: "1rem",
                                        width: "1rem",
                                        color: "#1d4ed8",
                                      }}
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M5 11.917 9.724 16.5 19 7.5"
                                      />
                                    </svg>
                                  ) : (
                                    <svg
                                      style={{
                                        height: "1rem",
                                        width: "1rem",
                                        color: "#6b7280",
                                      }}
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M13 7h6l2 4m-8-4v8m0-8V6a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v9h2m8 0H9m4 0h2m4 0h2v-4m0 0h-5m3.5 5.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Zm-10 0a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z"
                                      />
                                    </svg>
                                  )}
                                </span>
                                <h4
                                  style={{
                                    color: `${
                                      data.status === "Pending" ||
                                      data.status === "Shipped/In Transit" ||
                                      data.status ===
                                        "Arrived at destination (customs pending)" ||
                                      data.status === "Out for delivery" ||
                                      data.status === "Delivered"
                                        ? "#1d4ed8"
                                        : ""
                                    }`,
                                  }}
                                >
                                  Pending
                                </h4>
                                <div>
                                  {shippedOn
                                    .filter(
                                      (order) => order.status === "Pending"
                                    )
                                    .map((order) => (
                                      <>
                                        <p style={{ color: "#1d4ed8" }}>
                                          {order?.description}
                                        </p>
                                        <p style={{ color: "#3599fd" }}>
                                          {` ${new Date(
                                            order?.date
                                          ).toLocaleString("en-US", {
                                            year: "numeric",
                                            month: "long",
                                            day: "numeric",
                                            hour: "2-digit",
                                            minute: "2-digit",
                                            second: "2-digit",
                                            hour12: true,
                                          })}`}
                                        </p>
                                      </>
                                    ))}
                                </div>
                              </li>
                              <li className="tracking__li">
                                <span>
                                  {data.status === "Shipped/In Transit" ||
                                  data.status ===
                                    "Arrived at destination (customs pending)" ||
                                  data.status === "Out for delivery" ||
                                  data.status === "Delivered" ? (
                                    <svg
                                      style={{
                                        height: "1rem",
                                        width: "1rem",
                                        color: "#1d4ed8",
                                      }}
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M5 11.917 9.724 16.5 19 7.5"
                                      />
                                    </svg>
                                  ) : (
                                    <svg
                                      style={{
                                        height: "1rem",
                                        width: "1rem",
                                        color: "#6b7280",
                                      }}
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M12 8c-1.1 0-2 .9-2 2s.9 2 2 2 2 .9 2 2-.9 2-2 2m0-8V6m0 12v-2m-6-6H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2h-1m-10 0H4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-2"
                                      />
                                    </svg>
                                  )}
                                </span>
                                <h4
                                  style={{
                                    color: `${
                                      data.status === "Shipped/In Transit" ||
                                      data.status ===
                                        "Arrived at destination (customs pending)" ||
                                      data.status === "Out for delivery" ||
                                      data.status === "Delivered"
                                        ? "#1d4ed8"
                                        : ""
                                    }`,
                                  }}
                                >
                                  Shipped/In Transit
                                </h4>
                                <div>
                                  {shippedOn
                                    .filter(
                                      (order) =>
                                        order.status === "Shipped/In Transit"
                                    )
                                    .map((order) => (
                                      <>
                                        <p style={{ color: "#1d4ed8" }}>
                                          {order?.description}
                                        </p>
                                        <p style={{ color: "#3599fd" }}>
                                          {` ${new Date(
                                            order?.date
                                          ).toLocaleString("en-US", {
                                            year: "numeric",
                                            month: "long",
                                            day: "numeric",
                                            hour: "2-digit",
                                            minute: "2-digit",
                                            second: "2-digit",
                                            hour12: true,
                                          })}`}
                                        </p>
                                      </>
                                    ))}
                                </div>
                              </li>
                              <li className="tracking__li">
                                <span>
                                  {data.status === "Shipped/In Transit" ||
                                  data.status ===
                                    "Arrived at destination (customs pending)" ||
                                  data.status === "Out for delivery" ||
                                  data.status === "Delivered" ? (
                                    <svg
                                      style={{
                                        height: "1rem",
                                        width: "1rem",
                                        color: "#1d4ed8",
                                      }}
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M5 11.917 9.724 16.5 19 7.5"
                                      />
                                    </svg>
                                  ) : (
                                    <svg
                                      style={{
                                        height: "1rem",
                                        width: "1rem",
                                      }}
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M3 8l9 5 9-5M3 8v10a2 2 0 002 2h14a2 2 0 002-2V8M3 8l9 5 9-5"
                                      />
                                      <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M15 12l2 2 4-4"
                                      />
                                    </svg>
                                  )}
                                </span>
                                <h4
                                  style={{
                                    color: `${
                                      data.status ===
                                        "Arrived at destination (customs pending)" ||
                                      data.status === "Out for delivery" ||
                                      data.status === "Delivered"
                                        ? "#1d4ed8"
                                        : ""
                                    }`,
                                  }}
                                >
                                  Arrived at destination (customs pending)
                                </h4>
                                <div>
                                  {shippedOn
                                    .filter(
                                      (order) =>
                                        order.status ===
                                        "Arrived at destination (customs pending)"
                                    )
                                    .map((order) => (
                                      <>
                                        <p style={{ color: "#1d4ed8" }}>
                                          {order?.description}
                                        </p>
                                        <p style={{ color: "#3599fd" }}>
                                          {` ${new Date(
                                            order?.date
                                          ).toLocaleString("en-US", {
                                            year: "numeric",
                                            month: "long",
                                            day: "numeric",
                                            hour: "2-digit",
                                            minute: "2-digit",
                                            second: "2-digit",
                                            hour12: true,
                                          })}`}
                                        </p>
                                      </>
                                    ))}
                                </div>
                              </li>
                              <li className="tracking__li">
                                <span>
                                  {data.status === "Out for delivery" ||
                                  data.status === "Delivered" ? (
                                    <svg
                                      style={{
                                        height: "1rem",
                                        width: "1rem",
                                        color: "#1d4ed8",
                                      }}
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M5 11.917 9.724 16.5 19 7.5"
                                      />
                                    </svg>
                                  ) : (
                                    <svg
                                      style={{
                                        height: "1rem",
                                        width: "1rem",
                                      }}
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <circle
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                      />
                                      <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M9 12l2 2 4-4"
                                      />
                                    </svg>
                                  )}
                                </span>
                                <h4
                                  style={{
                                    color: `${
                                      data.status === "Out for delivery" ||
                                      data.status === "Delivered"
                                        ? "#1d4ed8"
                                        : ""
                                    }`,
                                  }}
                                >
                                  Out for delivery
                                </h4>
                                <div>
                                  {shippedOn
                                    .filter(
                                      (order) =>
                                        order.status === "Out for delivery"
                                    )
                                    .map((order) => (
                                      <>
                                        <p style={{ color: "#1d4ed8" }}>
                                          {order?.description}
                                        </p>
                                        <p style={{ color: "#3599fd" }}>
                                          {` ${new Date(
                                            order?.date
                                          ).toLocaleString("en-US", {
                                            year: "numeric",
                                            month: "long",
                                            day: "numeric",
                                            hour: "2-digit",
                                            minute: "2-digit",
                                            second: "2-digit",
                                            hour12: true,
                                          })}`}
                                        </p>
                                      </>
                                    ))}
                                </div>
                              </li>
                              <li className="tracking__li">
                                <span>
                                  {data.status === "Delivered" ? (
                                    <svg
                                      style={{
                                        height: "1rem",
                                        width: "1rem",
                                        color: "#1d4ed8",
                                      }}
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M5 11.917 9.724 16.5 19 7.5"
                                      />
                                    </svg>
                                  ) : (
                                    <svg
                                      style={{
                                        height: "1rem",
                                        width: "1rem",
                                      }}
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M4 8v12a2 2 0 002 2h12a2 2 0 002-2V8l-8-4-8 4z"
                                      />
                                      <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M10 12l2 2 4-4"
                                      />
                                    </svg>
                                  )}
                                </span>
                                <h4
                                  style={{
                                    color: `${
                                      data.status === "Delivered"
                                        ? "#1d4ed8"
                                        : ""
                                    }`,
                                  }}
                                >
                                  Delivered
                                </h4>
                                <div>
                                  {shippedOn
                                    .filter(
                                      (order) => order.status === "Delivered"
                                    )
                                    .map((order) => (
                                      <>
                                        <p style={{ color: "#1d4ed8" }}>
                                          {order?.description}
                                        </p>
                                        <p style={{ color: "#3599fd" }}>
                                          {` ${new Date(
                                            order?.date
                                          ).toLocaleString("en-US", {
                                            year: "numeric",
                                            month: "long",
                                            day: "numeric",
                                            hour: "2-digit",
                                            minute: "2-digit",
                                            second: "2-digit",
                                            hour12: true,
                                          })}`}
                                        </p>
                                      </>
                                    ))}
                                </div>
                              </li>
                            </>
                          )}

                          {data.status === "Canceled" && (
                            <li
                              className="tracking__li"
                              style={{ color: "red" }}
                            >
                              <span>
                                <svg
                                  style={{
                                    height: "1rem",
                                    width: "1rem",
                                    color: "#f44336", // red color for cancelled
                                  }}
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <circle
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                  />
                                  <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M15 9l-6 6M9 9l6 6"
                                  />
                                </svg>
                              </span>
                              <h4 style={{ color: "red" }}>Cancelled</h4>
                              <p style={{ fontSize: "0.875rem", color: "red" }}>
                                Order has been cancelled - call us
                              </p>
                            </li>
                          )}
                        </ol>

                        <div
                          style={{
                            gap: "1rem",
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          <button
                            type="button"
                            style={{
                              width: "100%",
                              padding: "0.625rem 1.25rem",
                              backgroundColor: "#ffffff",
                              border: "1px solid #e5e7eb",
                              borderRadius: "0.5rem",
                              fontSize: "0.875rem",
                              fontWeight: "500",
                              color: "#111827",
                              cursor: "pointer",
                            }}
                          >
                            Call Us - 0795172846
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
        </div>
      )}

      <Footer />
    </div>
  );
};

export default TrackingReceiptPage;
