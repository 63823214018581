import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import AboutPage from "./components/AboutPage";
import ServicesPage from "./components/ServicesPage";
import ContactsPage from "./components/ContactsPage";
import TrackingPage from "./components/TrackingPage";
import { Toaster } from "react-hot-toast";
import Quotation from "./components/Quotation";
import LoginPage from "./components/LoginPage";
import AdminPage from "./components/admin/AdminPage";
import TransitionToTop from "./components/common/transitionToTop";
import AdminCargosPage from "./components/admin/AdminCargosPage";
import AddCargo from "./components/admin/AddCargo";
import AdminUpdateCargo from "./components/admin/AdminUpdateCargo";
import AdminUsers from "./components/admin/AdminUsers";
import ProfilesPage from "./components/user/ProfilesPage";
import UpdateProfile from "./components/user/UpdateProfile";
import UserCargo from "./components/user/UserCargo";
import UserAddCargo from "./components/user/UserAddCargo";
import Payments from "./components/admin/Payments";
import AddPayment from "./components/admin/AddPayment";
import UserPayments from "./components/user/UserPayments";
import ForgotPassword from "./components/ForgotPassword";
import ChangePasswordPage from "./components/ChangePasswordPage";
import Trial from "./components/Trial";
import TermsAndConditions from "./components/termsandconditions/TermsAndConditions";
import AdminRequestsPage from "./components/admin/AdminRequestsPage";
import TrackingReceiptPage from "./components/TrackingReceiptPage";

function App() {
  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/services/contacts" element={<ContactsPage />} />
          <Route path="/about/contacts" element={<ContactsPage />} />
          <Route path="/contacts" element={<ContactsPage />} />
          <Route path="/tracking/contacts" element={<ContactsPage />} />
          <Route path="/tracking" element={<TrackingPage />} />
          <Route path="/tracking/:id" element={<TrackingReceiptPage />} />
          <Route path="/quotation" element={<Quotation />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/trial" element={<Trial />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />

          <Route path="/profile" element={<ProfilesPage />} />
          <Route path="/update-profile/:id" element={<UpdateProfile />} />
          <Route path="/user-cargo" element={<UserCargo />} />
          <Route path="/user-add-request" element={<UserAddCargo />} />
          <Route path="/my-payments" element={<UserPayments />} />
          <Route path="/change-password" element={<ChangePasswordPage />} />

          <Route path="/admin" element={<AdminPage />} />
          <Route path="/cargo" element={<AdminCargosPage />} />
          <Route path="/requests" element={<AdminRequestsPage />} />
          <Route path="/users" element={<AdminUsers />} />
          <Route path="/payments" element={<Payments />} />
          <Route path="/addcargo/:id" element={<AddCargo />} />
          <Route path="/updatecargo/:id" element={<AdminUpdateCargo />} />
          <Route path="/add-payments/:id" element={<AddPayment />} />
        </Routes>
        <TransitionToTop />
      </Router>
    </>
  );
}

export default App;
