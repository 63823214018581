import React from "react";
import Header from "./common/Header";
import Footer from "./common/Footer";
import Contacts from "./common/Contacts";

const ContactsPage = () => {
  return (
    <div>
      <Header />
      <div style={{ marginTop: "30px" }}>
        <Contacts />
      </div>
      <Footer />
    </div>
  );
};

export default ContactsPage;
