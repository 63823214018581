import React, { useState } from "react";
import img from "./img-login.svg";
import img2 from "./img-register.svg";
import { Link, useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";
import toast from "react-hot-toast";
import axios from "axios";
import Header from "./common/Header";
import { server } from "../server";
import Footer from "./common/Footer";

const LoginPage = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [isSignIn, setIsSignIn] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();
  const loginSubmit = async (e) => {
    e.preventDefault();
    if (!email || !password) {
      toast.error("Email and password are required.");
      return;
    }
    try {
      setLoading(true);
      const response = await axios.post(`${server}/login`, { email, password });
      localStorage.setItem("userToken", response.data.token);
      toast.success("Login Success.");
      console.log("data", response.data);
      setLoading(false);

      const { role } = jwtDecode(response.data.token);
      const { reset } = jwtDecode(response.data.token);

      console.log(reset);
      if (reset === "yes") {
        navigate("/change-password");
      } else {
        if (role === "admin") {
          navigate("/admin");
        } else {
          navigate("/profile");
        }
      }
    } catch (error) {
      console.error("Login error:", error);
      toast.error("Login failed. Please try again.");
      setLoading(false);
    }
  };
  const checkPasswordStrength = (password) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/;
    const hasLowerCase = /[a-z]/;
    const hasNumber = /\d/;
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;

    let missingCriteria = [];

    if (password.length < minLength) {
      missingCriteria.push("at least 8 characters long");
    }
    if (!hasUpperCase.test(password)) {
      missingCriteria.push("at least one uppercase letter");
    }
    if (!hasLowerCase.test(password)) {
      missingCriteria.push("at least one lowercase letter");
    }
    if (!hasNumber.test(password)) {
      missingCriteria.push("at least one number");
    }
    if (!hasSpecialChar.test(password)) {
      missingCriteria.push("at least one special character");
    }

    return missingCriteria;
  };
  const registerSubmit = async (e) => {
    e.preventDefault();
    if (!name || !email || !password) {
      toast.error("Name, Email and password are required.");
      return;
    }
    const missingCriteria = checkPasswordStrength(password);
    if (missingCriteria.length > 0) {
      toast.error(`Password must include: ${missingCriteria.join(", ")}.`, {
        duration: 10000,
      });
      return;
    }
    try {
      setLoading(true);
      await axios.post(`${server}/register`, {
        name,
        email,
        password,
      });
      toast.success("Register Success. Login In now", {
        duration: 10000,
      });
      setLoading(false);
      setIsSignIn(true);
    } catch (error) {
      if (
        error?.response?.data?.error === "User with this email already exists."
      ) {
        toast.error("User with this email already exists.");
      } else {
        console.log(error);

        toast.error("Register failed. Please try again.");
      }
      setLoading(false);
    }
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleForm = () => {
    setIsSignIn(!isSignIn);
  };

  return (
    <div>
      <Header />
      <div className="login">
        <div className="login__content">
          <div className="login__img">
            <img
              src={isSignIn ? img : img2}
              style={{ maxWidth: "100%", height: "auto", display: "block" }}
              alt=""
            />
          </div>

          <div className="login__forms">
            <form
              action=""
              className={isSignIn ? "login__registre" : "login__create none"}
              id="login-in"
              onSubmit={(e) => {
                loginSubmit(e);
              }}
            >
              <h1 className="login__title">Sign In</h1>

              <div className="login__box">
                <i className="bx bx-user login__icon"></i>
                <input
                  type="email"
                  placeholder="Email"
                  id="input-email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="login__input"
                />
              </div>

              <div className="login__box" style={{ position: "relative" }}>
                <i className="bx bx-lock-alt login__icon"></i>
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  className="login__input"
                  id="input-pass"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <span
                  className="password-toggle-icon"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? (
                    <i
                      className="bx bxs-hide"
                      style={{
                        cursor: "pointer",
                        position: "absolute",
                        top: "50%",
                        transform: "translateY(-50%)",
                        right: "20px",
                        fontSize: "25px",
                      }}
                    ></i>
                  ) : (
                    <i
                      className="bx bxs-show"
                      style={{
                        cursor: "pointer",
                        position: "absolute",
                        top: "50%",
                        transform: "translateY(-50%)",
                        right: "20px",
                        fontSize: "25px",
                      }}
                    ></i>
                  )}
                </span>
              </div>

              <Link to="/forgot-password" className="login__forgot">
                Forgot password?
              </Link>

              <button
                type="submit"
                className="login__button"
                disabled={loading}
                style={{ border: "none", fontFamily: "Poppins" }}
              >
                {loading ? "Signing In ..." : "Sign In"}
              </button>

              <div>
                <span className="login__account">Don't have an Account?</span>
                <span
                  className="login__signin"
                  id="sign-up"
                  onClick={toggleForm}
                >
                  {" "}
                  Sign Up
                </span>
              </div>
            </form>

            <form
              action=""
              className={isSignIn ? "login__create none" : "login__registre"}
              id="login-up"
              onSubmit={(e) => registerSubmit(e)}
            >
              <h1 className="login__title">Create Account</h1>

              <div className="login__box">
                <i className="bx bx-user login__icon"></i>
                <input
                  type="text"
                  placeholder="Name"
                  id="input-name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="login__input"
                />
              </div>

              <div className="login__box">
                <i className="bx bx-at login__icon"></i>
                <input
                  type="email"
                  placeholder="Email"
                  id="input-email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="login__input"
                />
              </div>

              <div className="login__box" style={{ position: "relative" }}>
                <i className="bx bx-lock-alt login__icon"></i>
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  className="login__input"
                  id="input-pass"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <span
                  className="password-toggle-icon"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? (
                    <i
                      className="bx bxs-hide"
                      style={{
                        cursor: "pointer",
                        position: "absolute",
                        top: "50%",
                        transform: "translateY(-50%)",
                        right: "20px",
                        fontSize: "25px",
                      }}
                    ></i>
                  ) : (
                    <i
                      className="bx bxs-show"
                      style={{
                        cursor: "pointer",
                        position: "absolute",
                        top: "50%",
                        transform: "translateY(-50%)",
                        right: "20px",
                        fontSize: "25px",
                      }}
                    ></i>
                  )}
                </span>
              </div>
              <p
                style={{
                  color: "white",
                  fontSize: "12px",
                  textAlign: "start",
                  marginTop: "5px",
                  marginLeft: "5px",
                }}
              >
                By Signing Up, You agree to our{" "}
                <span
                  style={{ color: "#3166b6", cursor: "pointer" }}
                  onClick={() => {
                    navigate("/terms-and-conditions");
                  }}
                >
                  terms and contations
                </span>
              </p>
              <button
                type="submit"
                className="login__button"
                disabled={loading}
                style={{ border: "none", fontFamily: "Poppins" }}
              >
                {loading ? "Signing Up ..." : "Sign Up"}
              </button>

              <div>
                <span className="login__account">Already have an Account?</span>
                <span
                  className="login__signup"
                  id="sign-in"
                  onClick={toggleForm}
                >
                  {" "}
                  Sign In
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LoginPage;
