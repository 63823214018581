import React, { useState, useEffect } from "react";

const WhatsAppButton = () => {
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 80) {
        setIsScrolling(true);
      } else {
        setIsScrolling(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleClick = () => {
    window.open("https://wa.me/254795172846", "_blank");
  };

  return (
    <div
      className={`whatsapp-button ${isScrolling ? "scrolled" : ""}`}
      onClick={handleClick}
    >
      <i className="bx bxl-whatsapp"></i>
    </div>
  );
};

export default WhatsAppButton;
