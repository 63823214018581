import React, { useEffect, useState } from "react";
import UserHeader from "./UserHeader";
import Footer from "../common/Footer";
import toast from "react-hot-toast";
import { server } from "../../server";
import { Link } from "react-router-dom";
import jwtDecode from "jwt-decode";

const UserCargo = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPage2, setCurrentPage2] = useState(1);

  const [itemsPerPage] = useState(5);
  const [itemsPerPage2] = useState(5);

  const [cargos, setCargos] = useState([]);
  const [requests, setRequests] = useState([]);

  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);

  // Initialize menu visibility state for each cargo item
  const [menuVisible, setMenuVisible] = useState({});
  const [menuVisible2, setMenuVisible2] = useState({});

  useEffect(() => {
    fetchCargos();
    fetchRequests();
  }, []);

  const fetchCargos = async () => {
    const token = localStorage.getItem("userToken");
    if (!token) {
      toast.error("Please Login Again");
      throw new Error("Authentication token not found");
    }
    if (token) {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
        localStorage.removeItem("userToken");
      } else {
        try {
          setLoading2(true);
          const response = await fetch(`${server}/usercargos/${decoded.id}`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          if (!response.ok) {
            setLoading2(false);
            toast.error("Error occurred");
            console.log("error", response);
            throw new Error("Network response was not ok.");
          }
          const data = await response.json();
          // Initialize menuVisible state for each cargo item
          const initialMenuVisible = {};
          data.data.forEach((cargo) => {
            initialMenuVisible[cargo._id] = false;
          });
          setMenuVisible(initialMenuVisible);
          const sortedCargos = data.data.sort((a, b) => {
            return new Date(b.createdAt) - new Date(a.createdAt);
          });
          setCargos(sortedCargos);
        } catch (error) {
          setLoading2(false);
          console.error("Error fetching cargos:", error);
        }
      }
    }
    setLoading2(false);
  };
  const fetchRequests = async () => {
    const token = localStorage.getItem("userToken");
    if (!token) {
      toast.error("Please Login Again");
      throw new Error("Authentication token not found");
    }
    if (token) {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
        localStorage.removeItem("userToken");
      } else {
        try {
          setLoading3(true);
          const response = await fetch(`${server}/userrequests/${decoded.id}`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          if (!response.ok) {
            setLoading3(false);
            toast.error("Error occurred");
            console.log("error", response);
            throw new Error("Network response was not ok.");
          }
          const data = await response.json();
          // Initialize menuVisible state for each cargo item
          const initialMenuVisible = {};
          data.data.forEach((request) => {
            initialMenuVisible[request._id] = false;
          });
          setMenuVisible2(initialMenuVisible);
          const sortedRequests = data.data.sort((a, b) => {
            return new Date(b.createdAt) - new Date(a.createdAt);
          });
          setRequests(sortedRequests);
        } catch (error) {
          setLoading3(false);
          console.error("Error fetching cargos:", error);
        }
      }
    }
    setLoading3(false);
  };

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfLastItem2 = currentPage2 * itemsPerPage2;

  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const indexOfFirstItem2 = indexOfLastItem2 - itemsPerPage2;

  const currentItems = cargos?.slice(indexOfFirstItem, indexOfLastItem);
  const currentItems2 = requests?.slice(indexOfFirstItem2, indexOfLastItem2);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const paginate2 = (pageNumber2) => setCurrentPage2(pageNumber2);

  // Calculate total pages
  const totalPages = Math.ceil(cargos.length / itemsPerPage);
  const totalPages2 = Math.ceil(requests.length / itemsPerPage2);

  // Generate page numbers
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }
  const pageNumbers2 = [];
  for (let i = 1; i <= totalPages2; i++) {
    pageNumbers2.push(i);
  }

  // Toggle menu for specific cargo item
  const toggleMenu = (cargoId) => {
    setMenuVisible({
      ...menuVisible,
      [cargoId]: !menuVisible[cargoId],
    });
  };

  const toggleMenu2 = (requestsId) => {
    setMenuVisible2({
      ...menuVisible2,
      [requestsId]: !menuVisible2[requestsId],
    });
  };

  return (
    <>
      <div className="admin">
        <UserHeader />
        <main style={{ minHeight: "80vh" }}>
          <article className="container article">
            <section className="tasks">
              <div className="section-title-wrapper">
                <h2 className="section-title">Packages</h2>

                <div className="pagination">
                  <div>
                    Page {currentPage} of {totalPages}
                  </div>
                </div>
              </div>
              {loading2 ? (
                <ul className="tasks-list loading">
                  {[...Array(5)].map((_, index) => (
                    <li className="tasks-item loading-item" key={index}>
                      <div className="card task-card">
                        <div className="card-input loading-checkbox"></div>
                        <div className="card-badge radius-pill loading-badge"></div>
                        <div className="card-badge radius-pill loading-badge"></div>
                        <div className="card-badge radius-pill loading-badge"></div>
                        <ul className="card-meta-list">
                          <li>
                            <div className="meta-box icon-box loading-meta"></div>
                          </li>
                        </ul>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <>
                  <ul className="tasks-list">
                    {/* Render current page items */}
                    {currentItems.map((cargo, index) => (
                      <li className="tasks-item" key={index}>
                        <div className="card task-card">
                          <div className="card-input">
                            <label
                              htmlFor={`task-${index}`}
                              className="task-label"
                            >
                              Receipt No: {cargo.receiptNo}
                            </label>
                          </div>
                          <div
                            className={`card-badge ${
                              cargo.status === "delivered"
                                ? "cyan"
                                : "radius-pill"
                            }`}
                          >
                            {cargo.status}
                          </div>
                          <div className="card-badge">{cargo.shipFrom}</div>
                          <div className="card-badge">{cargo.shippingMode}</div>

                          <ul className="card-meta-list">
                            <li>
                              <Link to={`/tracking/${cargo.receiptNo}`}>
                                <div
                                  className="card-badge orange"
                                  style={{
                                    display: "flex",
                                    textAlign: "center",
                                  }}
                                >
                                  <i
                                    className="bx bx-current-location icon"
                                    style={{ fontSize: "25px" }}
                                  ></i>

                                  <p
                                    style={{
                                      fontSize: "13px",
                                      marginLeft: "2px",
                                      marginTop: "2px",
                                    }}
                                  >
                                    Track
                                  </p>
                                </div>
                              </Link>
                            </li>
                            <li>
                              <div
                                className="meta-box icon-box"
                                style={{ cursor: "pointer" }}
                                onClick={() => toggleMenu(cargo._id)}
                              >
                                <i class="bx bx-list-ul icon"></i>
                                <span>{cargo.list}</span>
                              </div>
                            </li>
                            {menuVisible[cargo._id] && (
                              <ul
                                className="ctx-menu"
                                style={{ width: "fit-content", zIndex: "99" }}
                              >
                                <li className="ctx-item">
                                  <button className="ctx-menu-btn icon-box">
                                    <i class="bx bx-money icon"></i>

                                    <span className="ctx-menu-text">
                                      Amount:{" "}
                                      <strong>
                                        {cargo?.currency}.{" "}
                                        {Number(cargo.amount).toLocaleString()}
                                      </strong>
                                    </span>
                                  </button>
                                </li>
                                <li className="ctx-item">
                                  <button className="ctx-menu-btn icon-box">
                                    <i class="bx bx-message-square-detail icon"></i>

                                    <span className="ctx-menu-text">
                                      {cargo.email}
                                    </span>
                                  </button>
                                </li>
                                <li className="ctx-item">
                                  <button className="ctx-menu-btn icon-box">
                                    <i className="bx bx-phone icon"></i>
                                    <span className="ctx-menu-text">
                                      {cargo.phone}
                                    </span>
                                  </button>
                                </li>
                                <li className="ctx-item">
                                  <button className="ctx-menu-btn icon-box">
                                    <i class="bx bx-message icon"></i>

                                    <span className="ctx-menu-text">
                                      {cargo.quantitynquality}
                                    </span>
                                  </button>
                                </li>
                                <li className="ctx-item">
                                  <button className="ctx-menu-btn icon-box">
                                    <i className="bx bx-wallet icon"></i>
                                    <span className="ctx-menu-text">
                                      Balance:{" "}
                                      <strong>
                                        {cargo?.currency}.{" "}
                                        {Number(cargo.balance).toLocaleString()}
                                      </strong>
                                    </span>
                                  </button>
                                </li>
                                <li className="ctx-item">
                                  <button className="ctx-menu-btn icon-box">
                                    <i class="bx bx-time icon"></i>
                                    <span className="ctx-menu-text">
                                      {new Date(
                                        cargo.createdAt
                                      ).toLocaleString()}
                                    </span>
                                  </button>
                                </li>

                                <li className="divider"></li>

                                <i
                                  class="bx bx-x-circle icon"
                                  style={{
                                    position: "absolute",
                                    top: "2px",
                                    right: "2px",
                                    padding: "5px",
                                    cursor: "pointer",
                                    fontSize: "24px",
                                  }}
                                  onClick={() => toggleMenu(cargo._id)}
                                ></i>
                              </ul>
                            )}
                          </ul>
                        </div>
                      </li>
                    ))}
                  </ul>
                </>
              )}

              {!loading2 && currentItems?.length === 0 && (
                <li className="tasks-item loading-item">
                  <div className="card task-card">
                    <div className="card-input loading-checkbox"></div>
                    <div className="card-badge radius-pill loading-badge"></div>
                    <div className="card-badge radius-pill loading-badge">
                      No Cargos
                    </div>
                    <div className="card-badge radius-pill loading-badge"></div>
                    <ul className="card-meta-list">
                      <li>
                        <div className="meta-box icon-box loading-meta"></div>
                      </li>
                    </ul>
                  </div>
                </li>
              )}

              {/* Render page numbers */}
              <div
                style={{ display: "flex", marginTop: "6px", flexWrap: "wrap" }}
              >
                {pageNumbers.map((number) => (
                  <button
                    key={number}
                    className={`pag-button ${
                      currentPage === number ? "active" : ""
                    }`}
                    onClick={() => paginate(number)}
                  >
                    {number}
                  </button>
                ))}
              </div>
            </section>
            <section className="tasks">
              <div className="section-title-wrapper">
                <h2 className="section-title">Your Requests</h2>
                <Link to="/user-add-request">
                  <div className="card-badge orange">
                    <div style={{ display: "flex", textAlign: "center" }}>
                      <i
                        class="bx bx-plus-circle icon"
                        style={{ fontSize: "25px" }}
                      ></i>
                      <p
                        style={{
                          fontSize: "13px",
                          marginLeft: "2px",
                          marginTop: "2px",
                        }}
                      >
                        Add Request
                      </p>
                    </div>
                  </div>
                </Link>
                <div className="pagination">
                  <div>
                    Page {currentPage2} of {totalPages2}
                  </div>
                </div>
              </div>
              {loading3 ? (
                <ul className="tasks-list loading">
                  {[...Array(5)].map((_, index) => (
                    <li className="tasks-item loading-item" key={index}>
                      <div className="card task-card">
                        <div className="card-input loading-checkbox"></div>
                        <div className="card-badge radius-pill loading-badge"></div>
                        <div className="card-badge radius-pill loading-badge"></div>
                        <div className="card-badge radius-pill loading-badge"></div>
                        <ul className="card-meta-list">
                          <li>
                            <div className="meta-box icon-box loading-meta"></div>
                          </li>
                        </ul>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <>
                  <ul className="tasks-list">
                    {/* Render current page items */}
                    {currentItems2.map((request, index) => (
                      <li className="tasks-item" key={index}>
                        <div className="card task-card">
                          <div className="card-input">
                            <label
                              htmlFor={`task-${index}`}
                              className="task-label"
                            >
                              Tracking No: {request.receiptNo}
                            </label>
                          </div>
                          <div
                            className={`card-badge ${
                              request.status === "Confirmed"
                                ? "cyan"
                                : "radius-pill"
                            }`}
                          >
                            {request.status}
                          </div>
                          <div className="card-badge">{request.shipFrom}</div>
                          <div className="card-badge">
                            {request.shippingMode}
                          </div>

                          <ul className="card-meta-list">
                            <li>
                              <div
                                className="meta-box icon-box"
                                style={{ cursor: "pointer" }}
                                onClick={() => toggleMenu2(request._id)}
                              >
                                <i class="bx bx-list-ul icon"></i>
                                <span>{request.list}</span>
                              </div>
                            </li>
                            {menuVisible2[request._id] && (
                              <ul
                                className="ctx-menu"
                                style={{ width: "fit-content", zIndex: "99" }}
                              >
                                <li className="ctx-item">
                                  <button className="ctx-menu-btn icon-box">
                                    <i class="bx bx-money icon"></i>

                                    <span className="ctx-menu-text">
                                      Amount:{" "}
                                      <strong>
                                        {request?.currency}.{" "}
                                        {Number(
                                          request.amount
                                        ).toLocaleString()}
                                      </strong>
                                    </span>
                                  </button>
                                </li>
                                <li className="ctx-item">
                                  <button className="ctx-menu-btn icon-box">
                                    <i class="bx bx-message-square-detail icon"></i>

                                    <span className="ctx-menu-text">
                                      {request.email}
                                    </span>
                                  </button>
                                </li>
                                <li className="ctx-item">
                                  <button className="ctx-menu-btn icon-box">
                                    <i className="bx bx-phone icon"></i>
                                    <span className="ctx-menu-text">
                                      {request.phone}
                                    </span>
                                  </button>
                                </li>
                                <li className="ctx-item">
                                  <button className="ctx-menu-btn icon-box">
                                    <i class="bx bx-message icon"></i>

                                    <span className="ctx-menu-text">
                                      {request.quantitynquality}
                                    </span>
                                  </button>
                                </li>
                                <li className="ctx-item">
                                  <button className="ctx-menu-btn icon-box">
                                    <i class="bx bx-time icon"></i>
                                    <span className="ctx-menu-text">
                                      {new Date(
                                        request.createdAt
                                      ).toLocaleString()}
                                    </span>
                                  </button>
                                </li>

                                <li className="divider"></li>

                                <i
                                  class="bx bx-x-circle icon"
                                  style={{
                                    position: "absolute",
                                    top: "2px",
                                    right: "2px",
                                    padding: "5px",
                                    cursor: "pointer",
                                    fontSize: "24px",
                                  }}
                                  onClick={() => toggleMenu2(request._id)}
                                ></i>
                              </ul>
                            )}
                          </ul>
                        </div>
                      </li>
                    ))}
                  </ul>
                </>
              )}

              {!loading3 && currentItems2?.length === 0 && (
                <li className="tasks-item loading-item">
                  <div className="card task-card">
                    <div className="card-input loading-checkbox"></div>
                    <div className="card-badge radius-pill loading-badge"></div>
                    <div className="card-badge radius-pill loading-badge">
                      No Requests so far
                    </div>
                    <div className="card-badge radius-pill loading-badge"></div>
                    <ul className="card-meta-list">
                      <li>
                        <div className="meta-box icon-box loading-meta"></div>
                      </li>
                    </ul>
                  </div>
                </li>
              )}

              {/* Render page numbers */}
              <div
                style={{ display: "flex", marginTop: "6px", flexWrap: "wrap" }}
              >
                {pageNumbers2.map((number) => (
                  <button
                    key={number}
                    className={`pag-button ${
                      currentPage === number ? "active" : ""
                    }`}
                    onClick={() => paginate2(number)}
                  >
                    {number}
                  </button>
                ))}
              </div>
            </section>
          </article>
        </main>
      </div>
      <Footer />
    </>
  );
};

export default UserCargo;
