import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { server } from "../../server";
import jwtDecode from "jwt-decode";
import UserHeader from "./UserHeader";

const UserPayments = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const [payments, setPayments] = useState([]);
  const [loading2, setLoading2] = useState(false);

  console.log("payments", payments);

  useEffect(() => {
    fetchPayments();
  }, []);

  const fetchPayments = async () => {
    const token = localStorage.getItem("userToken");
    if (!token) {
      toast.error("Please Login Again");
      throw new Error("Authentication token not found");
    }
    if (token) {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
        localStorage.removeItem("userToken");
      } else {
        try {
          setLoading2(true);
          const response = await fetch(`${server}/payments/${decoded.id}`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          if (!response.ok) {
            setLoading2(false);
            toast.error("Error occurred");
            console.log("error", response);
            throw new Error("Network response was not ok.");
          }
          const data = await response.json();
          // Initialize menuVisible state for each cargo item
          const initialMenuVisible = {};
          data.data.forEach((cargo) => {
            initialMenuVisible[cargo._id] = false;
          });
          const sortedCargos = data.data.sort((a, b) => {
            return new Date(b.createdAt) - new Date(a.createdAt);
          });
          setPayments(sortedCargos);
        } catch (error) {
          setLoading2(false);
          console.error("Error fetching cargos:", error);
        }
      }
    }
    setLoading2(false);
  };

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = payments?.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate total pages
  const totalPages = Math.ceil(payments.length / itemsPerPage);

  // Generate page numbers
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="admin">
      <UserHeader />
      <main>
        <article className="container article">
          <section className="tasks">
            <div className="section-title-wrapper">
              <h2 className="section-title">Payments</h2>
              <div className="pagination">
                <div>
                  Page {currentPage} of {totalPages}
                </div>
              </div>
            </div>
            {loading2 ? (
              <ul className="tasks-list loading">
                {[...Array(5)].map((_, index) => (
                  <li className="tasks-item loading-item" key={index}>
                    <div className="card task-card">
                      <div className="card-input loading-checkbox"></div>
                      <div className="card-badge radius-pill loading-badge"></div>
                      <div className="card-badge radius-pill loading-badge"></div>
                      <div className="card-badge radius-pill loading-badge"></div>
                      <ul className="card-meta-list">
                        <li>
                          <div className="meta-box icon-box loading-meta"></div>
                        </li>
                      </ul>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <>
                <ul className="tasks-list">
                  {/* Render current page items */}
                  {currentItems.map((payment, index) => (
                    <li className="tasks-item" key={index}>
                      <div className="card task-card">
                        <span className="ctx-menu-text">
                          {new Date(payment.createdAt).toLocaleString()}
                        </span>
                        <div className="card-input">
                          <label
                            htmlFor={`task-${index}`}
                            className="task-label"
                          >
                            Receipt No: {payment.receiptNo}
                          </label>
                        </div>
                        <div className="card-input">
                          <label
                            htmlFor={`task-${index}`}
                            className="task-label"
                          >
                            Transaction Code: {payment.transactionCode}
                          </label>
                        </div>
                        <div className="card-badge">{payment.phone}</div>

                        <ul className="card-meta-list">
                          <div className="card-badge">
                            sh. {Number(payment.amount).toLocaleString()}
                          </div>

                          <div
                            className={`card-badge ${
                              payment.method === "Mpesa"
                                ? "cyan"
                                : payment.method === "Bank"
                                ? "blue"
                                : "radius-pill"
                            }`}
                          >
                            {payment.method}
                          </div>
                        </ul>
                      </div>
                    </li>
                  ))}
                </ul>
              </>
            )}
            {!loading2 && currentItems?.length === 0 && (
              <li className="tasks-item loading-item">
                <div className="card task-card">
                  <div className="card-input loading-checkbox"></div>
                  <div className="card-badge radius-pill loading-badge"></div>
                  <div className="card-badge radius-pill loading-badge">
                    No Payments
                  </div>
                  <div className="card-badge radius-pill loading-badge"></div>
                  <ul className="card-meta-list">
                    <li>
                      <div className="meta-box icon-box loading-meta"></div>
                    </li>
                  </ul>
                </div>
              </li>
            )}

            {/* Render page numbers */}
            <div
              style={{ display: "flex", marginTop: "6px", flexWrap: "wrap" }}
            >
              {pageNumbers.map((number) => (
                <button
                  key={number}
                  className={`pag-button ${
                    currentPage === number ? "active" : ""
                  }`}
                  onClick={() => paginate(number)}
                >
                  {number}
                </button>
              ))}
            </div>
          </section>
        </article>
      </main>
    </div>
  );
};

export default UserPayments;
